import _ from 'lodash';
import { colors, adaptV4Theme, createTheme as createMuiTheme } from '@mui/material';
import { THEMES } from '../constants';
import { softShadows, strongShadows } from './shadows';
import typography from './typography';
import { green } from '@mui/material/colors';

const baseOptions = {
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden'
      }
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: '#9B00F9'
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    },
    MuiSvgIcon: {
      root: {
        fill: 'white',
      }
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)'
      }
    },
    MuiStepIcon: {
      root: {
        '&$completed': {
          color: '#00aeff'
        },
        '&$active': {
          color: '#00aeff'
        }
      }
    },

    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: 'rgba(222, 193, 218, 0.19)',
      },
      barColorPrimary: {
        backgroundColor: '#9B00F9',
      }
    },

    MuiCssBaseline: {
      body: {
        '*::-webkit-scrollbar': {
          backgroundColor: 'rgba(222, 193, 218, 0.19)',
          height: '10px !important',
          width: '5px',
        },
        '*::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(155, 0, 249, 1)',
        }
      },
    },

    MuiTableCell: {
      root: {
        borderBottom: '1px solid #9B00F9',
        textTransform: "uppercase"
      },
      head: {
        fontWeight: '800'
      }
    },
    MuiFormLabel: {
      root: {
        fontWeight: '600',
        fontSize: '16px',
        textTransform: 'uppercase',
        "&.Mui-focused": {
          color: "#9B00F9"
        }
      },
    },
    MuiTablePagination: {
      root:{
        overflow:'unset'
      },
      toolbar:{
        minHeight:'70px'
      },
      caption: {
        fontWeight: '600',
        fontSize: '14px',
        textTransform: 'uppercase',
      },
      input: {
        fontWeight: '600',
        fontSize: '14px',
        textTransform: 'uppercase',
        backgroundColor: 'rgba(63, 61, 122, 0.24)',
        height: '45px',
        width:'85px',
      },
      actions: {
        marginLeft: '8px',
        backgroundColor: 'rgba(63, 61, 122, 0.24)',
        height: '45px',
      },
      select: {
        textAlign:'center',
        textAlignLast:'center',
        '&:focus': {
          backgroundColor: 'transparent'
        }
      },
      selectRoot: {
        marginRight: '8px'
      },
      MuiIconButton: {
        root: {
          backgroundColor: 'red'
        },
      },
    },
    MuiDialog: {
      root: {
        '& .MuiPaper-root': {
          backgroundColor: "#0B0B26",
          border: '1px solid #9B00F9',
        },
      },
    },
    MuiDialogTitle: {
      root: {
        color: '#00AEFF',
        textTransform: 'uppercase',
        textAlign: 'center',

      }
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'center',
      }
    },
    MuiTypography: {
      body1: {
        textTransform: 'uppercase',
      }
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: '#0B0B26',
        border: '1px solid  #9B00F9',
        fonts: '16px',
        fontWeight: '600',
        textTransform: 'uppercase',
        "&:hover $notchedOutline": {
          borderColor: "#9B00F9"
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "#9B00F9"
        }
      }
    },
    MuiAlert: {
      filledWarning: {
        backgroundColor: '#9B00F9',
      },
      standardSuccess: {
        backgroundColor: '#9B00F9',
        color: '#FFFFFF'
      },
      standardError: {
        backgroundColor: '#9B00F9',
        color: '#FFFFFF'
      },
      standardWarning: {
        backgroundColor: '#9B00F9',
        color: '#FFFFFF'
      },
      standardInfo: {
        backgroundColor: '#9B00F9',
        color: '#FFFFFF'
      }
    },
    MuiCheckbox: {
      root: {
        "& .MuiIconButton-label": {
          position: "relative",
          zIndex: 0
        },
        "&:not($checked) .MuiIconButton-label:after": {
          content: '""',
          left: 4,
          top: 4,
          height: 15,
          width: 15,
          position: "absolute",
          backgroundColor: "#000000",
          zIndex: -1,
        },
    },
  },
    MuiButton: {
      contained: {
        textTransform: 'uppercase',
        fontSize: '16px',
        fontWeight: '600',
        color: 'white',
        borderRadius: '0px'
      },
      containedPrimary: {
        textTransform: 'uppercase',
        fontSize: '16px',
        fontWeight: '600',
        color: 'white',
        borderRadius: '0px'
      },
      outlined: {
        textTransform: 'uppercase',
        fontSize: '16px',
        fontWeight: '600',
        color: 'white',
        borderRadius: '0px'
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#0B0B26',
      },
    },
    MuiPickerDTTabs: {
      tabs: {
        backgroundColor: '#0B0B26',
      }
    },
    MuiCssBaseline: {
      body: {
        '*::-webkit-scrollbar': {
          backgroundColor: 'rgba(222, 193, 218, 0.19)',
          backgroundColor: '#0B0B26',
          height: '10px !important',
          width: '5px',
        },
        '*::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(155, 0, 249, 1)',
          backgroundColor: '#9B00F9',
        }
      },
    },
    MuiTab: {
      root: {
        minWidth: '160px'
      }
  },
    MuiPaper: {
      root: {
        backgroundImage :'none'
      }
    }
}
};

const themesOptions = [
  {
    name: THEMES.DARK,
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    palette: {
      mode: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(155, 0, 249, 0.2)',
        focus: 'rgba(255, 255, 255, 0.12)',
      },
      background: {
        default: '#141342',
        dark: '#141342',
        paper: '#141342'
      },
      divider: '#9B00F9',
      primary: {
        //main: '#80260f'
        main: '#00AEFF'
      },
      secondary: {
        main: '#9b00f9'
      },
      text: {
        primary: '#fff',
        secondary: '#fff'
      },
      success: {
        main: green[500],
        contrastText: '#fff'
      },
      info: {
        main : '#FFFFFF'
      }
    },
    shadows: strongShadows
  }
];

export const createTheme = (config = {}) => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(adaptV4Theme(_.merge({}, baseOptions, themeOptions)));

  return theme;
};
