import React from 'react'
import { Box, Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Height } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
     buttonlink:{
      border:'1px solid  #9B00F9',
      height:'56px'
      
   },
   fontStyleK2D:{
    fontFamily:'K2D',fontSize:'20px',
    [theme.breakpoints.down('sm')]: {
    fontSize:'14px'
    },
   }
}));
export default function Option({
  link = null,
  onClick = null,
  header,
  icon,
  active = false,
  id,
  classes
}) {
   const classes1 = useStyles();
  const content = (
    <Box mb={1} id={id}>
      <Button
        className={classes1.buttonlink}
        onClick={onClick}
        key={id}
        fullWidth
        display="flex"
        variant="outlined"
        color='secondary'
      >
        <Typography variant="body1" color="textPrimary" className={classes1.fontStyleK2D}>
          {header}
        </Typography>
        <Box flexGrow={1} />
        <img
          className={classes.img}
          src={require('../../assets/images/' + icon).default}
        />
      </Button>
    </Box>
  )
  if (link) {
    return <a href={link}>{content}</a>
  }

  return content
}