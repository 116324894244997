/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useContext } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import Logo from 'src/components/Logo';
import { useHistory } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  Paper,
  Grid,
  Typography,
  Tooltip,
  MenuItem,
  MenuList,
  ListItemText,
  ListItemIcon,
  Menu,
  useMediaQuery,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import navcxp1 from '../../../../src/assets/img/navcxp1.svg';
import navcxp2 from '../../../../src/assets/img/navcxp2.svg';
import navcxp3 from '../../../../src/assets/img/navcxp3.svg';
import lefticon from '../../../../src/assets/img/lefticon.svg';
import righticon from '../../../../src/assets/img/righticon.svg';
import exportimport from '../../../../src/assets/img/exportimport.svg';
import leftnav from '../../../../src/assets/img/leftnav.svg';
import rightnav from '../../../../src/assets/img/rightnav.svg';
import quickswap from '../../../../src/assets/img/quickswap.svg';
import vector from '../../../../src/assets/img/Vector.svg';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  User as UserIcon,
  Settings as ToolIcon,
  List as ListIcon,
  CreditCard as CreditcardIcon,
  Phone as PhoneIcon,
  ChevronsDown as WithdrawIcon,
  Award as AwardIcon,
  Calendar as CalendarIcon,
  ShoppingCart as ShoppingCartIcon,
  Send as SendIcon,
  Copy as CopyIcon
} from 'react-feather';
import { createTheme } from '@mui/material/styles';
import { getChainvalue } from 'src/service/battleServerService';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import NavItem from './NavItem';
import NumberFormat from 'react-number-format';
import Logout from './Logout';
import AdminPanel from './AdminPanel';
import ManageEvents from './ManageEvents';
import * as Sentry from '@sentry/react';
import { AuthContext } from '../../../context/AuthContext';
import { getBalance, formatInCHAIN } from '../../../utils/helpers.js';
import { getBalanceFromCS, getReferralKey } from '../../../service/centralServerService';
import { MAX_APPROVED_BALANCE, eligibleReferral, ChainBloxLink, SignUpRedirectURL, stakingURL } from '../../../config/constants';
import WalletSelector from '../../../components/WalletSelector/WalletSelector';
import StorefrontIcon from '@mui/icons-material/Storefront';
import {
  userInfoCSService,
  getMyInfoService
} from '../../../service/node.service';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import BuyTokens from '../../../components/BuyTokens/BuyTokens';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import Ellipse from '../../../../src/assets/img/Ellipse12.svg';
import { useWeb3React } from '@web3-react/core';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { ReactComponent as GroupTestnetTokensLogo } from '../../../../src/assets/img/GroupTestnetTokens.svg';
import DashboardIcon from '@mui/icons-material/Dashboard';
import './scrollbar.css';
import SearchBox from '../TopBar/SearchBox';

const WizardEnums = {
  AccountLink: 1,
  Approve: 2,
  Deposit: 3,
  ConsoleLink: 5
};

const gamesNavigation = [
  {
    items: [
      {
        title: 'Games',
        icon: CalendarIcon,
        href: '/actionGamePage/cod',
        isExternal: false,
        items: [
          // {
          //   title: 'Call of Duty',
          //   href: '/actionGamePage/cod',
          //   isExternal: false
          // },
          // {
          //   title: 'Halo Infinite',
          //   href: '/actionGamePage/halo',
          //   isExternal: false
          // },
          // {
          //   title: 'PUBG',
          //   href: '/actionGamePage/pubg',
          //   isExternal: false
          // },
          // {
          //   title: 'Fifa',
          //   href: '/actionGamePage/fifa',
          //   isExternal: false
          // },
          // {
          //   title: 'Madden',
          //   href: '/actionGamePage/madden2021',
          //   isExternal: false
          // },
          // {
          //   title: 'NBA 2K21',
          //   href: '/actionGamePage/nba',
          //   isExternal: false
          // },
          {
            title: 'Chaino',
            href: 'https://chaino.chaingames.io/',
            isExternal: true
          },
          {
            title: 'Super Crypto Kart',
            href: 'https://chaingames.io/super-crypto-kart',
            isExternal: true
          },
          {
            title: 'Solitaire',
            href: 'https://solitaireplus.chaingames.io',
            isExternal: true
          },
          {
            title: '21Wild',
            href: 'https://21wild.chaingames.io/',
            isExternal: true
          },
          {
            title: 'ChainBlox',
            href: ChainBloxLink,
            isExternal: true
          }
        ]
      }
    ]
  }
];

const sections = [
  {
    items: [
      {
        title: 'MY EVENTS',
        icon: CalendarIcon,
        href: '/myEvents',
        isExternal: false
      },
      {
        title: 'MY CHALLENGES',
        icon: AwardIcon,
        href: '/myChallenges',
        isExternal: false
      },
      {
        title: 'DEPOSIT',
        icon: CreditcardIcon,
        href: '/deposit',
        isExternal: false
      },
      {
        title: 'WITHDRAW',
        icon: WithdrawIcon,
        href: '/withdraw',
        isExternal: false
      },
      {
        title: 'TRANSFER CHAIN',
        icon: SendIcon,
        href: '/tokenTransfer',
        isExternal: false
      },
      {
        title: 'TRANSACTION HISTORY',
        icon: ListIcon,
        href: '/transactionHistory',
        isExternal: false
      },
      // {
      //   title: 'WALLET TRANSACTION HISTORY',
      //   icon: AccountBalanceWalletOutlinedIcon,
      //   href: '/walletTransactionHistory',
      //   isExternal: false
      // },
      {
        title: 'STAKING DASHBOARD',
        icon: DashboardIcon,
        href: stakingURL,
        isExternal: true
      },
      {
        title: 'COMMUNITY NFTS',
        icon: SelectAllIcon,
        href: '/community-nfts',
        isExternal: false
      },
      {
        title: 'REPORT ISSUE',
        icon: ReportProblemOutlinedIcon,
        href: '/reportIssue',
        isExternal: false
      },
      {
        title: 'ACCOUNT SETTING',
        icon: ToolIcon,
        href: '/userAccountSetting',
        isExternal: false
      }
    ]
  }
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
        isExternal={item.isExternal}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
        isExternal={item.isExternal}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer1: {
    paddingLeft: 0,
    width: '347px',
    // top: '81px',
    height: '100vh',
    borderRight: '1px solid #9B00F9',
    position: 'relative',
    overFlow: 'scroll'
  },
  desktopDrawer: {
    width: '347px',
    top: '81px',
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  paper: {
    backgroundColor: '#141342',
    width: 202,
    padding: 0,
    paddingTop: 3
  },
  paperApprove: {
    backgroundColor: '#141342',
    width: 240,
    padding: 0,
    // paddingTop: 3
  },
  button: {
    textTransform: 'none',
    borderRadius: 3,
    "&:disabled": {
      pointerEvents: "auto"
    }
  },
  checkCircle: {
    // color: '#FFFFFF80'
  },
  balanceNav: {
    fontSize: '14px',
    fontWeight: 800

  },
  balancefiat: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  buychain: {
    fontSize: '12px',
    paddingLeft: '30px',
    fontWeight: '500',
    width: '346px',
    height: '51px',
    fontFamily: 'Orbitron',
    background: 'linear-gradient(90.96deg, #010029 22.94%, #141342 95.85%)',
    // left: '68px',
  },
  textChain: {
    fontFamily: 'K2D',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '13px'
  },
  text1Chain: {
    fontFamily: 'K2D',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px'
  },
  text2Chain: {
    fontFamily: 'K2D',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '13px',
    color: '#FF5A5A'
  },
  text3Chain: {
    fontFamily: 'K2D',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '13px',
    color: 'rgb(22, 206, 185)'
  },
  fontStyle: {
    fontWeight: 500,
    fontSize: '12px',
  },
  tokenBox: {
    width: '347px',
    height: '43px',
    background: '#141342',
    paddingTop: '12px',
    paddingLeft: '15px',
    display: 'flex',
    justifyContent: "space-evenly"
  },
  logoBox: {
    width: '347px',
    height: '87px',
    paddingLeft: '27px',
    paddingTop: '27px',
    borderBottom: '1px solid #9B00F9'
  },
  approvedFont: {
    fontFamily: 'K2D',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '13px',
    color: '#FFFFFF'
  },
  userName: {
    fontFamily: 'Orbitron',
    fontWeight: '800',
    fontSize: '18px',

  },
  copyButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontFamily: 'Orbitron',
    fontSize: '12px',
    fontWeight: '800',
    height: '52px'
  },
  menuBuychain: {
    background: 'linear-gradient(90.96deg, #00AEFF 22.94%, #9B00F9 95.85%)',
    width: '346px',
    height: '51px',
    fontSize: '14px',
    fontWeight: '500',
    paddingLeft: '30px'
  },
  menuToken: {
    fontFamily: 'K2D',
    width: '366px',
    height: '47px',
    paddingLeft: '28px'
  },
  menuJoinover: {
    paddingTop: '12px',
    fontWeight: '400',
    fontFamily: 'Orbitron',
    height: '75px',
    width: '348px',
    borderBlockEnd: '1px solid #9B00F9',
    fontSize: '14px',
    paddingLeft: '28px'
  },
  regiButton: {
    fontFamily: 'Orbitron',
    fontSize: '14px',
    fontWeight: '600',
    color: '#141342',
    paddingLeft: '15px'
  },
  menuRegister: {
    background: '#00AEFF',
    marginTop: '4px',
    width: '348px',
    height: '45px'
  },
  loginButton: {
    fontFamily: 'Orbitron',
    fontSize: '12px',
    fontWeight: '500',
    paddingLeft: '15px'
  },
  menuLogin: {
    background: '#9B00F9',
    width: '348px',
    height: '45px',
    marginTop: '24px'
  },
  avatarButton: {
    borderRadius: '50%',
    '&.MuiMenuItem-root': {
      padding: '0px'
    },
    '&.MuiListItem-button:hover': {
      backgroundColor: 'transparent'
    }
  },
}));

const NavBar = ({ onOpen, toggleDrawer }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useContext(AuthContext);
  const ethAccount = user.user?.session?.ethAddress;
  const [showNotification, setShowNotification] = useState(false);
  const [showWizardModal, setShowWizardModal] = useState(false);
  const [chainNetworkBalance, setChainNetworkBalance] = useState(0);
  const [approveBalance, setApprovedBalance] = useState(0);
  const [fiatBalance, setFiatBalance] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);
  const [mintCXPBalance, setMintCXPBalance] = useState(0);
  const [cxpBalance, setCXPBalance] = useState(0);
  const [currentStep, setCurrentStep] = useState(WizardEnums.AccountLink);
  const [username, setUsername] = useState('');
  const [imageURL, setImageURL] = useState();
  const [referralCode, setReferralCode] = useState();
  const [chainvalue, setChainvalue] = useState([]);
  const [chainvaluediff, setChainvaluediff] = useState([]);
  const history = useHistory();
  const [anchorElBuyTokens, setAnchorElBuyTokens] = useState(null);
  const profileNavItem = [
    {
      title: 'PROFILE',
      icon: UserIcon,
      href: '/profile/' + username,
      isExternal: false
    }
  ];
  const CartNavItem = [
    {
      title: 'CART',
      icon: ShoppingCartIcon,
      href: '/cart',
      isExternal: false
    },
  ];
  const [copied, isCopied] = useState(false);
  const { chainId, account } = useWeb3React();
  const [anchorElNFT, setAnchorElNFT] = useState(null);
  const openNFT = Boolean(anchorElNFT);
  const appBarLargeScreen = useMediaQuery('(max-width:1755px)');
  const [userLevel, setUserLevel] = useState();

  useEffect(() => {
    if (!user.user.session?.ethAddress) {
      setShowNotification(true);
    } else {
      setShowNotification(false);
    }

    if (user.user?.session?.username) {
      setUsername(user.user?.session?.username);
    }
  }, [user.user.session]);

  const getProfileImgFromAPI = async () => {
    try {
      const [res] = await Promise.all([
        userInfoCSService({ username })
      ]);
      if (res.data.success === true) {
        setImageURL(res?.data?.userInfo[0]?.profile_picture_high ? res?.data?.userInfo[0]?.profile_picture_high : '');
        setUserLevel(res?.data?.userInfo[0]?.level)
      }
    } catch (error) {
      console.log(
        '🚀 ~ file: DashboardSectionOne.js ~ line 375 ~ getProfileImgFromAPI ~ error',
        error
      );
      Sentry.captureException(error, {
        tags: {
          page: location.pathname
        }
      });
    }
  };

  const handleCloseBuyTokens = () => {
    setAnchorElBuyTokens(null);
  };
  const handleMenuNFT = (event) => {
    setAnchorElNFT(event.currentTarget);
  };
  const handleCloseNFT = () => {
    setAnchorElNFT(null);
  };

  const getChainTokenValue = async () => {
    const { data } = await getChainvalue();
    if (data) {
      setChainvalue(data["chain-games"]?.usd);
      setChainvaluediff((data["chain-games"]?.usd_24h_change - data["chain-games"]?.usd));
    }
  };

  const getBalanceInfoFromAPI = async () => {
    try {
      // const { data } = await getMyInfoService();
      // console.log("🚀 ~ file: index.js:584 ~ getBalanceInfoFromAPI ~ data:", data)
      const balanceInfo = await getBalanceFromCS({});
      if (balanceInfo.data.success) {
        setTotalBalance(balanceInfo.data.bonus);
        setFiatBalance(balanceInfo.data.fiat);
        setMintCXPBalance(balanceInfo.data.data[0].mintableCXPBalance);
        setCXPBalance(balanceInfo.data.data[0].cxpBalance);
        const allowanceFormatInChain = formatInCHAIN(
          balanceInfo.data.data[0].token.allowance
        );
        const networkFormatInChain = formatInCHAIN(
          balanceInfo.data.data[0].token.total
        );

        setApprovedBalance(allowanceFormatInChain);
        setChainNetworkBalance(networkFormatInChain);
        if (ethAccount) {
          if (MAX_APPROVED_BALANCE > allowanceFormatInChain) {
            setCurrentStep(WizardEnums.Approve);
            setShowWizardModal(true);
          } else if (networkFormatInChain <= 0) {
            setCurrentStep(WizardEnums.Deposit);
            setShowWizardModal(true);
          } else {
            setCurrentStep(WizardEnums.ConsoleLink);
            setShowWizardModal(true);
          }
        } else {
          setCurrentStep(WizardEnums.AccountLink);
          setShowWizardModal(true);
        }
      }
    } catch (error) {
      console.log("🚀 ~ file: index.js ~ line 325 ~ getBalanceInfoFromAPI ~ error", error)

      Sentry.captureException(error, {
        tags: {
          page: location.pathname
        }
      });
    }
  };

  const getReferralInfoFromAPI = async () => {
    try {
      const referralInfo = await getReferralKey();

      if (referralInfo.data.success) {
        setReferralCode(referralInfo.data.key);
      }
    } catch (error) {
      console.log("🚀 ~ file: index.js ~ line 464 ~ getReferralInfoFromAPI ~ error", error)

      Sentry.captureException(error, {
        tags: {
          page: location.pathname
        }
      });
    }
  };

  useEffect(() => {
    if (username) {
      getBalanceInfoFromAPI();
      getProfileImgFromAPI();
      getReferralInfoFromAPI();
    }
  }, [username, user.user]);

  useEffect(() => {
    getChainTokenValue();
  }, [])

  const contentlogin = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        {!appBarLargeScreen && <>
          <RouterLink to="/dashboard">
            <Box className={classes.logoBox}>
              <Logo />
            </Box>
          </RouterLink>
        </>
        }
        {appBarLargeScreen && <>
          <MenuItem style={{ fontFamily: 'Orbitron', fontSize: '16px', borderBlockEnd: '1px solid #9B00F9', width: '347px', height: '41px' }}>
            <Grid container direction="row" xs={12} alignItems="center" justifyContent='flex-start' display='flex' spacing={0.5}  >
              {account && <><Box style={{ borderRadius: '30px', backgroundColor: '#9B00F9', width: '94px', height: '25px', display: 'flex', alignItems: 'center', marginRight: '20px' }} >
                <> <img src={Ellipse} /><Typography style={{ fontSize: '10px' }}>{window.location.href.includes(`https://battle.chaingames.io`) ? 'MAINNET' : 'TESTNET'}</Typography></>
              </Box>
                <WalletSelector widthInPixel={'310px'}/></>
              }
              {!account && <><Grid item xs={11} justifyContent='flex-start' display='flex' style={{ paddingLeft: '15px' }} ><WalletSelector widthInPixel={'310px'}/></Grid>
              {/* <Grid item xs={1}>
                <NavigateNextIcon />
              </Grid> */}
              </>}
            </Grid>
          </MenuItem>
        </>}
        <Box style={{ height: '95px', display: 'flex', paddingLeft: '32px', alignItems: 'center' }} >

          <Box>
            <MenuItem onClick={() => {history.push(`/profile/${username}`);toggleDrawer()}} className={classes.avatarButton}>
              <Avatar className={classes.avatar} src={imageURL}></Avatar>
            </MenuItem>
          </Box>

          <Box display='flex' flexDirection="column" justifyContent='center' alignItems='center'>
            <Box >
              <Tooltip
                title={
                  user.user?.session?.username
                    ? user.user.session.username.toUpperCase()
                    : 'USERNAME'
                }
              >
                <Typography className={classes.userName} color="textPrimary" >
                  {user.user?.session?.username
                    ? user.user.session.username.length > 5 ? user.user.session.username.toUpperCase().substring(0, 5) + '....' : user.user.session.username.toUpperCase()
                    : 'USERNAME'}
                </Typography>
              </Tooltip>
            </Box>

            <Box display="flex" >

              <MenuItem
                component={RouterLink}
                to="/approve"
                disabled={MAX_APPROVED_BALANCE <= approveBalance}
                onClick={toggleDrawer}
                fullWidth
              >
                {MAX_APPROVED_BALANCE <= approveBalance ? (
                  <img src={vector}></img>
                ) : (
                  <ErrorOutlineIcon color="error" />
                )}
                {MAX_APPROVED_BALANCE <= approveBalance
                  ? <Typography className={classes.approvedFont}>Approved</Typography>
                  : <Typography className={classes.approvedFont}>Approve</Typography>}
              </MenuItem>
            </Box>

          </Box>
          <Box disple='flex' justifyContent='center' alignItems='center' pl={4}>
            <Box    >
              <img src={navcxp1}></img>
              <img src={navcxp2}></img>
              <img src={navcxp3}></img>
            </Box>
            <Box >
              <Typography variant="body1" color="textPrimary" >
                <NumberFormat
                  value={cxpBalance ? Number(cxpBalance).toFixed(2) : 0}
                  displayType={'text'}
                  thousandSeparator={true}
                />
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection='row' style={{ border: 'solid #9B00F9', borderWidth: '1px 0px 1px 0px' }}>
          <Box
            className={classes.tokenBox}
          >
            <Box style={{ display: "flex" }}>
              <Typography variant="title" color="inherit">
                <img src={leftnav}></img> <img src={rightnav} style={{ marginLeft: '-4px' }}></img>
              </Typography>
              <Typography variant="title" color="inherit" >
                &nbsp;
              </Typography>
              <Typography variant="body2" color="inherit" noWrap className={classes.textChain}>
                $CHAIN Token Price
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.text1Chain}>
                ${chainvalue} USD
              </Typography>
            </Box>
            <Box>
              {chainvaluediff >= 0 ? (
                <Typography className={classes.text3Chain}>
                  +{chainvaluediff.toString().length > 3 ? (chainvaluediff).toString().substring(0, 4) : chainvaluediff}%
                </Typography>
              ) :
                (
                  <Typography className={classes.text2Chain}>
                    {chainvaluediff.toString().length > 3 ? (chainvaluediff).toString().substring(0, 4) : chainvaluediff}%
                  </Typography>
                )
              }
            </Box>
          </Box>
        </Box>

        {/* <Box display="flex" flexDirection='row' style={{ border: 'solid #9B00F9', borderWidth: '0px 0px 1px 0px' }}>
          <Box
            className={classes.tokenBox}
            style={{paddingTop: '8px'}}
          >
            <Box style={{ display: "flex" }}>
              <Typography variant="body2" color="inherit" noWrap className={classes.textChain} style={{fontSize : '16px'}}>
                Level
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.text1Chain} style={{fontSize : '16px'}}>
                {userLevel}
              </Typography>
            </Box>
          </Box>
        </Box> */}

        <Box
          display="flex"
          flexDirection="row"
          //alignItems="stretch"
          //padding={1}
          height='84px'
        >
          <Box width={215} display='flex' justifyContent='center' pl={4} flexDirection='column'>
            <Box >
              <Typography
                display="inline"
                variant="h4"
                color="textPrimary"
                className={classes.balanceNav}
              >
                CHAIN BALANCE
              </Typography>
            </Box>

            <Box display='flex' flexDirection='row'>
              <img src={lefticon}></img>
              <img src={righticon}></img>
              &nbsp;
              &nbsp;
              <Typography display="inline" variant="body1" color="textPrimary" >
                <NumberFormat
                  value={chainNetworkBalance ? chainNetworkBalance : 0}
                  displayType={'text'}
                  thousandSeparator={true}
                />
              </Typography>
            </Box>
          </Box>

          <Box display="flex" justifyContent="center" style={{ backgroundColor: '#3F3D7A3D', width: '141px' }}>
            <MenuItem
              className={classes.button}
              component={RouterLink}
              to="/deposit"
              onClick={toggleDrawer}
            >
              <Typography className={classes.fontStyle}>DEPOSIT</Typography>
              &nbsp; &nbsp; &nbsp;
              <NavigateNextIcon />
            </MenuItem>
          </Box>

        </Box>
        <hr style={{ color: 'rgba(79, 174, 247, 0.21)' }}></hr>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="stretch"
          // padding={1}
          height='84px'
        >
          <Box display='flex' flexDirection='column' width={215} pl={4} justifyContent='center'>
            <Box >
              <Typography
                display="inline"
                variant="subtitle2"
                color="textPrimary"
                className={classes.balanceNav}
              >
                MINTABLE CXP
              </Typography>
            </Box>
            <Box>
              <img src={navcxp1}></img>
              <img src={navcxp2}></img>
              <img src={navcxp3}></img>
              <Typography variant="title" color="inherit" noWrap>
                &nbsp;
              </Typography>
              <Typography display="inline" variant="body1" color="textPrimary">
                <NumberFormat
                  value={mintCXPBalance ? Number(mintCXPBalance).toFixed(2) : 0}
                  displayType={'text'}
                  thousandSeparator={true}
                />
              </Typography>
            </Box>
          </Box>

          <Box justifyContent="center" display="flex" style={{ backgroundColor: '#3F3D7A3D', width: '141px' }}>
            <MenuItem
              className={classes.button}
              size="small"
              color="secondary"
              variant="outlined"
              component={RouterLink}
              to="/mint-cxp-token"
              fullWidth
              onClick={toggleDrawer}
            >
              <Typography className={classes.fontStyle}> MINT CXP</Typography>
              &nbsp; &nbsp; &nbsp;
              <NavigateNextIcon />
            </MenuItem>
          </Box>
          <Box flexGrow={1} />
        </Box>

        <MenuItem
          style={{ background: 'linear-gradient(90.96deg, #00AEFF 22.94%, #9B00F9 95.85%)', height: '51px' }}
          component="a"
          href='https://chaingames.io/exchanges'
        >
          <ListItemText><Box style={{ fontSize: '14px', fontWeight: '800', paddingLeft: '14px' }}>BUY CHAIN</Box></ListItemText>
          <Typography color="text.secondary">
            <NavigateNextIcon />
          </Typography>
        </MenuItem>

        {/* <Box >
          <Paper sx={{ width: 320, maxWidth: '100%', background: 'linear-gradient(90.96deg, #010029 22.94%, #141342 95.85%)' }}>
            <MenuList style={{ paddingTop: '0', paddingBottom: '0' }}>
              <MenuItem
                onClick={() => {
                  handleCloseBuyTokens(), history.push('/buy-chain-transak');
                }}
                color="#fff"
                className={classes.buychain}>

                <Typography className={classes.fontStyle}> BUY VIA FIAT WITH</Typography>
                &nbsp;
                <img src={exportimport} style={{ paddingLeft: '5px', paddingRight: '5px' }}></img>
                &nbsp;
                <ListItemText> <Box style={{ fontSize: 14, fontWeight: '800' }}>Transak</Box></ListItemText>
                <Typography variant="body2" color="text.secondary">
                  <NavigateNextIcon />
                </Typography>
              </MenuItem>
              <hr style={{ color: 'rgba(79, 174, 247, 0.21)' }}></hr>

              <MenuItem
                onClick={handleCloseBuyTokens}
                color="#fff"
                component="a"
                target="_blank"
                // href={QuickswapLink}
                href='https://quickswap.exchange/#/swap?outputCurrency=0xd55fCe7CDaB84d84f2EF3F99816D765a2a94a509'
                className={classes.buychain}
              >
                <Typography className={classes.fontStyle}> BUY VIA</Typography>
                &nbsp;
                <ListItemText> <img src={quickswap} ></img></ListItemText>
                <Typography color="text.secondary">
                  <NavigateNextIcon />
                </Typography>
              </MenuItem>
              <hr style={{ color: 'rgba(79, 174, 247, 0.21)' }}></hr>
              <MenuItem
                onClick={handleCloseBuyTokens}
                color="#fff"  //textAlign:'left',
                component="a"
                target="_blank"
                href="https://chaingames.io/exchanges"
                className={classes.buychain}>
                <ListItemText><Box style={{ fontSize: 12, fontWeight: '500' }}>OTHER EXCHANGES</Box></ListItemText>
                <Typography variant="body2" color="text.secondary">
                  <NavigateNextIcon />
                </Typography>
              </MenuItem>
            </MenuList>
          </Paper>
        </Box> */}
        <Box
          style={{ fontsize: '9px' }}
          paddingTop={1}
          borderBottom='1px solid rgba(79, 174, 247, 0.21)'

        >
          <Tooltip
            title={
              !referralCode
                ? `Reveal your referral code by spending $${eligibleReferral} in the games.`
                : ''
            }
          >
            <Box>
              <Box>
                <Button
                  Width='100%'
                  fullWidth
                  className={classes.copyButton}
                  onClick={() => {
                    navigator.clipboard.writeText(`${SignUpRedirectURL}?referral-code=${referralCode}`);
                    isCopied(true);
                  }}
                  disabled={!referralCode}
                  color='info'
                >
                  <Box pl='22px'>GENERATE REFERRAL LINK</Box>
                  <CopyIcon height='15' width='17' style={{ marginRight: '13px' }} />
                </Button>
              </Box>
              <Typography
                variant="body2"
                style={{ textAlign: 'center', color: '#4faef7' }}
              >
                {copied && 'Copied!'}
              </Typography>
            </Box>
          </Tooltip>
        </Box>
        {appBarLargeScreen && <>
          <Box borderBottom='1px solid rgba(79, 174, 247, 0.21)' >
            <MenuItem
              className={classes.copyButton}
              aria-controls="menu-NFT"
              aria-haspopup="true"
              onClick={handleMenuNFT}
            >
              <Typography style={{ fontFamily: 'Orbitron', fontSize: '12px', paddingLeft: '14px', fontWeight: '800' }} >
                IN-GAME ITEMS
              </Typography>
              <Typography  >
                <ShoppingCartOutlinedIcon />
              </Typography>
            </MenuItem>
          </Box>
          <Menu
            id="menu-NFT"
            anchorEl={anchorElNFT}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            keepMounted
            open={openNFT}
            onClose={handleCloseNFT}
          >
            <MenuItem
              onClick={handleCloseNFT}
              color="#fff"
              component={RouterLink}
              to="/store"
            >
              Marketplace
            </MenuItem>
            <MenuItem
              onClick={handleCloseNFT}
              color="#fff"
              component={RouterLink}
              to="/communitynft-store"
            >
              Community NFT Store
            </MenuItem>
            <MenuItem
              onClick={handleCloseNFT}
              color="#fff"
              component={RouterLink}
              to="/myassets"
            >
              My Items
            </MenuItem>
          </Menu>
          <Box style={{ width: '3%' }}></Box>
          {window.location.href.includes(`https://battle.testnet.chaingames.io`) && <MenuItem
            className={classes.copyButton}
            onClick={() => {
              history.push('/chaingamesFaucet');
              toggleDrawer()
            }}
          >
            <Typography style={{ fontFamily: 'Orbitron', fontSize: '12px', paddingLeft: '14px', fontWeight: '800' }} >
              GET TESTNET TOKENS
            </Typography>
            <Typography>
              <GroupTestnetTokensLogo />
            </Typography>
          </MenuItem>
          }
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <SearchBox />
          </Box>
        </>}

        <Box p={2} pl='20px' style={{ fontSize: '14px' }} onClick={toggleDrawer}>
          {renderNavItems({
            items: profileNavItem,
            pathname: location.pathname
          })}
          {appBarLargeScreen && renderNavItems({
            items: CartNavItem,
            pathname: location.pathname
          })}
          {sections.map((section) => (
            <List key={'personalSection'}>
              {renderNavItems({
                items: section.items,
                pathname: location.pathname
              })}
              <AdminPanel />
              <ManageEvents />
              <Logout />
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );
  const contentlogout = (
    <Box>
      <RouterLink to="/dashboard">
        <Box className={classes.logoBox} borderBottom='1px solid #9B00F9'>
          <Logo />
        </Box>
      </RouterLink>

      <MenuItem style={{ width: '347px', height: '60px' }}>
        <Typography variant='h4' style={{ paddingLeft: '13px' }}>
          WELCOME TO THE <br></br>
          LEADING WEB3 GAMING PLATFORM
        </Typography>

      </MenuItem>
      <MenuItem>
        <Typography variant='h4' style={{ color: '#9B00F9', paddingLeft: '13px' }}  >
          LOGIN OR REGISTER TO PLAY
        </Typography>
      </MenuItem>
      <MenuItem className={classes.menuLogin} onClick={() => {
        history.push('/login');
      }}>
        <Grid container direction="row" alignItems="center" xs={12} >
          <Grid item xs={11} className={classes.loginButton}>
            LOGIN
          </Grid>
          <Grid item xs={1}>
            <NavigateNextIcon />
          </Grid>
        </Grid>
      </MenuItem>

      <MenuItem className={classes.menuRegister} onClick={() => {
        history.push('/register');
      }}>


        <Grid container direction="row" alignItems="center" xs={12} >
          <Grid item xs={11} className={classes.regiButton}>
            REGISTER
          </Grid>
          <Grid item xs={1}>
            <NavigateNextIcon />
          </Grid>
        </Grid>

      </MenuItem>
      <MenuItem className={classes.menuJoinover} >

        JOIN OVER 40,000 PLAYERS

      </MenuItem>
      <Box display="flex" flexDirection='row' style={{ border: 'solid #9B00F9', borderWidth: '1px 0px 1px 0px' }}>
        <Box
          className={classes.tokenBox}
        >
          <Box style={{ display: "flex" }}>
            <Typography variant="title" color="inherit">
              <img src={leftnav}></img> <img src={rightnav} style={{ marginLeft: '-4px' }}></img>
            </Typography>
            <Typography variant="title" color="inherit" >
              &nbsp;
            </Typography>
            <Typography variant="body2" color="inherit" noWrap className={classes.textChain}>
              $CHAIN Token Price
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.text1Chain}>
              ${chainvalue} USD
            </Typography>
          </Box>
          <Box>
            {chainvaluediff >= 0 ? (
              <Typography className={classes.text3Chain}>
                +{chainvaluediff.toString().length > 3 ? (chainvaluediff).toString().substring(0, 4) : chainvaluediff}%
              </Typography>
            ) :
              (
                <Typography className={classes.text2Chain}>
                  {chainvaluediff.toString().length > 3 ? (chainvaluediff).toString().substring(0, 4) : chainvaluediff}%
                </Typography>
              )
            }
          </Box>
        </Box>
      </Box>

      <MenuItem
        style={{ background: 'linear-gradient(90.96deg, #00AEFF 22.94%, #9B00F9 95.85%)', height: '51px' }}
        component="a"
        href='https://chaingames.io/exchanges'
        onClick={toggleDrawer}
      >
        <ListItemText><Box style={{ fontSize: '14px', fontWeight: '800', paddingLeft: '14px' }}>BUY CHAIN</Box></ListItemText>
        <Typography color="text.secondary">
          <NavigateNextIcon />
        </Typography>
      </MenuItem>
    </Box>
  );

  return (
    <>
      {user.user?.isLoggedIn ? (
        <>
          {!appBarLargeScreen && <>
            <Drawer
              anchor="left"
              width="50%"
              classes={{ paper: classes.desktopDrawer1 }}
              open={onOpen}
              variant="persistent"
            >
              {contentlogin}
            </Drawer>
          </>}

          {appBarLargeScreen && <>
            <Drawer
              anchor="right"
              width="50%"
              classes={{ paper: classes.desktopDrawer }}
              open={onOpen}
              variant="persistent"
            >
              {contentlogin}
            </Drawer>
          </>
          }
        </>
      ) : (
        <>
          {!appBarLargeScreen && <>
            <Drawer
              anchor="left"
              width="50%"
              classes={{ paper: classes.desktopDrawer1 }}
              open={onOpen}
              variant="persistent"
            >
              {contentlogout}
            </Drawer>
          </>}
        </>
      )
      }
    </>
  );
};

NavBar.propTypes = {
  onOpen: PropTypes.bool
};

export default NavBar;
