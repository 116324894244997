const {
  formDataAxiosServiceCentralServer,
  nodeAxiosWithCredentialsCentralServer
} = require('../config/axios');

export const registerUser = async (body) => {
  return await nodeAxiosWithCredentialsCentralServer.post(
    `/public/register`,
    body
  );
};

export const loginService = async (body) => {
  return await nodeAxiosWithCredentialsCentralServer.post(
    `/public/login`,
    body
  );
};

export const logoutService = async () => {
  return await nodeAxiosWithCredentialsCentralServer.post(
    `/authenticated/logout`,
    {}
  );
};

export const getBalanceFromCS = async (body) => {
  return await nodeAxiosWithCredentialsCentralServer.post(
    `/authenticated/get-balance`,
    body
  );
};

export const postReportIssue = async (body) => {
  return await formDataAxiosServiceCentralServer.post(
    `/authenticated/report-issue`,
    body
  );
};

export const verifyEmailService = async (body) => {
  return await nodeAxiosWithCredentialsCentralServer.post(
    `/public/verify-email-token`,
    body
  );
};

export const recoverPassword = async (body) => {
  return await nodeAxiosWithCredentialsCentralServer.post(
    `/public/send-reset-link`,
    body
  );
};

export const verifyForgotPasswordTokenService = async (body) => {
  return await nodeAxiosWithCredentialsCentralServer.post(
    `/public/verify-forgot-password-token`,
    body
  );
};

export const resetForgottenPasswordService = async (body) => {
  return await nodeAxiosWithCredentialsCentralServer.post(
    `/public/reset-forgotten-password`,
    body
  );
};

export const changeUserPassword = async (body) => {
  return await nodeAxiosWithCredentialsCentralServer.post(
    `authenticated/change-password`,
    body
  );
};

export const uploadProfileImageService = async (body) => {
  return await formDataAxiosServiceCentralServer.post(
    `authenticated/set-user-dp`,
    body
  );
};


export const getAllGames = async () => {
  return await nodeAxiosWithCredentialsCentralServer.get(
    `/public/get-all-games`
  );
};

export const getAllGamesImage = async () => {
  return await nodeAxiosWithCredentialsCentralServer.get(
    `/public/get-all-games`,
  );
};

export const createNewNFT = async (body) => {
  return await nodeAxiosWithCredentialsCentralServer.post(
    `authenticated/create-nft`,
    body
  );
};


export const createNewScheduleNFT = async (body) => {
  return await nodeAxiosWithCredentialsCentralServer.post(
    `/authenticated/schedule-assets`,
    body
  );
};

export const getAllNFT = async (body) => {
  return await nodeAxiosWithCredentialsCentralServer.get(
    `/public/get-all-nfts`,
    {
      params: {
        body
      }
    }
  );
};


export const getNFTDetailByTokenId = async (gameID,tokenId) => {
  return await nodeAxiosWithCredentialsCentralServer.get(
    `/public/get-asset-details/${gameID}/${tokenId}`,
  );
};

export const getMyAssetsByAddress = async (account, filter) => {
  return await nodeAxiosWithCredentialsCentralServer.get(
    `/public/get-my-assets/${account}`,
    {
      params: {
        filter
      }
    }
  );
};

export const getLeaderBoardDetails = async (body) => {
  return await nodeAxiosWithCredentialsCentralServer.get(
    `/public/get-leaderboard-details`,
    {
    params: {
      body
    }
  }
  );
};

export const uploadBio = async (body) => {
  return await nodeAxiosWithCredentialsCentralServer.post(
    `authenticated/set-bio`,
    body
  );
};

export const uploadSocialMediaLinks = async (body) => {
  return await nodeAxiosWithCredentialsCentralServer.post(
    `authenticated/set-social-account`,
    body
  );
};

export const getReferralKey = async () => {
  return await nodeAxiosWithCredentialsCentralServer.post(
    `authenticated/get-refferal-key`,
  );
};

export const checkUsernameConflict = async (body) => {
  return await nodeAxiosWithCredentialsCentralServer.get(
    `/public/check-username-conflict`,
    {
      params: {
        body
      }
    }
  );
};

export const newUsernameUpdate = async (body) => {
  return await nodeAxiosWithCredentialsCentralServer.post(
    `public/update-username`,
    body
  );
};

export const changeUsername = async (body) => {
  return await nodeAxiosWithCredentialsCentralServer.post(
    `/public/send-username-change-link`,
    body
  );
};

export const  getCreatedNFT = async () => {
  return await nodeAxiosWithCredentialsCentralServer.post(
    `authenticated/get-created-nfts`,
  );
};

export const  UpdateintoCart = async (body) => {
  return await nodeAxiosWithCredentialsCentralServer.post(
    `authenticated/update-cart-asset-quantity`,
    body
  );
};

export const  AddintoCart = async (body) => {
  return await nodeAxiosWithCredentialsCentralServer.post(
    `authenticated/add-to-cart`,
    body
  );
};

export const  getCartItems = async () => {
  return await nodeAxiosWithCredentialsCentralServer.post(
    `authenticated/get-cart-assets`,
  );
};

export const  getCartStatus = async () => {
  return await nodeAxiosWithCredentialsCentralServer.post(
    `authenticated/get-cart-stats`,
  );
};

export const  RemoveFromCart = async (body) => {
  return await nodeAxiosWithCredentialsCentralServer.post(
    `authenticated/remove-from-cart`,
    body
  );
};

export const RejectFromCart = async (body) => {
  return await nodeAxiosWithCredentialsCentralServer.post(
    `authenticated/reject-asset-purchase-from-cart`,
    body
  );
};

export const  getPurchasefromcart = async (body) => {
  return await nodeAxiosWithCredentialsCentralServer.post(
    `authenticated/get-purchase-from-cart`,
    body
  );
};

export const  sendPurchasefromcart = async (body) => {
  return await nodeAxiosWithCredentialsCentralServer.post(
    `authenticated/send-purchase-from-cart`,
    body
  );
};

export const  getCreatedScheduleNFT = async () => {
  return await nodeAxiosWithCredentialsCentralServer.post(
    `authenticated/get-scheduled-assets`,
  );
};

export const EditNewNFT = async (body) => {
  return await nodeAxiosWithCredentialsCentralServer.post(
    `authenticated/edit-created-assets`,
    body
  );
};
 
export const EditNewScheduleNFT = async (body) => {
  return await nodeAxiosWithCredentialsCentralServer.post(
    `authenticated/edit-scheduled-asset`,
    body
  );
};

export const addCommunityNFT = async (body) => {
  return await nodeAxiosWithCredentialsCentralServer.post(
    `authenticated/add-community-nfts`,
    body
  );
};

export const getAllCommunityNFT = async (body) => {
  return await nodeAxiosWithCredentialsCentralServer.post(
    `/authenticated/get-all-community-nfts`,
    body
  );
};

export const getApprovedCommunityNFT = async (body) => {
  return await nodeAxiosWithCredentialsCentralServer.get(
    `/public/get-approved-community-nfts`,
    {
      params: {
        body
      }
    }
  );
};

export const createCommunityNewNFT = async (body) => {
  return await nodeAxiosWithCredentialsCentralServer.post(
    `authenticated/create-community-nft`,
    body
  );
};

export const rejectCommunityNFT = async (body) => {
  return await nodeAxiosWithCredentialsCentralServer.post(
    `authenticated/reject-community-nfts`,
    body
  );
};

export const getPendingCommunityNFT = async (body) => {
  return await nodeAxiosWithCredentialsCentralServer.post(
    `/authenticated/get-pending-community-nfts`,
    body
  );
};

export const getNFTDetailByCommunityAssetId = async (assetId) => {
  return await nodeAxiosWithCredentialsCentralServer.get(
    `/public/get-community-asset-details/${assetId}`,
  );
};

export const getcommunityNFTDetailByTokenId = async (gameID,tokenId) => {
  return await nodeAxiosWithCredentialsCentralServer.get(
    `/public/get-approved-community-asset-details/${gameID}/${tokenId}`,
  );
};

export const saveWalletTx = async (body) => {
  return await nodeAxiosWithCredentialsCentralServer.post(
    `/authenticated/save-wallet-tx`,
    body
  );
};

export const getWalletTxHistory = async (body) => {
  return await nodeAxiosWithCredentialsCentralServer.post(
    `/authenticated/get-wallet-tx-history`,
    body
  );
};

export const getNftTxHistory = async (body) => {
  return await nodeAxiosWithCredentialsCentralServer.post(
    `/authenticated/get-asset-tx-history`,
    body
  );
};

export const updateChain = async (body) => {
  return await nodeAxiosWithCredentialsCentralServer.post(
    `/authenticated/update-chain-config`,
    body
  );
};

export const getAllChains = async (body) => {
  return await nodeAxiosWithCredentialsCentralServer.get(
    `/public/get-supported-chains`
  ); 
}

export const AddNewNews = async (body) => {
  return await nodeAxiosWithCredentialsCentralServer.post(
    `authenticated/add-news`,
    body
  );
};

export const getCreatedNews = async (body) => {
  return await nodeAxiosWithCredentialsCentralServer.post(
    `/authenticated/get-created-news`,
    body
  );
};

export const UpdateCreatedNews = async (body) => {
  return await nodeAxiosWithCredentialsCentralServer.post(
    `authenticated/update-created-news`,
    body
  );
};

export const DeleteNews = async (body) => {
  return await nodeAxiosWithCredentialsCentralServer.post(
    `authenticated/delete-news`,
    body
  );
};