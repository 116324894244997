import { ethers } from "ethers";
import CommunityNFTContractABI from '../contract-builds/CommunityNFTContractABI.json';

const NETWORK_GOERLI = 'goerli';
const NETWORK_MAINNET = 'mainnet';
const NETWORK_TESTNET = 'testnet';

export default class CommunityNFTContract {
  provider;
  contract;

  static PAYMENT_OPTIONS_ONLY_CHAIN = 0;
  static PAYMENT_OPTIONS_ONLY_CXP = 1;
  static PAYMENT_OPTIONS_CHAIN_OR_CXP = 2;
  static PAYMENT_OPTIONS_CHAIN_AND_CXP = 3; // if both

  constructor(contractAddress) {
    this.setDummyProvider();
    this.initializeContractInstance(contractAddress);
  }

  async getAssetQuantity(tokenID) {
    const q = await this.contract.totalSupply(tokenID);
    return q;
  }

  async isAssetExist(tokenID) {
    const isExist = await this.contract.exists(tokenID);
    return isExist;
  }

  async getAssetPrice(tokenID, quantity, paymentTokenAddress) {
    const price = await this.contract.price(
      tokenID,
      quantity,
      paymentTokenAddress
    );
    return price;
  }

  async getAssetBalance(address, tokenID) {
    const q = await this.contract.balanceOf(address, tokenID);
    return q;
  }

  async setApprovalForAll(address, approved) {
    try {
      this.setDefaultSigners();
      return this.contract.setApprovalForAll(address, approved);
    } catch (e) {
      console.error(`failed to setApprovalForAll ${e}`);
      throw e;
    }
  }

  /**
     * 
     * @param {string} sell

  /**
     * 
     * @param {number} tokenId 
     * @param {number} quantities 
     * @param {string} paymentTokenAddess 
     */
  async getPrice(tokenID, quantities, paymentTokenAddess) {
    const price = await this.contract.price(
      tokenID,
      quantities,
      paymentTokenAddess
    );
    return ethers.utils.formatEther(price.toString());
  }

  async getURI(tokenID) {
    const uri = await this.contract.uri(tokenID);
    return uri;
  }

  async getTokenId() {
    return await this.contract.tokenId();
  }
 
  /**
   *
   * @param {string} sellarAddress
   * @param {string} buyerAddress
   * @param {number} tokenID
   * @param {number} qunatity
   * @returns tx hash
   */
  purchaseAsset(sellarAddress, buyerAddress, tokenID, qunatity) {
    try {
      this.setDefaultSigners();
      return this.contract.purchase(
        sellarAddress,
        buyerAddress,
        tokenID,
        qunatity,
        '0x'
      );
    } catch (e) {
      console.error(`failed to purchaseAsset ${e}`);
      throw e;
    }
  }

  /**
   *
   * @param {string} sellarAddress
   * @param {string} buyerAddress
   * @param {number} tokenID
   * @param {number} qunatity
   * @returns tx hash
   */
  async purchaseAssetUsingChain(
    sellarAddress,
    buyerAddress,
    tokenID,
    qunatity
  ) {
    try {
      this.setDefaultSigners();
      return this.contract.purchaseUsingChain(
        sellarAddress,
        buyerAddress,
        tokenID,
        qunatity,
        '0x'
      );
    } catch (e) {
      console.error(`failed to purchaseAssetUsingChain ${e}`);
      throw e;
    }
  }

  /**
   *
   * @param {string} sellarAddress
   * @param {string} buyerAddress
   * @param {number} tokenID
   * @param {number} qunatity
   * @returns tx hash
   */
  purchaseUsingCXP(sellarAddress, buyerAddress, tokenID, qunatity) {
    try {
      this.setDefaultSigners();
      return this.contract.purchaseUsingCXP(
        sellarAddress,
        buyerAddress,
        tokenID,
        qunatity,
        '0x'
      );
    } catch (e) {
      console.error(`failed to purchaseUsingCXP ${e}`);
      throw e;
    }
  }

  setDummyProvider() {
    switch (process.env.REACT_APP_ETH_PROVIDER) {
      case NETWORK_MAINNET:
        this.provider = new ethers.providers.InfuraProvider(
          'matic',
          '514b774b11f2451a9b092acb578835dc'
        );
        this.provider.ready.catch((e) =>
          console.error('Could not create read-only InfuraProvider: ', e)
        );
        break;
      case NETWORK_TESTNET:
      case NETWORK_GOERLI:
        this.provider = new ethers.providers.InfuraProvider(
          'maticmum',
          '514b774b11f2451a9b092acb578835dc'
        );
        this.provider.ready.catch((e) =>
          console.error('Could not create read-only InfuraProvider: ', e)
        );
        break;
      default:
        this.provider = new ethers.providers.JsonRpcProvider(
          'http://127.0.0.1:7545'
        );
        this.provider.ready.catch((e) =>
          console.error(
            'Could not create read-only JsonRpcProvider for development mode: ',
            e
          )
        );
    }
  }

  initializeContractInstance(contractAddress) {
    try {
      if (contractAddress) {
        this.contract = new ethers.Contract(
          contractAddress,
          CommunityNFTContractABI,
          this.provider
        );
      }
    } catch (e) {
      console.error(`failed to initialize CommunityNFTContract`);
      throw e;
    }
  }

  setLocalProvider() {
    if (!window.web3) {
      console.warn('metamask not installed');
      return false;
    }
    this.provider = new ethers.providers.Web3Provider(
      window.web3.currentProvider
    );
    this.provider.ready.catch((e) =>
      console.error('Could not create Web3Provider: ', e)
    );
    return true;
  }

  setDefaultSigners() {
    this.contract = this.contract.connect(this.provider.getSigner());
  }

  setWeb3ReactProvider(provider) {
    if (!provider) return;
    this.provider = provider;
  }

  static hasMetamask() {
    return typeof window.web3 !== 'undefined';
  }

  async metamaskEnabled() {
    try {
      await this.provider.getSigner().getAddress();
      return true;
    } catch (e) {
      return false;
    }
  }

  static enableMetamask() {
    return window.ethereum.enable();
  }

  /**
   * Signer of this transaction should have minter role
   * @param {number} tokenId
   * @param {string} creatorAddress
   * @param {string} ownerAddress // TO address
   * @param {number} quantities
   * @param {string[]} paymentTokens
   * @param {number[]} priceInUSD
   * @param {number} acceptPaymentIn
   * @returns tx hash
   */
  createCommunityAsset(
    tokenId,
    creatorAddress,
    ownerAddress,
    quantity,
    paymentTokens,
    priceInUSD,
    acceptPaymentIn
  ) {
    try {
      this.setDefaultSigners();
      return this.contract.mint(
        tokenId,
        creatorAddress,
        ownerAddress,
        quantity,
        paymentTokens,
        priceInUSD,
        acceptPaymentIn,
        '0x'
      );
    } catch (e) {
      console.error(`failed to create asset ${e}`);
      throw e;
    }
  }

   /**
   *
   * @param {string} transferFromAddress
   * @param {string} transferToAddress
   * @param {number} tokenID
   * @param {number} qunatity
   * @returns tx hash
   */
   transfer(transferFromAddress, transferToAddress, tokenID, qunatity) {
    try {
      this.setDefaultSigners();
      return this.contract.safeTransferFrom(
        transferFromAddress,
        transferToAddress,
        tokenID,
        qunatity,
        '0x'
      );
    } catch (e) {
      console.error(`failed to purchaseAsset ${e}`);
      throw e;
    }
  }

}

