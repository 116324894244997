import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, Hidden, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import NavBar from './NavBar';
import TopBar from './TopBar';
import { ToastContainer } from 'react-toastify';
import Footer from 'src/views/footer/index'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
    backgroundSize: 'cover',
    backgroundImage: `url("https://uploads-ssl.webflow.com/6395a6e4027db0068e0adf6f/639b1cbbe06c696b80c46c77_bg4.png")` 
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64 
    /* ,
    [theme.breakpoints.up('lg')]: {
      paddingRight: 256
    } */
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const appBarLargeScreen = useMediaQuery('(max-width:1755px)');

  const callbackOnNavOpen = useCallback((nav) => {
    setNavOpen(nav);
  }, []);

  return (
    <>
    {appBarLargeScreen && 
      <div className={classes.root}>
        <TopBar/>
        <div className={classes.wrapper}>
          <div className={classes.contentContainer}>
            <div className={classes.content}>
              {children}
              <Box >
                <Footer />
              </Box>
            </div>
          </div>
        </div>
        <ToastContainer autoClose={3000} />
      </div>
      }
      {!appBarLargeScreen && 
      <Box className={classes.root} display='flex' flexDirection='row' >
          
            <Box style={{width:'347px',height:'3127px'}}>                     
            <NavBar onOpen='true'/>
           </Box>
      
       
           <Box className={classes.wrapper} display='flex' flexDirection='column'>
           <TopBar/>
          <div className={classes.contentContainer}>
         
         <div className={classes.content}>
            
              {children}
              <Footer />
            </div>
          </div>
        </Box>
        <ToastContainer autoClose={3000} />
      </Box>
}
      </>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node
};

export default MainLayout;
