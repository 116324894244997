import React from 'react';
import Carousel from '../../../components/Carousel/Carousel';
import NftCarousel from '../../../components/Carousel/NftCarousel';
import { useEffect, useState } from 'react';
import { Card, CardMedia, Link, Typography, useMediaQuery, useTheme, Box, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { SuperCryptoKartLink, ChainOPageLink } from '../../../config/constants';
import righticon from '../../../assets/img/righticon.svg';
import lefticon from '../../../assets/img/lefticon.svg';
import { getAllNFT } from 'src/service/centralServerService';
import { NavLink } from 'react-router-dom';
import {
  PRICE_USD,
} from 'src/constants';
import * as Sentry from '@sentry/react';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    borderRadius: 30,
    height: 'auto',
    backgroundColor: 'transparent'
  },
  image: {
    height: 200,
    borderRadius: 30,
  },
  title: {
    position: 'relative',
    '&:before': {
      position: 'absolute',
      bottom: -2,
      left: -16,
      content: '" "',
      height: 62,
      width: 6,
      backgroundColor: theme.palette.secondary.main,
      marginRight: '20px'
    }
  },
  media: {
    height: '123px',
    overflow: 'hidden',
    position: 'relative',
    transition: '300ms',
    cursor: 'pointer',
    '&:hover': {
      filter: 'brightness(115%)'
    },
    [theme.breakpoints.down('sm')]: {
      height: '90px',
    },
  },
  mediaCaption: {
    textOverflow: 'ellipsis',
    paddingtop:'20px',
    position: 'absolute',
    bottom: 0,
    borderradius: 30,
    paddingTop: '10px',
    paddingLeft:'10px',
    paddingRight:'10px',
    backgroundColor: '#0b0b26',
    color: 'white',
    opacity: 1,
    fontFamily: 'K2D',
    fontSize: 20,
    width: '100%',
    transition: '300ms',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  banner: {
    borderRadius: 30,
    padding: '17px',
    margin: '12px',
    backgroundColor: '#0B0B26',
    boxShadow: "0px 0px 4px 4px rgba(155, 0, 249, 0.3)",
  },
  banner1: {
    borderRadius: 30,
    padding: '17px',
    margin: '17px',
    [theme.breakpoints.down('sm')]: {
      margin: '10px',
      padding: '10px',
    },
    backgroundColor: '#0B0B26',
    boxShadow: "0px 0px 4px 4px rgba(155, 0, 249, 0.3)"
  },
  colortextBox: {
    fontFamily: 'Orbitron',
    textTransform: 'uppercase',
    fontWeight: '700',
    fontSize: '10px',
    color: '#00AEFF',
    [theme.breakpoints.down('sm')]: {
      fontSize: '7px',
    },
  },
  normaltextBox: {
    fontFamily: 'Orbitron',
    textTransform: 'uppercase',
    fontWeight: '800',
    fontSize: '10px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '7px',
    },
  },
  normaltextBox1: {
    fontFamily: 'Orbitron',
    textTransform: 'uppercase',
    fontWeight: '800',
    fontSize: '15px',
    //color:'red',
    [theme.breakpoints.down('sm')]: {
      fontSize: '7px',
    },
  },
  bannercar: {
    objectFit: 'contain',
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: '0px',
    opacity: 1,
    transitionProperty: 'opacity',
    transitionDuration: '400ms',
    transitionTimingFunction: 'ease',
    transitionDelay: '0s'
  },
  cardstylecontainer: {
    height: '320px',
    // width: '346px',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    position: 'relative'
  },
  carddivcontainer: {
    minHeight: 'inherit',
    borderRadius: 'inherit',
    height: '100%',
    width: '100%'
  },
  cardblock: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    display: 'flex',
    height: '100%',
    position: 'relative',
    borderRadius: 'inherit',
    PointerEvent: 'initial',
    cursor: 'pointer'
  },
  nftrow: {
    height: '100%',
    // width: '346px',
    borderRadius: 'inherit',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    maxHeight: '100%',
    maxWidth: '100%',
    overFlow: 'hidden',
    position: 'relative',
    PointerEvent: 'initial',
    cursor: 'pointer'
  },
  previewStyle:{
    width: '100%',
    height: '307px',
    borderRadius: '30px',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      height: '170px',
    },
  },
  usdPrices:{
    fontFamily: 'K2D',
    fontSize: '14px',
    fontWeight: '600',
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
  },
  fontPadding:{
    padding: 5,
    fontSize: 14,
    [theme.breakpoints.down('sm')]: {
      padding: 4,
    },
  }
}));

const Banner = () => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const laptopDevice = useMediaQuery(theme.breakpoints.down('md'));
  const mediumDevice = useMediaQuery(theme.breakpoints.down('lg'));
  const [items, setitems] = useState([]);

  const getNFTs = async () => {
    const nftFiler = {
        sort_by: "NEW"
    }
    try {
      const data = await getAllNFT(nftFiler);
      if (data?.data?.success && data?.data?.assets.length > 0) {
        setitems(data?.data?.assets);
      }
        
    } catch (error) {
      console.log(
        '🚀 ~ file: NftCarousel.js ~ line 144 ~ getNFTs ~ error',
        error
      );
      Sentry.captureException(error, {
        tags: {
          page: location.pathname
        }
      });
    }
}

useEffect(() => {
    getNFTs()
}, []);

  return (
    <Card className={classes.card} elevation={0}>
    <NftCarousel
      className={classes.banner}
      show={mobileDevice ? 2 : laptopDevice ? 2 : mediumDevice ? 3 : 4}
      infiniteLoop = {items.length >= 4 ? true : false}
      items={items.length}
    >
      {items.map((item) => {
        return (
          <NavLink
            to={`/assets/${item.gameID}/${item.token_id}`}>
            <Box className={classes.banner1}>
            {(item.image_preview_file_type?.toLowerCase() == 'jpg' ||
              item.image_preview_file_type?.toLowerCase() == 'png' ||
              item.image_preview_file_type?.toLowerCase() == 'gif' ||
              item.image_preview_file_type?.toLowerCase() == 'svg' ||
              item.image_preview_file_type?.toLowerCase() == 'jpeg' ||
              item.image_preview_file_type?.toLowerCase() == 'webp') && (
              <img src={item.image_preview_url} alt="Logo" className={classes.previewStyle}/>
              )}
               {(item.image_preview_file_type?.toLowerCase() == 'mp4' ||
                item.image_preview_file_type?.toLowerCase() == 'mp3' ||
                item.image_preview_file_type?.toLowerCase() == 'wav' ||
                item.image_preview_file_type?.toLowerCase() == 'ogg' ||
                item.image_preview_file_type?.toLowerCase() == 'webm') && (
              <video
                autoPlay muted
                className={classes.previewStyle}
                controls
                controlsList="nodownload"
                loop
                preload="auto"
                src={item.image_preview_url}
              ></video>
              )}
              <CardMedia
                className={classes.media}
                title={item.Name}
              >
                <Typography className={classes.mediaCaption} style={{ paddingBottom: (item.cxp_usd || item.cxp_usd == 0) ? '0px': '10px'}}>
                  <span style={{ fontFamily: 'Orbitron', fontWeight: '800'}}>
                   {item.asset_name.length>12?item.asset_name[0].toUpperCase() + item.asset_name.slice(1, 13).toLowerCase()+'...'
                   :item.asset_name[0].toUpperCase() + item.asset_name.slice(1, item.asset_name.length).toLowerCase()}
                   </span><br></br>
                  <p className={classes.fontPadding}>
                  </p>
                  <Box display="flex" justifyContent='space-between'>
                    <Box display='flex' flexDirection='column' >
                      <Box className={classes.colortextBox}>
                        GAME:
                      </Box>
                      <Box className={classes.normaltextBox}>
                        {item.game_name}
                      </Box>
                    </Box>
                    {item.quantity_left>0?
                    <Box display='flex' flexDirection="column"> 
                      <Box className={classes.colortextBox}>
                        AVAILABILITY:
                      </Box>  
                      <Box className={classes.normaltextBox}>
                        {item.quantity_left}/{item.asset_quantities}
                      </Box>
                    </Box>
                    :<> <Box display='flex' flexDirection="column"className={classes.normaltextBox1}> 
                      SOLD OUT
                    </Box></>}
                  </Box>  
                  <Box display='flex' mt={1}>
                    {(item.chain_usd || item.chain_usd == 0) && <><img src={lefticon}></img>
                      <img src={righticon}></img>
                      <Typography className={classes.usdPrices}>CHAIN ${item.chain_usd / PRICE_USD}</Typography>
                    </>
                    }
                  </Box>
              
                  {(item.cxp_usd || item.cxp_usd == 0 )&& <Box display='flex' >
                    <img src={lefticon}></img>
                    <img src={righticon}></img>
                    <Typography className={classes.usdPrices}>CXP ${item.cxp_usd / PRICE_USD}</Typography>
                  </Box>
                  }
                </Typography>
              </CardMedia>
            </Box>
          </NavLink>
        );
      })}
    </NftCarousel>
  </Card>
  
  );
};

export default Banner;
  