import Carousel from '../../../components/Carousel/Carousel';

import { Card, CardMedia, Link, Typography, useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { SuperCryptoKartLink, ChainOPageLink } from '../../../config/constants';
import CHAINO_Image from '../../../assets/img/chaino-splash.jpg';

const items = [
  // {
  //   id: 0,
  //   Name: 'Halo Infinite',
  //   Image: '/static/images/halo2.jpg',
  //   link: '/actionGamePage/halo',
  //   openInNewTab: false
  // },
  // {
  //   id: 1,
  //   Name: 'PUBG',
  //   Image: '/static/images/pubg.jpg',
  //   link: '/actionGamePage/pubg',
  //   openInNewTab: false
  // },
  // {
  //   id: 2,
  //   Name: 'Call of Duty',
  //   Image: '/static/images/cod-warzone-sm.jpg',
  //   link: '/actionGamePage/cod',
  //   openInNewTab: false
  // },
  {
    id: 0,
    Name: 'Fifa',
    Image: '/static/images/fifa21.jpg',
    link: '/actionGamePage/fifa',
    openInNewTab: false
  },
  {
    id: 1,
    Name: 'Madden NFL 21',
    Image: '/static/images/madden.jpg',
    link: '/actionGamePage/madden2021',
    openInNewTab: false
  },
  {
    id: 2,
    Name: 'NBA 2K21',
    Image: '/static/images/nba.png',
    link: '/actionGamePage/nba',
    openInNewTab: false
  },
  {
    id: 3,
    Name: 'Fifa',
    Image: '/static/images/fifa21.jpg',
    link: '/actionGamePage/fifa',
    openInNewTab: false
  },
  {
    id: 4,
    Name: 'Madden NFL 21',
    Image: '/static/images/madden.jpg',
    link: '/actionGamePage/madden2021',
    openInNewTab: false
  },
  {
    id: 5,
    Name: 'NBA 2K21',
    Image: '/static/images/nba.png',
    link: '/actionGamePage/nba',
    openInNewTab: false
  },
];

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    height: 400
  },
  image: {
    height: 400,
    backgroundColor: theme.palette.background.dark
  },
  title: {
    position: 'relative',
    '&:before': {
      position: 'absolute',
      bottom: -2,
      left: -16,
      content: '" "',
      height: 62,
      width: 6,
      backgroundColor: theme.palette.secondary.main,
      marginRight: '20px'
    }
  },
  media: {
    height: '100%',
    position: 'relative',
    transition: '300ms',
    cursor: 'pointer',
    '&:hover': {
      filter: 'brightness(115%)'
    }
  },
  mediaCaption: {
    textOverflow: 'ellipsis',

    position: 'absolute',
    bottom: 0,

    paddingLeft: '15px',
    paddingBottom: '10px',
    paddingTop: '10px',

    backgroundColor: 'black',
    color: 'white',
    opacity: 0.4,

    width: '100%',
    height: '25%',

    transition: '300ms',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8
    }
  },
  banner: {
    height: 400
  }
}));

const Banner = () => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));
  const mediumDevice = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <Card raised className={classes.card}>
      <Carousel
        className={classes.banner}
        show={mobileDevice ? 1 : mediumDevice ? 2 : 3}
        infiniteLoop
        items={items.length}
      >
        {items.map((item) => {
          return (
            <Link href={item.link} key={item.id}>
              <CardMedia
                className={classes.media}
                image={item.Image}
                title={item.Name}
              >
                <Typography className={classes.mediaCaption}>
                  {item.Name}
                </Typography>
              </CardMedia>
            </Link>
          );
        })}
      </Carousel>
    </Card>
  );
};

export default Banner;
