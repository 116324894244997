import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider, StyledEngineProvider, adaptV4Theme } from '@mui/material';
import jssPreset from '@mui/styles/jssPreset';
import StylesProvider from '@mui/styles/StylesProvider';
import GlobalStyles from './components/GlobalStyles';
import ScrollReset from './components/ScrollReset';
import useSettings from './hooks/useSettings';
import { createTheme } from './theme';
import  routes,{ renderRoutes } from './routes';
import { AuthContextProvider } from './context/AuthContext.js';
import { MyCartProvider} from './contexts/cartContext';
import { ChainNFTContractProvider } from './context/Contract/index';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { Web3ReactProvider } from '@web3-react/core'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { hooks as metaMaskHooks, metaMask } from './components/connectors/metaMask.js'
import { hooks as walletConnectV2Hooks, walletConnectV2 } from './components/connectors/walletConnectV2.js'
import { hooks as coinbaseWalletHooks, coinbaseWallet } from './components/connectors/coinbase.js'
import { hooks as torusWalletHooks, torusWallet } from './components/connectors/torus.js'

const connectors = [
  [metaMask, metaMaskHooks],
  [walletConnectV2, walletConnectV2Hooks],
  [coinbaseWallet, coinbaseWalletHooks],
  [torusWallet, torusWalletHooks]
]

const jss = create({ plugins: [...jssPreset().plugins] });
const history = createBrowserHistory();

const App = () => {
  const { settings } = useSettings();

  const theme = createTheme(adaptV4Theme({
    theme: settings.theme
  }));

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });

  return (
    <Web3ReactProvider connectors={connectors}>
      <AuthContextProvider>
      <MyCartProvider>
        <ChainNFTContractProvider>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <StylesProvider jss={jss}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <SnackbarProvider dense maxSnack={3}>
                    <Router history={history}>
                      <GlobalStyles />
                      <ScrollReset />
                      {renderRoutes(routes)}
                    </Router>
                  </SnackbarProvider>
                </LocalizationProvider>
              </StylesProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </ChainNFTContractProvider>
       </MyCartProvider>
      </AuthContextProvider>
    </Web3ReactProvider>
  );
};

export default App;
