import React, {useState, useEffect, useContext, useCallback} from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  MenuItem
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useWeb3React } from '@web3-react/core'
import WalletOption from '../WalletOption/WalletOption';
import { isMobile } from 'react-device-detect'
import { shortenAddress } from '../../utils/helpers';
import { recoverPersonalSignature } from 'eth-sig-util';
import { bufferToHex } from 'ethereumjs-util';
import { ADDRESS_SIGNED_FAILURE, ADDRESS_SIGNED_SUCCESS, WALLET_DISCONNECTED } from '../../actions/actions.js';
import { AuthContext } from '../../context/AuthContext.js';
import usePrevious from '../../hooks/usePrevious';
import { getNonceForSign, verifyNonce } from '../../service/node.service';
import useConnectedWalletName from '../../hooks/useConnectedWalletName';
import { useLocation } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useConnectedWallet from '../../hooks/useConnectedWallet';
import { useChainNFTContract } from '../../context/Contract/index';
import { MetaMask } from '@web3-react/metamask';
import { walletConnectV2 } from '../connectors/walletConnectV2';
import { metaMask } from '../connectors/metaMask';
import { coinbaseWallet } from '../connectors/coinbase';
import { CoinbaseWallet } from '@web3-react/coinbase-wallet';
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2';
import { torusWallet } from "../../components/connectors/torus";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    position: 'relative',
    fontSize: 32
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white'
  },
  img: {
    cursor: 'pointer',
    width: 28,
    height: 28
  },
  dialogTitle: {
    // backgroundColor: theme.palette.background.dark,
    paddingRight: 0,
    paddingTop: 8,
    paddingBottom: 8,
    marginBottom: 8
  },

  DialogMain: {
    '&.MuiDialog-root .MuiPaper-root': {
      width: '100%',
      maxWidth: '929px',
      height: 'auto',
      maxHeight: '444px',
      paddingTop: '10px',
      paddingBottom: '20px',
      paddingLeft: '10px',
      paddingRight: '10px',
    }
  },
  dialogtitle: {
    fontSize: '26px',
    fontWeight: '600',
    textTransform: 'upperCase',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
      display: 'flex',
      justifyContent: 'flex-start',
      textAlign: 'start'
    },
  },
  dialogtitle1: {
    fontSize: '26px',
    fontWeight: '600',
    textTransform: 'upperCase',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
    },
  },
  Dialogfont: {
    fontFamily: 'K2D',
    textTransform: 'uppercase',
    fontSize: '600',
    fontSize: '18px',
    textAline: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
  DialogButton: {
    height: '53px',
    width: '191px',
    [theme.breakpoints.down('sm')]: {
      height: '53px',
      width: '144px',
    },
  },
}));

export const SUPPORTED_WALLETS = {
  METAMASK: {
    connector: metaMask,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D',
  },
  WALLET_CONNECT: {
    connector: walletConnectV2,
    name: 'WalletConnect',
    iconName: 'walletConnectIcon.svg',
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#4196FC',
    mobile: true
  },
  WALLET_LINK: {
    connector: coinbaseWallet,
    name: 'Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Use Coinbase Wallet app on mobile device',
    href: null,
    color: '#315CF5',
    mobile: true
  },
  TORUS_WALLET: {
    connector: torusWallet,
    name: 'Torus Wallet',
    iconName: 'torus.png',
    description: 'Use Coinbase Wallet app on mobile device',
    href: null,
    color: '#315CF5',
    mobile: true
  }
}

export default function WalletSelector(props) {
  const [modalShow, setModalShow] = useState(false);
  const { connector, provider, account, isActivating, isActive } = useWeb3React();
  const {user, dispatch} = useContext(AuthContext);
  const [isSigningInProcess, setIsSigningInProcess] = useState(false);
  const [accountCheckModalShow, setAccountCheckModalShow] = useState(false);
  const [verificationError, setVerificationError] = useState('');
  const prevAccount = usePrevious(account);
  const walletName = useConnectedWalletName();
  const location = useLocation();
  const classes = useStyles();
  const [ connectedWallet, setConnectedWallet ] = useConnectedWallet()
  const { chainNFTContract, communityNFTContract } = useChainNFTContract();
  const userSavedAddress = user.user.session? user.user.session.ethAddress : '';
  const prevConnector = usePrevious(connector);

  // let account = accounts?.slice(-1).toString();

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const tryActivation = React.useCallback(
    async (connector, skipSetConnectedWallet = false) => {
      try {
        if(isActive && prevConnector){
          prevConnector.deactivate ? prevConnector.deactivate() : prevConnector.resetState();
        }
        if (!skipSetConnectedWallet) {
          Object.keys(SUPPORTED_WALLETS).map((key) => {
            if (connector === SUPPORTED_WALLETS[key].connector) {
              setConnectedWallet(key);
              return false;
            }
            return true;
          });
        }
      }
      catch (error) {
        console.log("🚀 ~ file: WalletSelector.js ~ line 90 ~ async(connector,skipSetConnectedWal ~ error", error)
      }
      await sleep(1000);
      if (connector instanceof WalletConnectV2) {
        setModalShow(false);
      }
      await connector.activate()
        .then((res) => {
          setModalShow(false);
        })
        .catch((error) => {
          console.log(error, "error in try activation")
          setModalShow(false);
        }); 
    },
    [setConnectedWallet]
  );


  useEffect(()=> {
    if(provider) {
      for(let i=0; i<chainNFTContract.length; i++){
        chainNFTContract[i].setWeb3ReactProvider(provider)
      }
    }
  }, [provider,
    chainNFTContract,
    account])

    useEffect(()=> {
      if(provider) {
        for(let i=0; i<communityNFTContract.length; i++){
        communityNFTContract[i].setWeb3ReactProvider(provider)
      }
    }
  }, [provider,
    communityNFTContract,
    account])

  useEffect(() => {
    async function tryToConnect() {
      if (connectedWallet) {
        if (connectedWallet === 'WALLET_CONNECT') {
          tryActivation(SUPPORTED_WALLETS.WALLET_CONNECT.connector, true)
        } else if (connectedWallet === 'WALLET_LINK') {
          tryActivation(SUPPORTED_WALLETS.WALLET_LINK.connector, true)
        } else if (connectedWallet === 'METAMASK') {
          tryActivation(SUPPORTED_WALLETS.METAMASK.connector, true)
        } else if(connectedWallet === 'TORUS_WALLET'){
          tryActivation(SUPPORTED_WALLETS.TORUS_WALLET.connector, true)
          // torusWallet.connectEagerly().catch((error) => {
          //   console.debug("Failed to connect Torus Wallet")
          // })
        }
      }
    }
    tryToConnect()
  }, [connectedWallet]);  

  useEffect(() => {
    if(!account) {
      setAccountCheckModalShow(false)
      return;
    } else if (user.user.isLoggedIn && prevAccount !== account && account && prevAccount && ((userSavedAddress?.toLowerCase()) !== (account?.toLowerCase()))) {
      setAccountCheckModalShow(true)
    } else if(user.user.isLoggedIn && account && (userSavedAddress?.toLowerCase()) !== (account?.toLowerCase())) {
      setAccountCheckModalShow(true)
    } else if(account && (userSavedAddress?.toLowerCase()) === (account?.toLowerCase())) {
      setAccountCheckModalShow(false);
    } else if(!account && !userSavedAddress) {
      setAccountCheckModalShow(false);
    }

    if(!user.user.isLoggedIn)
    {
      setAccountCheckModalShow(false);
    }
  }, [account, prevAccount, userSavedAddress]);

  const getSignature = useCallback(async () => {
    if(user && user.user && provider) {
      const isMetaMask = provider.provider.isMetaMask;
      const {data: {message: msg}} =  await getNonceForSign()
      if (isMetaMask) {
        try {
          let signature = await provider.getSigner().signMessage(msg);
          const { data } = await verifyNonce({
            signature: signature,
            newAddress: account
          });
          if (data.success) {
            return true;
          } else {
            return false;
          }
        } catch (error) {
          return false;
        }
      } else if (walletName === 'Coinbase Wallet') {
        function getDetailsCoinbase() {
          // works only for Coinbase, need to serach for wallet connect
          let coinbaseInfo =
            provider.provider._personal_sign &&
            provider.provider
              ._personal_sign([msg, account])
              .then(({ result: signature }) => {
                // We now are in possession of msg, publicAddress and signature. We
                // will use a helper from eth-sig-util to extract the address from the signature
                const msgBufferHex = bufferToHex(Buffer.from(msg, 'utf8'));
                const address = recoverPersonalSignature({
                  data: msgBufferHex,
                  sig: signature
                });
                return [signature, address];
              })
              .catch((err) => {
                console.log(
                  '🚀 ~ file: WalletSelector.js ~ line 324 ~ getDetailsCoinbase ~ err',
                  err
                );
                dispatch({
                  type: ADDRESS_SIGNED_FAILURE
                });
                setIsSigningInProcess(false);
                return false;
              });
          return coinbaseInfo;
        }
        try {
          const [signature, address] = await getDetailsCoinbase();
          const { data } = await verifyNonce({
            signature: signature,
            newAddress: account
          });
          // The signature verification is successful if the address found with
          // sigUtil.recoverPersonalSignature matches the initial publicAddress
          if (address.toLowerCase() === account.toLowerCase() && data.success) {
            setIsSigningInProcess(false);
            return true;
          } else {
            setIsSigningInProcess(false);
            return false;
          }
        } catch (error) {
          console.log(
            '🚀 ~ file: WalletSelector.js ~ line 352 ~ getSignature ~ error',
            error
          );
          dispatch({
            type: ADDRESS_SIGNED_FAILURE
          });
          setIsSigningInProcess(false);
          return false;
        }
      } else if (walletName === 'WalletConnect') {
        try {
          const signature =
            await provider.provider.request({
              method: "personal_sign",
              params: [msg, account]
            });
          const { data } = await verifyNonce({
            signature: signature,
            newAddress: account
          });
          if (data.success) {
            return true;
          } else {
            return false;
          }
        } catch (error) {
          console.log(
            '🚀 ~ file: WalletSelector.js ~ line 363 ~ getSignature ~ error',
            error
          );
          dispatch({
            type: ADDRESS_SIGNED_FAILURE
          });
          setIsSigningInProcess(false);
          return false;
        }
      } 
      else if (walletName === 'Torus Wallet') {
        try {
          let Web3 = await import('web3');
          Web3 = Web3.default;
          const web3 = new Web3(provider.provider);
          const signature = await web3.currentProvider.send('personal_sign', [
            bufferToHex(Buffer.from(msg, 'utf8')),
            account
          ]);
          const { data } = await verifyNonce({
            signature: signature.result,
            newAddress: account
          });
          if (data.success) {
            return true;
          } else {
            return false;
          }
        } catch (error) {
          console.log(
            '🚀 ~ file: WalletSelector.js ~ line 259 ~ getSignature ~ error',
            error
          );
          dispatch({
            type: ADDRESS_SIGNED_FAILURE
          });
          setIsSigningInProcess(false);
          return false;
        }
      }
      // else if (walletName === 'Portis') {
      //   try {
      //     let Web3 = await import('web3');
      //     Web3 = Web3.default;
      //     const web3 = new Web3(provider);
      //     const signature = await web3.currentProvider.send('personal_sign', [
      //       bufferToHex(Buffer.from(msg, 'utf8')),
      //       account
      //     ]);
      //     const { data } = await verifyNonce({
      //       signature: signature,
      //       newAddress: account
      //     });
      //     if (data.success) {
      //       return true;
      //     } else {
      //       return false;
      //     }
      //   } catch (error) {
      //     console.log(
      //       '🚀 ~ file: WalletSelector.js ~ line 259 ~ getSignature ~ error',
      //       error
      //     );
      //     dispatch({
      //       type: ADDRESS_SIGNED_FAILURE
      //     });
      //     setIsSigningInProcess(false);
      //     return false;
      //   }
      // } else if (walletName === 'Torus (Connect Social Media Account)') {
      //   try {
      //     let Web3 = await import('web3');
      //     Web3 = Web3.default;
      //     const web3 = new Web3(provider);
      //     const signature = await web3.currentProvider.send('personal_sign', [
      //       bufferToHex(Buffer.from(msg, 'utf8')),
      //       account
      //     ]);
      //     const { data } = await verifyNonce({
      //       signature: signature.result,
      //       newAddress: account
      //     });
      //     if (data.success) {
      //       return true;
      //     } else {
      //       return false;
      //     }
      //   } catch (error) {
      //     console.log(
      //       '🚀 ~ file: WalletSelector.js ~ line 259 ~ getSignature ~ error',
      //       error
      //     );
      //     dispatch({
      //       type: ADDRESS_SIGNED_FAILURE
      //     });
      //     setIsSigningInProcess(false);
      //     return false;
      //   }
      // } 
      else {
        console.log(walletName);
        console.log('Error: Unsupported wallet type');
        setIsSigningInProcess(false);
      }
    }
  }, [user, provider, account, dispatch, isSigningInProcess])

  // useEffect(() => {
  //   if (isActive === false) {
  //     dispatch({
  //       type: WALLET_DISCONNECTED,
  //     });
  //     setConnectedWallet(null);
  //   }
  // }, [isActive, ]);

  const killSession = (e) => {
    e.preventDefault();
    if (connector) {
      if(connector?.deactivate) {
        connector.deactivate()
      }
      else {
        connector.resetState();
      }
      dispatch({
        type: WALLET_DISCONNECTED,
      });
      setConnectedWallet(null);
    }
  }

  const verificationClick = async () => {
    const errMsg = 'Verification failed. Please try again.'
    setVerificationError(false);
    try {

      const verified = await getSignature()
      if(verified) {
        dispatch({
          type: ADDRESS_SIGNED_SUCCESS,
          payload: {
            account: account
          }
        })
        setAccountCheckModalShow(false);
      } else {
        setVerificationError(errMsg)
      }
    } catch (error) {
      console.log("verificationClick -> error", error)
      setVerificationError(errMsg)
    }
  }

  function getOptions() {
    try {
      const isMetamask = window.ethereum && window.ethereum.isMetaMask
      return Object.keys(SUPPORTED_WALLETS).map(key => {
        const option = SUPPORTED_WALLETS[key]

        if (isMobile) {
          if (option.mobile) {
            return <WalletOption
              onClick={() => {
                tryActivation(option.connector)
              }}
              id={`connect-${key}`}
              key={key}
              active={option.connector && option.connector === connector}
              link={option.href}
              header={option.name}
              icon={option.iconName}
              classes={classes}
            />
          }
          return null
        }
        if (option.connector === metaMask) {
          // don't show injected if there's no injected provider
          if (!(window.web3 || window.ethereum)) {
            if (option.name === 'MetaMask') {
              return (
                <WalletOption
                  id={`connect-${key}`}
                  key={key}
                  header={'Install Metamask'}
                  link={'https://metamask.io/'}
                  icon={option.iconName}
                  classes={classes}
                />
              )
            } else {
              return null //dont want to return install twice
            }
          }
          // don't return metamask if injected provider isn't metamask
          else if (option.name === 'MetaMask' && !isMetamask) {
            return null
          }
        }
        return (
          !isMobile && (
            <WalletOption
              id={`connect-${key}`}
              onClick={() => {
                tryActivation(option.connector)
              }}
              key={key}
              active={option.connector === connector}
              link={option.href}
              header={option.name}
              icon={option.iconName}
              classes={classes}
            />
          )
        )
      })
    }
    catch (error) {
      console.log("🚀 ~ file: WalletSelector.js ~ line 288 ~ getOptions ~ error", error)
    }
  }

  return <>
    <Box className={classes.buttonContainer} style={{ position: 'relative' }}>

      <MenuItem
        disableGutters
        variant={props.variant}
        style={{fontFamily: 'Orbitron',fontSize: '12px',fontWeight:'500', display: 'flex', width: props.widthInPixel}}
        onClick={() => setModalShow(true)}
      >
        {account ? (
          <div>
            CONNECTED: {shortenAddress(account, 6, 4)}
          </div>
        ) : (
          <div>
            CONNECT WALLET
            <NavigateNextIcon style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)' }} />
          </div>
        )}
        {/* {!account && (<div>CONNECT WALLET
           <NavigateNextIcon style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)' }} />
           </div>)
        } */}
      </MenuItem>

    </Box>
    <Dialog
      className={classes.DialogMain}
      fullWidth
      open={modalShow}
      onClose={() => {
        setModalShow(false);
      }}
    >
      <DialogTitle>
        <Typography className={classes.dialogtitle} >
        {account ? 'Wallet connected' : 'Connect to a wallet' }
        </Typography>
        <IconButton
          aria-hidden={true}
          className={classes.closeButton}
          onClick={() => {
            setModalShow(false);
          }}
          size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {getOptions()}
      </DialogContent>
      <DialogActions>
        {account && (
          <Button variant="contained" color="secondary" onClick={killSession} className={classes.DialogButton}>
            Disconnect
          </Button>
        )}
      </DialogActions>

    </Dialog>

    <Dialog
      className={classes.DialogMain}
      fullWidth
      open={accountCheckModalShow}
      onClose={() => {
        setAccountCheckModalShow(false);
      }}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography className={classes.dialogtitle} variant="h5">
          Account Verification
        </Typography>
        <IconButton
          aria-hidden={true}
          className={classes.closeButton}
          onClick={() => {
            setAccountCheckModalShow(false);
          }}
          size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {account ? (
          <>
            <Box mb={1}>
              <Typography variant="h4" style={{ fontFamily: 'K2D', textTransform: 'upperCase', fontSize: '20px' }}>
                {userSavedAddress
                  ? "We've detected account change."
                  : 'Your Ethereum address is not linked'}
              </Typography>
            </Box>
            <Typography style={{ whiteSpace: 'pre-line' }} className={classes.Dialogfont}>
              Please verify your new address {account} by signing one time
              nonce
              {userSavedAddress
                ? ` or change your
                address from wallet to ${userSavedAddress} to proceed.`
                : ''}
            </Typography>
            <Typography style={{ whiteSpace: 'pre-line', fontWeight: 600 }} className={classes.Dialogfont}>
              {userSavedAddress
                ? `
                Note: Not recommended to change the linked address. It may create a problem with pending winning rewards and transaction history.`
                : ''}
            </Typography>
          </>
        ) : (
          <Typography className={classes.Dialogfont} >
            We've detected no account. Please connect account{' '}
            {userSavedAddress} to proceed
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        {account ? (
          <Button
            variant="contained"
            color="primary"
            onClick={verificationClick}
            className={classes.DialogButton}
          >
            Verify
          </Button>
        ) : (
          ''
        )}
        <Button
          variant="contained"
          color="secondary"
          className={classes.DialogButton}
          onClick={() => setAccountCheckModalShow(false)}
        >
          Cancel
        </Button>
      </DialogActions>
      <Box ml={3} mt={1} mb={1}>
        <Typography color="error" style={{textAlign:'center',fontWeight:'600'}}>{verificationError}</Typography>
      </Box>
    </Dialog>
  </>;
}

WalletSelector.defaultProps = {
  variant: "outlined",
}