import React from 'react';
import { Box } from '@mui/material';
import logo from '../assets/img/logo-01.svg';

const Logo = (props) => {
  return (
    <Box style={{ justifyContent: 'center' }}>
      <img alt="Logo" src={logo} width="220px" {...props} />
    </Box>
  );
};

export default Logo;
