import React, {
    useState
  } from 'react';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router-dom';
import { QuickswapLink } from '../../config/constants';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
}))

const BuyTokens = () => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorElBuyTokens, setAnchorElBuyTokens] = useState(null);
  const openBuyTokens = Boolean(anchorElBuyTokens);


  const handleMenuBuyTokens = (event) => {
    setAnchorElBuyTokens(event.currentTarget);
  };

  const handleCloseBuyTokens = () => {
    setAnchorElBuyTokens(null);
  };

  return (
    <>
    <Box className={classes.buttonContainer}>
        <Button 
        style={{width: 120, marginRight: 30, borderRadius: 30, background: 'linear-gradient(to right bottom, #9b00f9, #00aeff)'}}
         size="small"
          aria-controls="menu-buytokens"
          aria-haspopup="true"
          onClick={handleMenuBuyTokens}
          borderradius="5"
          BackgroundColor="#fff"
          color="#fff"
           >
          Buy Tokens
        </Button>
        <Menu
          id="menu-buytokens"
          anchorEl={anchorElBuyTokens}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          keepMounted
          open={openBuyTokens}
          onClose={handleCloseBuyTokens}
        >
          <MenuItem
            onClick={() => {
              handleCloseBuyTokens(), history.push('/buy-chain-transak');
            }}
            color="#fff"
          >
            Transak (Buy with fiat)
          </MenuItem>
          <MenuItem
            onClick={handleCloseBuyTokens}
            color="#fff"
            component="a"
            target="_blank"
            href={QuickswapLink}
          >
            Quickswap
          </MenuItem>
          <MenuItem
            onClick={handleCloseBuyTokens}
            color="#fff"
            component="a"
            target="_blank"
            href="https://chaingames.io/exchanges"
          >
            Other Exchanges
          </MenuItem>
           {window.location.href.includes(`https://battle.testnet.chaingames.io`) && <MenuItem
            onClick={handleCloseBuyTokens}
            color="#fff"
            component="a"
            target="_blank"
            href="https://faucet.chaingames.io/"
          >
            Chain Games Faucet
          </MenuItem>
          }
        </Menu>
        </Box>
        </>
  );
};

export default BuyTokens;
  