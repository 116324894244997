import React, { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Typography,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  SvgIcon,
  Tooltip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FIFA_Image from '../../../assets/img/fifa21.jpg';
import NBA_Image from '../../../assets/img/nba.jpg';
import COD_Image from '../../../assets/img/cod.jpg';
import MADDEN_Image from '../../../assets/img/madden.png';
import { SupportedGamesWithID, WildGameURL, gameID, ChainoGameURL, SolitaireGameUrl, ChainbloxGameUrl } from '../../../config/constants';
import { Star as StarIcon } from 'react-feather';
import DeviceIconAndName from '../../gameInfo/DeviceIconAndName';
import Halo_Image from '../../../assets/img/halo.jpg';
import CHAINO_Image from '../../../assets/img/chaino-splash.jpg';
import WILDGAME_Image from '../../../assets/img/21-wild.png';
import SOLITAIRE_Image from '../../../assets/img/solitaireplus-dash.jpg';
import PUBG_Image from '../../../assets/img/pubg.jpg';
import ChainBlox_Image from '../../../assets/img/chainblox.jpg';

const font = "'K2D', sans-serif";

const useStyles = makeStyles((theme) => ({
  gamesTitle: {
    fontFamily: font,
    fontSize: 40
  },
  title: {
    position: 'relative',
    '&:before': {
      position: 'absolute',
      bottom: -2,
      left: -16,
      content: '" "',
      height: 62,
      width: 6,
      backgroundColor: theme.palette.secondary.main,
      marginRight: '20px'
    }
  },
  '@keyframes fadeIn': {
    '0%': {
      color: theme.palette.secondary.main
    },
    '100%': {
      color: '#30913C'
    }
  },
  icon: {
    marginRight: theme.spacing(1),
    animation: '$fadeIn 1s alternate infinite'
  },
  imageCell: {
    height: '60px',
    width: '60px',
    padding: 0
  },
  rowImage: {
    height: '60px',
    width: '60px',
    margin: 0,
    padding: 0,
    verticalAlign: 'top',
    objectFit: 'cover'
  },
  entry: {
    fontFamily: font,
    fontSize: 16
  },
  entryFree: {
    fontFamily: font,
    fontSize: 16,
    color: theme.palette.secondary.main
  },
  priceCell: {
    color: theme.palette.success.main,
    fontFamily: font,
    fontSize: 16
  },
  noEvents: {
    minHeight: '100px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'
  },
  noEventsText: {
    fontSize: 32
  },
  scrollBox: {
    overflow: 'auto'
  }
}));

const applyPagination = (list, page, limit) => {
  return list.slice(page * limit, page * limit + limit);
};

const Tournaments = ({ tournaments }) => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState('all');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const tabs = [
    { value: 'all', label: 'All' },
    { value: 'free', label: 'Free' },
    { value: 'paid', label: 'Paid' },
    { value: 'private match', label: 'private match'}
  ];

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value));
  };

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  const filterEvents = () => {
    const result = tournaments.filter((event) => {
      if (currentTab === 'free') {
        if (event.betAmount === 'Free') {
          return true;
        }
      } else if (currentTab === 'paid') {
        if (event.betAmount !== 'Free' && !event.creatorRake ) {
          return true;
        }
      }else if (currentTab === 'private match') {
        if (event.creatorRake) {
          return true;
        }
      }else {
        return true;
      }
    });
    return result;
  };

  const filteredEvents = filterEvents();
  const paginatedEvents = applyPagination(filteredEvents, page, limit);

  const getImage = (game) => {
    switch (game) {
      case SupportedGamesWithID[0].name:
        return COD_Image;
      case SupportedGamesWithID[1].name:
        return MADDEN_Image;
      case SupportedGamesWithID[2].name:
        return FIFA_Image;
      case SupportedGamesWithID[3].name:
        return NBA_Image;
      case SupportedGamesWithID[4].name:
        return Halo_Image;
      case SupportedGamesWithID[5].name:
        return PUBG_Image;                                                                        
      case SupportedGamesWithID[6].name:
        return CHAINO_Image;  
      case SupportedGamesWithID[7].name:
        return WILDGAME_Image;
      case SupportedGamesWithID[8].name:
        return SOLITAIRE_Image; 
      case SupportedGamesWithID[9].name:  
        return ChainBlox_Image;                                                    ;  
    }
  };

  return (
    <Container maxWidth="lg">
      <Box ml={2} mt={10} mb={3}>
        <Typography
          display="inline"
          variant="h2"
          color="textPrimary"
          className={classes.gamesTitle}
        >
          Tournaments
        </Typography>
        <Typography className={classes.title} variant="h4" color="textPrimary">
          Here you can find all upcoming tournaments.
        </Typography>
      </Box>
      <Box mt={1} mb={3}>
        <Tabs
          onChange={handleTabsChange}
          scrollButtons="auto"
          textColor="secondary"
          value={currentTab}
          variant="scrollable"
        >
          {tabs.map((tab) => (
            <Tab key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </Tabs>
        <Divider />
      </Box>
      {paginatedEvents?.length > 0 ? (
        <Card className={classes.scrollBox}>
          <Box minWidth={300}>
            <Table>
              <TableHead>
                <TableRow>
                  {currentTab==='private match' && <TableCell>Organizer</TableCell>}
                  <TableCell>Game</TableCell>
                  <TableCell>Tournament Name</TableCell>
                  <TableCell>Game Format</TableCell>
                  <TableCell>Start Time</TableCell>
                  <TableCell>Entry</TableCell>
                  <TableCell>Prize Pool</TableCell>
                  <TableCell align="center">Join</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedEvents.map((entry) => {
                  return (
                    <TableRow spacing={0} hover key={entry.id}>
                      {currentTab==='private match' &&  
                      <TableCell component={RouterLink} to={`/profile/${entry.creatorUsername}`} style={{ textDecoration: 'none' }}>
                          {entry.creatorUsername}
                      </TableCell>}
                      <TableCell
                        className={classes.imageCell}
                        align="center"
                        padding="none"
                      >
                        <img
                          className={classes.rowImage}
                          src={getImage(entry.game)}
                        />
                      </TableCell>
                      <TableCell>
                        {entry.betAmount === 'Free' && (
                          <Tooltip title="Sponsored Event!">
                            <SvgIcon className={classes.icon} fontSize="small">
                              <StarIcon />
                            </SvgIcon>
                          </Tooltip>
                        )}
                        {entry.name}
                      </TableCell>
                      <TableCell>{entry.gameFormat}</TableCell>
                      <TableCell>{`${entry.date} ${entry.time}`}</TableCell>
                      <TableCell
                        className={
                          entry.betAmount !== 'Free'
                            ? classes.entry
                            : classes.entryFree
                        }
                      >
                        {entry.betAmount}
                      </TableCell>
                      <TableCell className={classes.priceCell}>
                        {entry.prizePool}
                      </TableCell>
                      <TableCell className={classes.priceCell} align="center">
                        {(entry.game !==
                          SupportedGamesWithID[gameID.WILD_GAME_ID].name && entry.game !==
                          SupportedGamesWithID[gameID.CHAINO_GAME_ID].name && entry.game !==
                          SupportedGamesWithID[gameID.SOLITAIRE_GAME_ID].name && entry.game !==
                          SupportedGamesWithID[gameID.CHAINBLOX_GAME_ID].name) && (
                          <Button
                            variant="outlined"
                            size="small"
                            color="secondary"
                            component={RouterLink}
                            to={`/gameInformationPage/${entry.id}`}
                          >
                            JOIN
                          </Button>
                        )}{' '}
                        {entry.game ==
                          SupportedGamesWithID[gameID.WILD_GAME_ID].name && (
                          <Button
                            variant="outlined"
                            size="small"
                            color="secondary"
                            href={`${WildGameURL}TournamentsInformation?id=${entry.id}`}
                            target="_blank"
                          >
                            JOIN
                          </Button>
                        )}
                        {entry.game ==
                          SupportedGamesWithID[gameID.CHAINO_GAME_ID].name && (
                          <Button
                            variant="outlined"
                            size="small"
                            color="secondary"
                            href={`${ChainoGameURL}TournamentsInformation?id=${entry.id}`}
                            target="_blank"
                          >
                            JOIN
                          </Button>
                        )}
                        {entry.game ==
                          SupportedGamesWithID[gameID.SOLITAIRE_GAME_ID].name && (
                          <Button
                            variant="outlined"
                            size="small"
                            color="secondary"
                            href={`${SolitaireGameUrl}TournamentsInformation?id=${entry.id}`}
                            target="_blank"
                          >
                            JOIN
                          </Button>
                        )}
                        {entry.game ==
                          SupportedGamesWithID[gameID.CHAINBLOX_GAME_ID].name && (
                          <Button
                            variant="outlined"
                            size="small"
                            color="secondary"
                            href={`${ChainbloxGameUrl}TournamentsInformation?id=${entry.id}`}
                            target="_blank"
                          >
                            JOIN
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={filteredEvents.length}
              labelRowsPerPage={'Rows per page'}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </Box>
        </Card>
      ) : (
        <Card className={classes.noEvents}>
          <Box>
            <Typography variant="h5" className={classes.noEventsText}>
              No Upcoming Tournaments
            </Typography>
          </Box>
        </Card>
      )}
    </Container>
  );
};

export default Tournaments;
