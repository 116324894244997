export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_NOTIFICATION_EVENT = 'SET_NOTIFICATION_EVENT';
export const SET_CHALLENGE_TAB = 'SET_CHALLENGE_TAB';
export const DO_NOT_SHOW_WIZARD = 'DO_NOT_SHOW_WIZARD';

// new wallet actions
export const WALLET_CONNECTED = 'WALLET_CONNECTED';
export const WALLET_DISCONNECTED = 'WALLET_DISCONNECTED';
export const ADDRESS_SIGNED_SUCCESS = 'ADDRESS_SIGNED_SUCCESS';
export const ADDRESS_SIGNED_FAILURE = 'ADDRESS_SIGNED_FAILURE';
export const DEPOSIT_AMOUNT_ENTERED = 'DEPOSIT_AMOUNT_ENTERED';
export const WAGERING_AMOUNT_ENTERED = 'WAGERING_AMOUNT_ENTERED';
export const WAGERING_META_TX_FETCHED = 'WAGERING_META_TX_FETCHED';
export const SET_ALLOWANCE_AMOUNT = 'SET_ALLOWANCE_AMOUNT';
export const SET_PROFILE_TAB = 'SET_PROFILE_TAB';
export const WITHDRAW_AMOUNT_ENTERED = 'WITHDRAW_AMOUNT_ENTERED';
export const PROFILE_IMAGE_UPDATE = 'PROFILE_IMAGE_UPDATE';
export const CHAIN_BALANCE_UPDATE = 'CHAIN_BALANCE_UPDATE';
export const MINTABLE_CXP_BALANCE_UPDATE = 'MINTABLE_CXP_BALANCE_UPDATE';
export const CXP_BALANCE_UPDATE = 'CXP_BALANCE_UPDATE';
export const CART_DISABLE = 'CART_DISABLE';
export const CHAIN_UPDATE = 'CHAIN_UPDATE';
export const CHANGE_BALANCE_CHAIN_UPDATE = 'CHANGE_BALANCE_CHAIN_UPDATE';