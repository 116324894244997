import { initializeConnector } from '@web3-react/core';
import { MaticRpcURL, network, PortisId } from '../../config/constants';
import { TorusWallet } from 'web3-react-torus-provider';

export const [torusWallet, hooks] = initializeConnector(
  (actions) =>
    new TorusWallet({
      actions,
      options: {
        chains: [
          {
            host: network.url,
            chainId: network.chainID,
            networkName: network.name
          },
          {
            host: network.maticRPC,
            chainId: network.maticChainId,
            networkName: network.polygonNetworkName
          }
        ],
        initOptions: {
        }
      }
    })
);