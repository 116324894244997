import React, { useState, useEffect, useContext } from 'react';
import {
  ApproveRedirectLink,
  DepositRedirectLink,
  RegisterEthAddressRedirectURL,
  MAX_APPROVED_BALANCE,
  BuyTransakRedirectLink,
  UserAccountSettings
} from '../../config/constants.js';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { AuthContext } from '../../context/AuthContext';
import { DO_NOT_SHOW_WIZARD } from '../../actions/actions.js';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  Button,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Stepper,
  Step,
  StepConnector,
  StepLabel,
  StepContent,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import * as Sentry from '@sentry/react';
import {
  getLinkedNetworkService,
  getMyInfoService
} from '../../service/battleServerService';
import { getBalance } from '../../utils/helpers.js';
import WalletSelector from 'src/components/WalletSelector/WalletSelector.js';
import { start } from 'nprogress';
import { withThemeCreator } from '@mui/styles';

const CustomStepConnector = withStyles((theme) => ({

  vertical: {
    marginLeft: 19,
    paddingBottom: 20,
  },
  line: {
    //borderColor: theme.palette.divider
    display: 'none'
  }
}))(StepConnector);

const useCustomStepIconStyles = makeStyles((theme) => ({
  root: { border: '1px solid ', borderColor: '#9B00F9', width: '55px', height: '55px', backgroundColor: '#141342', color: theme.palette.secondary.contrastText },
  active: {
    backgroundColor: '#00AEFF',
    boxShadow: theme.shadows[10],
    color: theme.palette.secondary.contrastText
  },
  completed: {
    backgroundColor: '#00AEFF',
    color: theme.palette.secondary.contrastText
  },

}));

const CustomStepIcon = ({ active, completed, icon }) => {
  const classes = useCustomStepIconStyles();

  //const Icon = steps[icon - 1].icon;


  return (
    <Avatar
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}
    >
      {/* <Icon size="55" /> */}
      {icon}
    </Avatar>
  );
};

CustomStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.number.isRequired
};


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  resetContainer: {
    padding: theme.spacing(3)
  },
  connectWallet: {
    display: 'flex',
  },
  connectWalletMargin: {
    marginTop: '8px',
  },
  DialogMain: {
    '&.MuiDialog-root .MuiPaper-root': {
      //width: '100%',
      maxWidth: '703px',
      height: 'auto',
      paddingTop: '10px',
      paddingBottom: '20px',
      paddingLeft: '10px',
      paddingRight: '10px',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '1px',
      },
     
    }
  },
  dialogtitle: {
    fontSize: '18px',
    fontWeight: '600',
    textTransform: 'upperCase',
    fontSize:'26px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      display:'flex',
      justifyContent:'flex-start',
      textAlign: 'start'
    },
  },
  DialogButton: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      fontSize:'12px'
    },
  },
  Steppercss:{
      '&.MuiPaper-root':{
        border:'none'
      },
      [theme.breakpoints.down('sm')]: {
        '&.MuiStepper-root':{
          paddingLeft:'0px'
        }
      },
  },
  StepConnectorcss:{
    '&.MuiStepContent-root':{
      border:'none',
      paddingLeft:'56px'
    }
  },
  fontStyle: {
    fontSize: '16px',
    fontWeight: '600',
    [theme.breakpoints.down('sm')]: {
      fontSize:'12px'
    },
  },
  fontStyleK2D: {
    fontFamily:'K2D',
    fontSize: '16px',
    fontWeight: '600',
    [theme.breakpoints.down('sm')]: {
      fontSize:'12px'
    },
  }
}));


export default function Wizard({
  currentStep,
  showWizardModal,
  setShowWizardModal,
}) {
  const { user, dispatch } = useContext(AuthContext);
  const username = user.user?.session?.username;
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(currentStep);
  const steps = getSteps();
  const location = useLocation();
  const [polygonNetworkBalance, setPolygonNetworkBalance] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  function getSteps() {
    return [
      'Link Web3 Wallet',
      'Approve CHAIN for Esports Events',
      'CHAIN Tokens',
      'Polygon Network',
      'Deposit CHAIN',
      'Buy Tokens',
      'Link Gaming Network'
    ];
  }
  
  function getStepContent(step) {
    switch (step) {
      case 0:
        return `Please click the button below to link your wallet address`;
      case 1:
        return 'Please click the Approve button to approve CHAIN token to use for esports events.';
      case 2:
        return `Do you have CHAIN tokens yet?`;
      case 3:
        return `Are they on Polygon?`;
      case 4:
        return `Please click the button below to deposit CHAIN tokens into your balance.  If you don’t want to deposit any tokens at this time, click the next button at the bottom.`;
      case 5:
        return `You have less than 100 CHAIN tokens. You can buy more tokens to play with below.`;
      case 6:
        return `Please click the button below to link your gaming network accounts to Chain Games.  This allows us to track your progress in supported games.`;
      default:
        return 'Unknown step';
    }
  }
  
  function getStepButtonText(step) {
    switch (step) {
      case 0:
        return 'LINK';
      case 1:
        return 'APPROVE';
      case 2:
        return 'YES';
      case 3:
        return 'YES';      
      case 4:
        return 'DEPOSIT';
      case 5:
        return 'Buy Tokens';
      case 6:
        return 'LINK';  
      default:
        return 'Unknown step';
    }
  }
  
  function getStepButtonLink(step, Token, Polygon) {
    switch (step) {
      case 0:
        return RegisterEthAddressRedirectURL;
      case 1:
        return ApproveRedirectLink;
      case 2:
        if(Token == 1) setActiveStep(3);
        else if(Token == 2)setActiveStep(5);
        break;
      case 3:
        if(Polygon == 1 && polygonNetworkBalance < 100)setActiveStep(5);
        else if(Polygon == 1 && polygonNetworkBalance >= 100)setActiveStep(6);
        else if(Polygon == 2)setActiveStep(4);
        break;
      case 4:
        return DepositRedirectLink;
      case 5:
        return BuyTransakRedirectLink;
      case 6:
        return UserAccountSettings;
      default:
        return 'Unknown step';
    }
  }
  
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  // Step 0/3
  const getUserInfo = async () => {
    try {
      const { data } = await getMyInfoService({});
      if (data.success === true) {
        if (data.ethAddress) {
          handleNext();
        } else {
          setShowWizardModal(true);
        }
      }
    } catch (error) {
      console.log(
        '🚀 ~ file: initialStepWizard.js ~ line 95 ~ getUserInfo ~ error',
        error
      );
      Sentry.captureException(error, {
        tags: {
          page: location.pathname
        }
      });
    }
  };

  useEffect(() => {
    if (activeStep === 0) getUserInfo();
  }, [activeStep]);

  // Step 1/3
  const getApprovedBalance = async () => {
    try {
      const { approvedBalance } = await getBalance(
        user.user?.session?.ethAddress
      );
      if (approvedBalance >= MAX_APPROVED_BALANCE) {
        handleNext();
      } else {
        setShowWizardModal(true);
      }
    } catch (error) {
      console.log(
        '🚀 ~ file: initialStepWizard.js ~ line 123 ~ getApprovedBalance ~ error',
        error
      );
      Sentry.captureException(error, {
        tags: {
          page: location.pathname
        }
      });
    }
  };

  useEffect(() => {
    if (user.user?.session?.ethAddress && activeStep === 1) {
      getApprovedBalance();
    }
  }, [user.user?.session?.ethAddress, activeStep]);

  // Step 2/3
  const getNetworkBalance = async () => {
    try {
      const { networkBalance } = await getBalance(
        user.user?.session?.ethAddress
      );
      setPolygonNetworkBalance(networkBalance);
      if (networkBalance >= 100) {
        setActiveStep(6);
      } else {
        setShowWizardModal(true);
      }
    } catch (error) {
      console.log(
        '🚀 ~ file: initialStepWizard.js ~ line 143 ~ getNetworkBalance ~ error',
        error
      );
      Sentry.captureException(error, {
        tags: {
          page: location.pathname
        }
      });
    }
  };

  useEffect(() => {
    if (user.user?.session?.ethAddress && (activeStep === 3 || activeStep === 4)) {
      getNetworkBalance();
    }
  }, [user.user?.session?.ethAddress, activeStep]);

  // Step 3/3
  const getLinkedNetworks = async () => {
    try {
      const { data } = await getLinkedNetworkService({ username });
      if (data.success === true) {
        if (data.linkedNetworks?.length > 0) {
          setShowWizardModal(false);
        } else {
          setShowWizardModal(true);
        }
      }
    } catch (error) {
      console.log(
        '🚀 ~ file: initialStepWizard.js ~ line 172 ~ getLinkedNetworks ~ error',
        error
      );
      Sentry.captureException(error, {
        tags: {
          page: location.pathname
        }
      });
    }
  };

  useEffect(() => {
    if (username && activeStep === 6) {
      getLinkedNetworks();
    }
  }, [username, activeStep]);

  const handleClose = () => {
    setShowWizardModal(false);
  };
  
  return (
    <Dialog
      open={showWizardModal}
      className={classes.DialogMain}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <Typography  className={classes.dialogtitle}>
          In order to get started with Chain Games, please follow these steps.
        </Typography>
      </DialogTitle>
      <DialogContent >
        <div className={classes.root}>
          <Stepper activeStep={activeStep} orientation="vertical" 
            connector={<CustomStepConnector />}
             className={classes.Steppercss}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel StepIconComponent={CustomStepIcon}>
                <Typography className={classes.fontStyle} style={{paddingLeft:'8px'}}>
                          {label}
                    </Typography>
                </StepLabel>
                <StepContent className={classes.StepConnectorcss}>
                  <Typography className={classes.fontStyleK2D}>{getStepContent(index)}</Typography>
                  <div className={classes.actionsContainer}>
                    <div className={classes.connectWallet}>
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={classes.DialogButton}
                        variant="contained"
                        color="secondary"
                      >
                        Back
                      </Button>
                      {index == 0 ? (
                        <div className={classes.connectWalletMargin}>
                          <WalletSelector variant="contained" />
                        </div>
                      ) : (
                        <>
                          {activeStep !== 2 && activeStep !== 3 && (
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={(e) => {
                                e.preventDefault();
                                if (activeStep !== 2 || activeStep !== 3) {
                                  let haveToken;
                                  let inPolygon;
                                  openInNewWindow(
                                    getStepButtonLink(
                                      index,
                                      haveToken,
                                      inPolygon
                                    )
                                  );
                                }
                              }}
                              className={classes.DialogButton}
                            >
                              {getStepButtonText(index)}
                            </Button>
                          )}

                          {activeStep == 2 && activeStep !== 3 && (
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={(e) => {
                                e.preventDefault();
                                if (activeStep == 2) {
                                  let haveToken = 1;
                                  let inPolygon;
                                  getStepButtonLink(
                                    index,
                                    haveToken,
                                    inPolygon
                                  );
                                }
                              }}
                              className={classes.DialogButton}
                            >
                              {getStepButtonText(index)}
                            </Button>
                          )}

                          {activeStep == 3 && activeStep !== 2 && (
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={(e) => {
                                e.preventDefault();
                                if (activeStep == 3) {
                                  let haveToken;
                                  let inPolygon = 1;
                                  getStepButtonLink(
                                    index,
                                    haveToken,
                                    inPolygon
                                  );
                                }
                              }}
                              className={classes.DialogButton}
                            >
                              {getStepButtonText(index)}
                            </Button>
                          )}
                          {activeStep == 2 && (
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={(e) => {
                                e.preventDefault();
                                let haveToken = 2;
                                let inPolygon;
                                getStepButtonLink(index, haveToken, inPolygon);
                              }}
                              className={classes.DialogButton}
                            >
                              NO
                            </Button>
                          )}
                          {activeStep == 3 && (
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={(e) => {
                                e.preventDefault();
                                let haveToken;
                                let inPolygon = 2;
                                  getStepButtonLink(index, haveToken, inPolygon)
                              }}
                              className={classes.DialogButton}
                            >
                              NO
                            </Button>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </StepContent>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length && (
            <Paper square elevation={0} className={classes.resetContainer}>
              <Typography>
                You&apos;re now ready to participate in Chain Games events
              </Typography>
              <Button onClick={handleReset} className={classes.DialogButton}>
                Reset
              </Button>
            </Paper>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (activeStep === steps.length - 1) {
              setShowWizardModal(false);
            } else {
              handleNext();
            }
          }}
          className={classes.DialogButton}
        >
          {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
        </Button>
        <Button
          className={classes.DialogButton}
          color="secondary"
          variant="contained"
          onClick={() => {
            setShowWizardModal(false);
            dispatch({
              type: DO_NOT_SHOW_WIZARD
            });
          }}
        >
          Do Not Ask Again
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function openInNewWindow(url) {
  window.open(url, '_self');
}
