import { SUPPORTED_WALLETS } from '../components/WalletSelector/WalletSelector';
import { useWeb3React } from '@web3-react/core';

export default function useConnectedWalletName() {
  const { connector } = useWeb3React();
  const name = Object.keys(SUPPORTED_WALLETS)
    .filter(
      (k) =>
        SUPPORTED_WALLETS[k].connector === connector
    )
    .map((k) => SUPPORTED_WALLETS[k].name)[0];

  return name;
}
