import {BaseApiURLCS, CookieName} from './constants';
import Cookies from "js-cookie";
import * as Sentry from "@sentry/react";
import ax from 'axios';

export const nodeAxios = ax.create({
  baseURL: `${BaseApiURLCS}`,
  headers: {
    common: {
      'Content-Type': 'application/json',
    },
  },
  timeout: 50000,
});

export const formDataAxiosService = ax.create({
  baseURL: `${BaseApiURLCS}`,
  headers: {
    'content-type': 'multipart/form-data'
  },
  withCredentials: true,
  timeout: 50000,
});

nodeAxios.interceptors.response.use(
  res => res,
  err => {
    if (err?.response?.status === 401) {
      console.log("config->err->here", err)
      logout();
    }
    else if (err?.response?.status === 500) {
      Sentry.captureException(err, {
        tags: {
            page: "500",
            occuredAt: 'got 500 from server in an api call'
        },
    });
    }
    throw err;
  }
);

formDataAxiosService.interceptors.response.use(
  res => res,
  err => {
    if (err?.response?.status === 401) {
      console.log("config->err->here", err)
      logout();
    }
    else if (err?.response?.status === 500) {
      Sentry.captureException(err, {
        tags: {
          page: "500",
          occuredAt: 'got 500 from server in an api call'
        },
      });
    }
    throw err;
  }
);

const initialState = {
  user: {
    isLoggedIn: false,
  },
};

const logout = () =>{
  localStorage.setItem("user", JSON.stringify(initialState));
  Cookies.remove(CookieName);
  // const redirect = window.location.pathname;
  //  window.location.replace(`/login?redirect=${redirect}`);
}