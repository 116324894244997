import React, { useEffect } from 'react';
import NProgress from 'nprogress';
import { THEMES } from 'src/constants';
import { Box, LinearProgress } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    // backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    minHeight: '100%',
    padding: theme.spacing(3)
  },
  dark: {
    alignItems: 'center',
    // backgroundColor: theme.palette.background.dark,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    minHeight: '100%',
    padding: theme.spacing(3)
  }
}));

const LoadingScreen = ({ width = 200, darkMode = false }) => {
  const classes = useStyles();

  useEffect(() => {
    NProgress.start();
    
    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <div className={darkMode ? classes.dark : classes.root}>
      <Box width={width}>
        <LinearProgress />
      </Box>
    </div>
  );
};

export default LoadingScreen;
