import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Button,
  Toolbar,
  IconButton,
  Grid,
  Menu,
  MenuList,
  ListItemText,
  Paper,
  MenuItem,
  Typography,
  SvgIcon,
  useMediaQuery,
  useTheme,
  Drawer,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import leftnav from '../../../../src/assets/img/leftnav.svg';
import rightnav from '../../../../src/assets/img/rightnav.svg';
import quickswap from '../../../../src/assets/img/quickswap.svg';
import { getChainvalue } from 'src/service/battleServerService';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { ChevronDown as ArrowIcon, Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { AuthContext } from '../../../context/AuthContext';
import { useLocation, useHistory } from 'react-router-dom';
import { SET_USER_INFO } from '../../../actions/actions.js';
import { SessionID } from '../../../reducer/reducer.js';
import { PublicVapidKey, QuickswapLink, ChainBloxLink, network } from '../../../config/constants';
import * as Sentry from '@sentry/react';
import { checkIsPrivatePath, checkIsPublicPath } from '../../../utils/helpers';
import {
  getMyInfoService,
  sendSubscriptionOfServiceWorkerService
} from '../../../service/battleServerService';
import Notifications from './Notifications';
import SearchBox from './SearchBox';
import WalletSelector from '../../../components/WalletSelector/WalletSelector';
import BuyTokens from '../../../components/BuyTokens/BuyTokens';
import Ellipse from '../../../../src/assets/img/Ellipse12.svg';
import { useWeb3React } from '@web3-react/core';
import { ReactComponent as GroupTestnetTokensLogo } from '../../../../src/assets/img/GroupTestnetTokens.svg';
import Chat from '../../../../src/views/chat/index';
import NavBar  from '../NavBar';
import Cart from 'src/views/cart';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#141342',
    flexGrow: 1,
    width: '100%',
  },
  rootSmall: {
    backgroundColor: '#141342',
    flexGrow: 1,
    width: '100%'

  },
  logocss:{
    height:35,
    paddingLeft:'12px',
    [theme.breakpoints.down('sm')]:{
      paddingLeft:'12px'
    },
    '@media (max-width: 322px)': {
       width:'164px',
       height:'30px'
    },
  },
  tokenBox: {
    width: '347px',
    height: '43px',
    background: '#141342',
    paddingTop: '12px',
    display: 'flex',
    justifyContent: "space-evenly"
  },
  toolbar: {
    height: 64,
    display: 'flex',
    minHeight: '87px',

    flex: 1,
    justifyContent: 'center',
    borderBlockEnd: '1px solid #9B00F9',
  },
  toolbarSmall: {
    height: 64,
    display: 'flex',
    minHeight: '81px',

    flex: 1,
    justifyContent: 'center',
    borderBlockEnd: '1px solid #9B00F9;',
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  textChain: {
    fontFamily: 'K2D',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '11px'
  },
  text1Chain: {
    fontFamily: 'K2D',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '11px'
  },
  text2Chain: {
    fontFamily: 'K2D',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '10px',
    color: '#FF5A5A'

  },
  text3Chain: {
    fontFamily: 'K2D',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '13px',
    color: 'rgb(22, 206, 185)'
  },
  navicon: {
    marginLeft: '20px'
  },
  popOverMenu: {
    borderLeft: '1px solid #9B00F9',
    marginTop: '81.4px',
    width: '346px',
    paddingBlockEnd: '0'
  },
  navBox: {
    fontFamily: 'Orbitron',
    fontSize: '12px',
    border: '1px solid #9B00F9',
    width: '239px', height: '45px',
    backgroundColor: 'rgba(63, 61, 122, 0.24)',
    alignItems:'center',
  },
  copyButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontFamily: 'Orbitron',
    fontSize: '12px',
    fontWeight: '800',
    height: '52px'
  },
  menuiconcss:{
    '&.MuiSvgIcon-root':{
      fontSize:'2.1rem'
    }
  },
}));

const TopBar = ({ className, parentCallbackOnNavOpen ,...rest }) => {
  const { user, dispatch } = useContext(AuthContext);
  const classes = useStyles();
  const anchorRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(SessionID === 'true');
  const [userName, setUserName] = useState(user.user?.session?.username);
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('lg'));
  // dropdowns
  const [anchorElShooter, setAnchorElShooter] = useState(null);
  const openShooter = Boolean(anchorElShooter);
  const [anchorElSports, setAnchorElSports] = useState(null);
  const openSports = Boolean(anchorElSports);
  const [anchorElCasual, setAnchorElCasual] = useState(null);
  const openCasual = Boolean(anchorElCasual);
  const [anchorElNFT, setAnchorElNFT] = useState(null);
  const openNFT = Boolean(anchorElNFT);
  const [anchorElMobileNFT, setAnchorElMobileNFT] = useState(null);
  const openMobileNFT = Boolean(anchorElMobileNFT);
  const [anchorElBuyTokens, setAnchorElBuyTokens] = useState(null);
  const openBuyTokens = Boolean(anchorElBuyTokens);
  const { chainId, account } = useWeb3React();
  const [chainvalue, setChainvalue] = useState([]);
  const [chainvaluediff, setChainvaluediff] = useState([]);
  const appBarLargeScreen = useMediaQuery('(max-width:1755px)');
  const [isOpen, setIsOpen] = useState(false);
  const drawerRef = useRef(null);
  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const handleOutsideClick = (event) => {
    if (drawerRef.current && !drawerRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  // dropdown shooter
  const handleMenuShooter = (event) => {
    setAnchorElShooter(event.currentTarget);
  };

  const handleCloseShooter = () => {
    setAnchorElShooter(null);
  };
  // dropdown sports
  const handleMenuSports = (event) => {
    setAnchorElSports(event.currentTarget);
  };

  const handleCloseSports = () => {
    setAnchorElSports(null);
  };
  // dropdown casual
  const handleMenuCasual = (event) => {
    setAnchorElCasual(event.currentTarget);
  };

  const handleCloseCasual = () => {
    setAnchorElCasual(null);
  };
  // dropdown NFT
  const handleMenuNFT = (event) => {
    setAnchorElNFT(event.currentTarget);
  };

  const handleCloseNFT = () => {
    setAnchorElNFT(null);
  };

  // dropdown mobile
  const handleMenuMobileNFT = (event) => {
    setAnchorElMobileNFT(event.currentTarget);
  };

  const handleCloseMobileNFT = (event) => {
    setAnchorElMobileNFT(null);
  }

  // nav bar
  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuBuyTokens = (event) => {
    setAnchorElBuyTokens(event.currentTarget);
  };

  const getChainTokenValue = async () => {
    const { data } = await getChainvalue();
    if (data) {
      setChainvalue(data["chain-games"]?.usd);
      setChainvaluediff((data["chain-games"]?.usd_24h_change - data["chain-games"]?.usd));
    }
  };

  useEffect(() => {
    setUserName(user.user?.session?.username);
  }, [user.user]);

  const setLoggedIn = useCallback(() => {
    setIsLoggedIn(false);
  }, [location.pathname]);

  function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  async function registerSW() {
    let subscription = '';

    try {
      const register = await navigator.serviceWorker.register(
        '/notificationSW.js'
      );

      const tempSubscription = await register.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(PublicVapidKey)
      });

      subscription = JSON.stringify(tempSubscription);
    } catch (error) {
      console.log('🚀 ~ file: index.jsx ~ line 57 ~ registerSW ~ error', error);
    } finally {
      const { data } = await sendSubscriptionOfServiceWorkerService({
        subscription
      });
    }
  }

  useEffect(() => {
    if (isLoggedIn && 'serviceWorker' in navigator && 'PushManager' in window) {
      registerSW();
    }
  }, [isLoggedIn]);

  const getUserInfo = useCallback(async () => {
    try {
      const { data } = await getMyInfoService({});
      if (data.success === true) {
        dispatch({
          type: SET_USER_INFO,
          payload: {
            ...data
          }
        });
      }
    } catch (error) {
      console.log('🚀 ~ file: index.js ~ line 96 ~ getUserInfo ~ error', error);
      Sentry.captureException(error, {
        tags: {
          page: location.pathname
        }
      });
    }
  }, [location.pathname]);

  useEffect(() => {
    if (checkIsPublicPath(location.pathname)) {
      if (user.user.isLoggedIn) {
        setLoggedIn();
        getUserInfo();
      }
    } else if (checkIsPrivatePath(location.pathname)) {
      getUserInfo();
    }
  }, [location.pathname]);

  useEffect(() => {
    getChainTokenValue();
  }, [])

  return (
    <AppBar className={
      appBarLargeScreen ? classes.rootSmall : classes.root
    } color="default" {...rest} >
      <Toolbar className={appBarLargeScreen ? classes.toolbarSmall : classes.toolbar}>
        {appBarLargeScreen && <><RouterLink to="/dashboard">
            <Logo className={classes.logocss} />

          </RouterLink>
          <Box ml={2} flexGrow={1} />
          <Box display="flex">

            <Box display="flex" alignItems='center'>
              {user.user?.isLoggedIn ? (
                <>
                  <Chat roomType={1} typeId={1} />
                  <Notifications />
                  <div ref={drawerRef}>
                    <Button className={classes.root} onClick={toggleDrawer} >
                      <SvgIcon className={classes.menuiconcss}>
                        <MenuIcon />
                      </SvgIcon>
                    </Button>
                    <NavBar onOpen={isOpen} toggleDrawer={toggleDrawer} />
                  </div>
                </>
              ) : (
                <>
                  <div style={{ borderColor: '#9B00F9' }}>
                    <Button
                      aria-controls="menu-NFT"
                      aria-haspopup="true"
                      onClick={handleMenuMobileNFT}
                      color='info'
                    >
                      <SvgIcon className={classes.menuiconcss}>
                        <MenuIcon className={classes.menuiconcss}/>
                      </SvgIcon>
                    </Button>
                    <Drawer
                      style={{ width: '100%' }}
                      anchor="right"
                      id="menu-NFT"
                      anchorEl={anchorElMobileNFT}
                      getContentAnchorEl={null}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                      keepMounted
                      open={openMobileNFT}
                      onClose={handleCloseMobileNFT}
                      classes={{ paper: classes.popOverMenu }}
                    >
                      <MenuItem style={{ fontFamily: 'Orbitron', fontSize: '16px', borderBlockEnd: '1px solid #9B00F9', width: '347px', height: '41px' }}>
                        <Grid container direction="row" xs={12} alignItems="center" justifyContent='flex-start' display='flex' spacing={0.5} >
                          {account && <><Box style={{ borderRadius: '30px', backgroundColor: '#9B00F9', width: '94px', height: '25px', display: 'flex', alignItems: 'center', marginRight: '20px' }} >
                            <> <img src={Ellipse} /><Typography style={{ fontSize: '10px' }}>{window.location.href.includes(`https://battle.chaingames.io`) ? 'MAINNET' : 'TESTNET'}</Typography></>
                          </Box>
                            <WalletSelector widthInPixel={'310px'}/></>
                          }
                          {!account && <><Grid item xs={11} justifyContent='flex-start' display='flex'><WalletSelector widthInPixel={'310px'}/></Grid>
                          {/* <Grid item xs={1}>
                            <NavigateNextIcon />
                          </Grid> */}
                          </>}
                        </Grid>
                      </MenuItem>

                      <MenuItem style={{ width: '347px', height: '60px' }}>
                        <Typography variant='h4' >
                          WELCOME TO THE <br></br>
                          LEADING WEB3 GAMING PLATFORM
                        </Typography>

                      </MenuItem>
                      <MenuItem>
                        <Typography variant='h4' style={{ color: '#9B00F9' }} >
                          LOGIN OR REGISTER TO PLAY
                        </Typography>
                      </MenuItem>
                      <MenuItem style={{ background: '#9B00F9', width: '348px', height: '45px', marginTop: '24px' }} onClick={() => {
                        history.push('/login');
                        handleCloseMobileNFT();
                      }}>
                        <Grid container direction="row" alignItems="center" xs={12} >
                          <Grid item xs={11} style={{ fontFamily: 'Orbitron', fontSize: '14px', fontWeight: '600' }}>
                            LOGIN
                          </Grid>
                          <Grid item xs={1}>
                            <NavigateNextIcon />
                          </Grid>
                        </Grid>
                      </MenuItem>

                      <MenuItem style={{ background: '#00AEFF', marginTop: '4px', width: '348px', height: '45px' }} onClick={() => {
                        history.push('/register');
                        handleCloseMobileNFT();
                      }}>

                        {/* <Button
                        fullWidth={true}
                                                     
                          // size='medium'                            
                        
                         variant="contained"
                         onClick={() => {
                           history.push('/register');
                         }}
                       >
                       REGISTER
                       </Button> */}

                        <Grid container direction="row" alignItems="center" xs={12} >
                          <Grid item xs={11} style={{ fontFamily: 'Orbitron', fontSize: '14px', color: '#141342', fontWeight: '500' }}>
                            REGISTER
                          </Grid>
                          <Grid item xs={1}>
                            <NavigateNextIcon />
                          </Grid>
                        </Grid>

                      </MenuItem>
                      <MenuItem style={{ MozMarginEnd: '12px', paddingTop: '12px', fontFamily: 'Orbitron', height: '75px', width: '348px', borderBlockEnd: '1px solid #9B00F9', fontSize: '14px' }}>

                        JOIN OVER 40,000 PLAYERS

                      </MenuItem>
                    
                      <Box display="flex" flexDirection='row' style={{ border: 'solid #9B00F9', borderWidth: '1px 0px 1px 0px' }}>
                        <Box
                          className={classes.tokenBox}
                        >
                          <Box style={{ display: "flex" }}>
                            <Typography variant="title" color="inherit">
                              <img src={leftnav}></img> <img src={rightnav} style={{ marginLeft: '-4px' }}></img>
                            </Typography>
                            <Typography variant="title" color="inherit" >
                              &nbsp;
                            </Typography>
                            <Typography variant="body2" color="inherit" noWrap className={classes.textChain}>
                              $CHAIN Token Price
                            </Typography>
                          </Box>
                          <Box>
                            <Typography className={classes.text1Chain}>
                              ${chainvalue} USD
                            </Typography>
                          </Box>
                          <Box>
                            {chainvaluediff >= 0 ? (
                              <Typography className={classes.text3Chain}>
                                +{chainvaluediff.toString().length > 3 ? (chainvaluediff).toString().substring(0, 4) : chainvaluediff}%
                              </Typography>
                            ) :
                              (
                                <Typography className={classes.text2Chain}>
                                  {chainvaluediff.toString().length > 3 ? (chainvaluediff).toString().substring(0, 4) : chainvaluediff}%
                                </Typography>
                              )
                            }
                          </Box>
                        </Box>
                      </Box>
                      <MenuItem
                        style={{ background: 'linear-gradient(90.96deg, #00AEFF 22.94%, #9B00F9 95.85%)', height: '51px' }}
                        component="a"
                        href='https://chaingames.io/exchanges'
                      >
                        <ListItemText><Box style={{ fontSize: '14px', fontWeight: '800' }}>BUY CHAIN</Box></ListItemText>
                        <Typography color="text.secondary">
                          <NavigateNextIcon />
                        </Typography>
                      </MenuItem>
                      <Box borderBottom='1px solid rgba(79, 174, 247, 0.21)'>
                      <MenuItem
                        className={classes.copyButton}
                        aria-controls="menu-NFT"
                        aria-haspopup="true"
                        onClick={handleMenuNFT}
                      >
                        <Typography style={{ fontFamily: 'Orbitron', fontSize: '12px', fontWeight: '800' }} >
                          IN-GAME ITEMS
                        </Typography>
                        <Typography  >
                          <ShoppingCartOutlinedIcon />
                        </Typography>
                      </MenuItem>
                      </Box>
                      <Menu
                        id="menu-NFT"
                        anchorEl={anchorElNFT}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                        keepMounted
                        open={openNFT}
                        onClose={handleCloseNFT}
                      >
                        <MenuItem
                          onClick={handleCloseNFT}
                          color="#fff"
                          component={RouterLink}
                          to="/store"
                        >
                         Marketplace
                        </MenuItem>
                        <MenuItem
                          onClick={handleCloseNFT}
                          color="#fff"
                          component={RouterLink}
                          to="/communitynft-store"
                        >
                          Community NFT Store
                        </MenuItem>
                        <MenuItem
                          onClick={handleCloseNFT}
                          color="#fff"
                          component={RouterLink}
                          to="/myassets"
                        >
                         My Items
                        </MenuItem>
                      </Menu>
                      <Box style={{ width: '3%' }}></Box>
                      {window.location.href.includes(`https://battle.testnet.chaingames.io`) && <MenuItem
                        className={classes.copyButton}
                        onClick={() => {
                          history.push('/chaingamesFaucet');
                        }}
                      >
                        <Typography style={{ fontFamily: 'Orbitron', fontSize: '12px', paddingLeft: '14px', fontWeight: '800' }} >
                          GET TESTNET TOKENS
                        </Typography>
                        <Typography>
                          <GroupTestnetTokensLogo />
                        </Typography>
                      </MenuItem>
                      }
                    </Drawer>
                  </div>
                </>
              )}
            </Box>
          </Box>
</>}
        {!appBarLargeScreen && <>
          <Grid container className={classes.root} item xs={12}>

            <Box style={{ width: '347px', display:'flex',alignItems:'center'}}></Box>
        <Box sx={{display:'flex',alignItems:'center'}}>
            <Box className={classes.navBox}>

              <MenuItem >

                <Typography style={{ width: '220px' }}>

                  <WalletSelector widthInPixel={'205px'}/>

                </Typography>

                {/* {!account && <NavigateNextIcon />} */}
              </MenuItem>


            </Box>
            </Box>
            <Box style={{ width: '3%' }}></Box>
            <Box sx={{display:'flex',alignItems:'center'}}>
            <MenuItem
              className={classes.navBox}
              aria-controls="menu-NFT"
              aria-haspopup="true"
              onClick={handleMenuNFT}
            >
              <Typography style={{ width: '250px', fontFamily: 'Orbitron', fontSize: '12px' }} >
              IN-GAME ITEMS
              </Typography>
              <Typography  >
                <ShoppingCartOutlinedIcon />
              </Typography>
            </MenuItem></Box>
            <Menu
              id="menu-NFT"
              anchorEl={anchorElNFT}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              keepMounted
              open={openNFT}
              onClose={handleCloseNFT}
            >
              <MenuItem
                onClick={handleCloseNFT}
                color="#fff"
                component={RouterLink}
                to="/store"
              >
                Marketplace 
              </MenuItem>
              <MenuItem
                onClick={handleCloseNFT}
                color="#fff"
                component={RouterLink}
                to="/communitynft-store"
              >
                Community NFT Store
              </MenuItem>
              <MenuItem
                onClick={handleCloseNFT}
                color="#fff"
                component={RouterLink}
                to="/myassets"
              >
                My Items
              </MenuItem>
            </Menu>
            {window.location.href.includes(`https://battle.testnet.chaingames.io`) && <Box sx={{ display: 'flex', alignItems: 'center', paddingLeft: '54px' }}>
              <MenuItem
                className={classes.navBox}
                onClick={() => {
                  history.push('/chaingamesFaucet');
                }}
              >
                <Typography style={{ width: '250px', fontFamily: 'Orbitron', fontSize: '12px' }} >
                  GET TESTNET TOKENS
                </Typography>
                <Typography>
                  <GroupTestnetTokensLogo />
                </Typography>
              </MenuItem>
            </Box>
            }
            <Box sx={{display:'flex',alignItems:'center', paddingLeft:'54px'}}>
            <SearchBox/>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
           
              <Box sx={{ display: { xs: 'none', md: 'flex' } }} style={{ alignItems: 'center' }}>
                   
                <Box style={{ borderRadius: '30px', backgroundColor: '#9B00F9', width: '94px', height: '25px', display: 'flex', alignItems: 'center', marginRight: '20px' }} >
                  <> <img src={Ellipse} /><Typography style={{ fontSize: '10px' }}>{window.location.href.includes(`https://battle.chaingames.io`) ? 'MAINNET' : 'TESTNET'}</Typography></>
                </Box>
                {user.user?.isLoggedIn ? (
                <>
                <Chat roomType={1} typeId={1} />
                <Notifications />
                <Cart/>
                </>
                ) : (<></>)}
              </Box>
          
          </Grid>
          </>}
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onNavOpen: () => { }
};

export default TopBar;
