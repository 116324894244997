
import * as React from 'react';
import {
  CssBaseline,
  Box,
  Typography,
  Container,
  Link,
  Tooltip,
  IconButton,
  Grid,
  useMediaQuery,
  useTheme,
  MenuItem,
  Menu,
  Hidden,
  Button,
  MenuList,
  Paper,
  ListItemText,
  List,
  Divider,
  ListItem,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Link as RouterLink } from 'react-router-dom';
import { Collapse } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ListItemButton from '@mui/material/ListItemButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import linfooter from '../../../src/assets/img/linfooter.svg';
import msgfooter from '../../../src/assets/img/msgfooter.svg';
import fourfooter from '../../../src/assets/img/fourfooter.svg';
//import disfooter from '../../../src/assets/img/disfooter.svg';
import youfooter from '../../../src/assets/img/youfooter.svg';
import gamefooter from '../../../src/assets/img/gamefooter.svg';
import twiterfooter from '../../../src/assets/img/twiterfooter.svg';
import facebookfooter from '../../../src/assets/img/facebookfooter.svg';
import telifooter from '../../../src/assets/img/telifooter.svg';
import Logo from 'src/components/Logo';
import { Style } from '@mui/icons-material';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary">
      Copyright © {new Date().getFullYear()}, &nbsp;
      <Link
        variant="subtitle1"
        color="secondary"
        href="https://chaingames.io/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Chain Games
      </Link>
      {' '}OÜ. All rights reserved.{' '}
      <div>
        <img src={linfooter}></img> </div>
    </Typography>
  );
}


const buttonsArray = [

  {
    href: "https://twitter.com/realchaingames",
    icon: twiterfooter,
    message: 'Follow Us',
  },
  {
    href: "https://t.me/chaingames",
    icon: telifooter,
    message: 'Join Us',
  },
  {
    href: 'https://discord.com/invite/wAyMde8',
    icon: gamefooter,
    message: 'Subscribe',
  },
  {
    href: 'https://www.reddit.com/r/chaingames/',
    icon: fourfooter,
    message: 'Subscribe',
  },
  {
    href: 'https://www.twitch.tv/chaingames_io',
    icon: msgfooter,
    message: 'Subscribe',
  },

  {
    href: 'https://www.youtube.com/channel/UC6HPAdSdpzTIn4DGryr315Q',
    icon: youfooter,
    message: 'Subscribe',
  },
  {
    href: 'https://www.facebook.com/realchaingames',
    icon: facebookfooter,
    message: 'Like Us',
  },
  {
    href: 'https://www.linkedin.com/company/chain-games/',
    icon: linfooter,
    message: 'linkedin',
  },
]

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: ' #0B0B26',
    position: 'relative',
    width: '100%'
  },
  mainBox: {
    py: 1,
    px: 1,
    mx: 'auto',
    my: 'auto',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    padding: '30px'
  },
  box1Box: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '88px',
  },
  logoBox: {
    display: 'flex', flexDirection: 'column'
  },
  logoBox1: {
    width: '220px',
    height:35,
    [theme.breakpoints.up('md')]: {
      height:46,
      width: '300px',
    },
    '@media (max-width: 322px)': {
      width:'164px',
      height:'30px'
   },
  },
  componentBox: {
    display: 'flex',
    flexDirection: 'row',
  },
  helpBox: {
    height: '172px',
    fontSize: '14px',
    fontWeight: '700',
    fontFamily: 'K2D',
  },
  disBox: {
    height: '172px',
    fontSize: '14px',
    fontWeight: '700',
    fontFamily: 'K2D',
  },
  chainBox: {
    height: '172px',
    fontSize: '14px',
    fontWeight: '700',
    fontFamily: 'K2D',
  },
  box2Box: {
    height: '12.54px',
    marginTop: '50px',
    fontSize: '10px',
    fontWeight: '400',
    fontFamily: 'K2D',
    position: 'relative',
    color: 'rgba(255, 255, 255, 0.5)',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      marginTop: '20px'
    },
  },
  logotext: {
    fontFamily: 'K2D',
    fontWeight: '700',
    fontSize: '14px',
    fontSize: '14px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '20px'
    },
  },
  menuFont: {
    '& span, & svg': {
      fontFamily: "K2D",
      fontSize: '13px',
      textTransform: 'none',
      fontWeight: '600'
    },
  },
  listPadding: {
    '&.MuiListItem-root': {
      padding: '0px'
    }
  }
}));


export default function Footer() {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('lg'));
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  const handleClick1 = () => {
    setOpen1(!open1);
  };
  const handleClick2 = () => {
    setOpen2(!open2);
  };

  return (
    <Box
      className={classes.root}
    >
      <CssBaseline />
      <Box
        //component="footer"
        className={classes.mainBox}
      >
        <Box paddingTop={mobileDevice ? '10px' : '50px'}>
          <Grid container  >
            <Grid className={classes.logoBox} xs={12} sm={6}>
              <Grid xs={12} sm={12} style={{ marginLeft: '-8px' }}>
                <Box className={classes.logoBox1}>
                  <RouterLink to="/dashboard">
                    <Logo className={classes.logoBox1}/>
                  </RouterLink>
                </Box>
              </Grid>
              <Grid xs={12} sm={9}  >
                <Box className={classes.logotext}>
                  Chain Games is a blockchain-integrated Web3 gaming network that allows for decentralized game of skills contests with real crypto payouts.
                </Box>
              </Grid>
              <Grid
                // justifyContent={mobileDevice ? 'center' : 'end'}
                xs={12}
                sm={10}
                display="flex"
                flexDirection="row">
                <Box display="flex"
                  flexWrap='wrap'
                  ml='-8px'
                  paddingTop='10px'
                  flexDirection="row">
                  {buttonsArray.map(({ href, message, icon }) => {
                    return (
                      <Box key={href} my={'auto'} display='flex' flexDirection='row' >
                        <Tooltip title={message}>
                          <IconButton style={{ padding: '8px' }} href={href} target="_blank" size="large">
                            <img src={icon}></img>
                          </IconButton>

                        </Tooltip>
                      </Box>
                    );
                  })}
                </Box>
              </Grid>
            </Grid>
            <Hidden smDown>
              <Grid className={classes.componentBox} xs={12} sm={6}>
                <Grid xs={12} sm={4} className={classes.helpBox}>
                  <Typography variant='h4' style={{ fontFamily: 'K2D' }}>Help</Typography>
                  <Typography variant="title" color="inherit" noWrap>&nbsp; </Typography>
                  <ListItem disableGutters className={classes.listPadding}><Link href="https://chaingames.io/contact" style={{ fontFamily: 'K2D', color: '#ffffff' }}>Contact Us</Link> </ListItem>
                  <ListItem disableGutters className={classes.listPadding}><Link href="https://chaingames.io/terms-of-use" style={{ fontFamily: 'K2D', color: '#ffffff' }}>Terms of Use</Link> </ListItem>
                  <ListItem disableGutters className={classes.listPadding}><Link href="https://chaingames.io/privacy-policy" style={{ fontFamily: 'K2D', color: '#ffffff' }}>Privacy</Link></ListItem>
                  <ListItem disableGutters className={classes.listPadding}><Link href="https://chaingames.io/licenses" style={{ fontFamily: 'K2D', color: '#ffffff' }}>Licenses</Link></ListItem>
                  <ListItem disableGutters className={classes.listPadding}><Link href="https://chaingames.io/developer" style={{ fontFamily: 'K2D', color: '#ffffff' }}>Developer</Link></ListItem>
                </Grid>
                <Grid xs={12} sm={4} className={classes.disBox}>
                  <Typography variant='h4' style={{ fontFamily: 'K2D' }}>Discover</Typography>
                  <Typography variant="title" color="inherit" noWrap>&nbsp; </Typography>
                  <ListItem disableGutters className={classes.listPadding}><Link href="https://chaingames.io/" style={{ fontFamily: 'K2D', color: '#ffffff' }}>Home</Link> </ListItem>
                  <ListItem disableGutters className={classes.listPadding}><Link href="https://chaingames.io/about" style={{ fontFamily: 'K2D', color: '#ffffff' }}>About</Link> </ListItem>
                  <ListItem disableGutters className={classes.listPadding}><Link href="https://battle.chaingames.io/store" style={{ fontFamily: 'K2D', color: '#ffffff' }}>Store</Link></ListItem>
                  <ListItem disableGutters className={classes.listPadding}><Link href="https://chaingames.io/games" style={{ fontFamily: 'K2D', color: '#ffffff' }}>Games</Link></ListItem>
                  <ListItem disableGutters className={classes.listPadding}><Link href="https://battle.chaingames.io/" style={{ fontFamily: 'K2D', color: '#ffffff' }}>Battle</Link></ListItem>
                </Grid>
                <Grid xs={12} sm={4} className={classes.chainBox}>
                  <Typography variant='h4' style={{ fontFamily: 'K2D' }}>CHAIN Token</Typography>
                  <Typography variant="title" noWrap>&nbsp; </Typography>
                  <ListItem disableGutters className={classes.listPadding}><Link href="https://chaingames.io/exchanges" style={{ fontFamily: 'K2D', color: '#ffffff' }}>Buy $Chain</Link> </ListItem>
                  <ListItem disableGutters className={classes.listPadding}><Link href="https://staking.chaingames.io/" style={{ fontFamily: 'K2D', color: '#ffffff' }}>Stake $Chain</Link> </ListItem>
                </Grid>
              </Grid>
            </Hidden>
            <Hidden only={['sm', 'lg', 'md', 'xl']}>
              <Grid xs={12} >
                <List
                  sx={{ width: '100%', maxWidth: 500 }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                >
                  <ListItemButton onClick={handleClick} disableGutters>
                    <ListItemText primary="Help" className={classes.menuFont} />
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </ListItemButton>
                  <Divider />
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton component="a" href="https://chaingames.io/contact" disableGutters>
                        <ListItemText primary="Contact Us" className={classes.menuFont} />
                      </ListItemButton>
                      <ListItemButton component="a" href="https://chaingames.io/terms-of-use" disableGutters>
                        <ListItemText primary="Terms of Use" className={classes.menuFont} />
                      </ListItemButton>
                      <ListItemButton component="a" href="https://chaingames.io/privacy-policy" disableGutters>
                        <ListItemText primary="Privacy" className={classes.menuFont} />
                      </ListItemButton>
                      <ListItemButton component="a" href="https://chaingames.io/licenses" disableGutters>
                        <ListItemText primary="Licenses" className={classes.menuFont} />
                      </ListItemButton>
                      <ListItemButton component="a" href="https://chaingames.io/developer" disableGutters>
                        <ListItemText primary="Developer" className={classes.menuFont} />
                      </ListItemButton>
                    </List>
                  </Collapse>
                  <ListItemButton onClick={handleClick1} disableGutters>
                    <ListItemText primary="Discover" className={classes.menuFont} />
                    {open1 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </ListItemButton>
                  <Divider />
                  <Collapse in={open1} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton component="a" href="https://chaingames.io/" disableGutters>
                        <ListItemText primary="Home" className={classes.menuFont} />
                      </ListItemButton>
                      <ListItemButton component="a" href="https://chaingames.io/about" disableGutters>
                        <ListItemText primary="About" className={classes.menuFont} />
                      </ListItemButton>
                      <ListItemButton component="a" href="https://battle.chaingames.io/store" disableGutters>
                        <ListItemText primary="Store" className={classes.menuFont} />
                      </ListItemButton>
                      <ListItemButton component="a" href="https://chaingames.io/games" disableGutters>
                        <ListItemText primary="Games" className={classes.menuFont} />
                      </ListItemButton>
                      <ListItemButton component="a" href="https://battle.chaingames.io/" disableGutters>
                        <ListItemText primary="Battle" className={classes.menuFont} />
                      </ListItemButton>
                    </List>
                  </Collapse>
                  <ListItemButton onClick={handleClick2} disableGutters>
                    <ListItemText primary="CHAIN Token" className={classes.menuFont} />
                    {open2 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </ListItemButton>
                  <Divider />
                  <Collapse in={open2} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton component="a" href="https://chaingames.io/exchanges" disableGutters>
                        <ListItemText primary="Buy $Chain" className={classes.menuFont} />
                      </ListItemButton>
                      <ListItemButton component="a" href="https://staking.chaingames.io/" disableGutters>
                        <ListItemText primary="Stake $Chain" className={classes.menuFont} />
                      </ListItemButton>
                    </List>
                  </Collapse>
                </List>

              </Grid>

            </Hidden>
          </Grid>
        </Box>
        <Grid xs={12} sm={9} className={classes.box2Box}>
          Copyright 2023, Chain Games OÜ. All rights reserved.
          <br></br>
          Chain Games is not endorsed by, directly affiliated with,
          maintained or sponsored by Apple Inc, Google, Electronic Arts,
          Activision Blizzard, Take-Two Interactive, Microsoft, Xbox,
          Sony, Playstation, Activision, Infinity Ward, Mediatonic, or
          Epic Games. All content, games titles, trade names and/or trade dress, trademarks, artwork
          and associated imagery are trademarks and/or
          copyright material of their respective owners.
        </Grid>
      </Box>
    </Box>
  );
}