import { initializeConnector } from '@web3-react/core'
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2'
import { network } from '../../config/constants'

export const [walletConnectV2, hooks] = initializeConnector(
  (actions) =>
    new WalletConnectV2({ 
      actions,
      options: {
        projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID,
          rpcMap: {
              1: network.url,
              5: network.url,
              137: network.maticRPC,
              80001: network.maticRPC,
          },
        chains: [1],
        optionalChains:[5,137,80001],
        showQrModal: true,
        methods: ['eth_signTypedData_v4','personal_sign'],
      },
    })
)