import { CoinbaseWallet } from '@web3-react/coinbase-wallet'
import { initializeConnector } from '@web3-react/core'

export const [coinbaseWallet, hooks] = initializeConnector(
  (actions) =>
    new CoinbaseWallet({
      actions,
      options: {
        url: `https://mainnet.infura.io/v3/21c5078e83c447bab95d768a233accb8`,
        appName: 'chaingames',
        methods: ['eth_signTypedData_v4','personal_sign'],
      },
    })
)
