import {
  LOGOUT_REQUEST,
  LOGIN_REQUEST,
  SET_USER_INFO,
  SET_NOTIFICATION_EVENT,
  SET_CHALLENGE_TAB,
  DO_NOT_SHOW_WIZARD,
  // WALLET REDUCERS
  WALLET_CONNECTED, 
  WALLET_DISCONNECTED, 
  ADDRESS_SIGNED_SUCCESS, 
  ADDRESS_SIGNED_FAILURE,
  DEPOSIT_AMOUNT_ENTERED, 
  SET_ALLOWANCE_AMOUNT,
  SET_PROFILE_TAB,
  WITHDRAW_AMOUNT_ENTERED,
  PROFILE_IMAGE_UPDATE,
  CHAIN_BALANCE_UPDATE,
  MINTABLE_CXP_BALANCE_UPDATE,
  CXP_BALANCE_UPDATE,
  CART_DISABLE,
  CHAIN_UPDATE,
  CHANGE_BALANCE_CHAIN_UPDATE
} from '../actions/actions.js';
import Cookies from 'js-cookie';
import { CookieName } from '../config/constants';
import { ContactsOutlined } from '@mui/icons-material';

export let SessionID = Cookies.get(CookieName);

export const AuthReducer = (state, action) => {
  switch (action.type) {
    case LOGOUT_REQUEST:
      Cookies.remove(CookieName);
      SessionID = '';
      return {
        ...state,
        user: {
          isLoggedIn: false
        }
      };
    case LOGIN_REQUEST:
      SessionID = Cookies.get(CookieName);
      return {
        ...state,
        user: {
          ...state.user,
          isLoggedIn: true,
          session: {
            ...action.payload
          }
        }
      };
    case SET_USER_INFO:
      SessionID = Cookies.get(CookieName);
      return {
        ...state,
        user: {
          ...state.user,
          isLoggedIn: true,
          session: {
            ...action.payload
          }
        }
      };
    case SET_CHALLENGE_TAB:
      SessionID = Cookies.get(CookieName);
      return {
        ...state,
        user: {
          ...state.user,
          challenges: {
            ...action.payload
          }
        }
      };
    case SET_NOTIFICATION_EVENT:
      return {
        ...state,
        user: {
          ...state.user,
          eventInfo: {
            ...state.user.eventInfo,
            [action.payload.eventID]: {
              [action.payload.notificationType]: {
                ...action.payload.notificationInfo,
                time: Math.floor(Date.now() / 1000)
              }
            }
          }
        }
      };
    case DO_NOT_SHOW_WIZARD:
      return {
        ...state,
        user: {
          ...state.user,
          doNotShowWizard: true
        }
      };
      // WALLET REDUCERS
      case WALLET_CONNECTED:
        return {
          ...state,
          user: {
            ...state.user,
            connectedWallet: action.payload.wallet,
            isAddressSigned: false
          },
        };
      case WALLET_DISCONNECTED:
        return {
          ...state,
          user: {
            ...state.user,
            connectedWallet: null,
            isAddressSigned: false
          },
        };
      case ADDRESS_SIGNED_SUCCESS:
        return {
          ...state,
          user: {
            ...state.user,
            session: {
              ...state.user.session,
              ethAddress: action.payload.account
            }
          },
        };
      case ADDRESS_SIGNED_FAILURE:
        return {
          ...state,
          user: {
            ...state.user,
            isAddressSigned: false,
            signature: null
          },
        };
      case DEPOSIT_AMOUNT_ENTERED:
          return {
            ...state,
            user: {
              ...state.user,
              deposit : {
                amount: action.payload.amount
              }
            },
          };
      case SET_ALLOWANCE_AMOUNT:
        return {
          ...state,
          user: {
            ...state.user,
            allowance : {
              ...action.payload
            }
          },
        };
      case SET_PROFILE_TAB:
        return {
          ...state,
          user: {
            ...state.user,
            profile : {
              ...action.payload
            }
          },
        }; 
      case WITHDRAW_AMOUNT_ENTERED:
          return {
            ...state,
            user: {
              ...state.user,
              withdraw : {
                amount: action.payload.amount
              }
            },
          }; 
      case PROFILE_IMAGE_UPDATE:
          return {
            ...state,
            user: {
              ...state.user,
              session: {
                ...state.user.session,
              dpHigh: action.payload.profileDPHigh,
              dpLow: action.payload.profileDPLow,
              }
            },
          };
      case CHAIN_BALANCE_UPDATE:
        return {
          ...state,
          user: {
            ...state.user,
            chainBalance: action.payload.chainBalance
          },
        };
      case MINTABLE_CXP_BALANCE_UPDATE:
        return {
          ...state,
          user: {
            ...state.user,
            mintablecxpBalance: action.payload.mintablecxpBalance
          },
        };
      case CXP_BALANCE_UPDATE:
        return {
          ...state,
          user: {
            ...state.user,
            cxpBalance: action.payload.cxpBalance
          },
        };
        case CART_DISABLE:
          return {
            ...state,
            user: {
              ...state.user,
              cartdisable: action.payload.cartdisable,
              totalQuantity:action.payload.totalQuantity,
              cartstatusdata: action.payload.cartstatusdata,
              ProgressBarStart:action.payload.ProgressBarStart                         
            },
          };
        case CHAIN_UPDATE:
          return {
            ...state,
            user: {
              ...state.user,
              chainID: action.payload.chainID,
              networkDetails : action.payload.networkDetails
            },
          };  
        case CHANGE_BALANCE_CHAIN_UPDATE:
          return {
            ...state,
            user: {
              ...state.user,
              chainIDForBalanceUpdate: action.payload.chainIDForBalanceUpdate,
            },
          };                      
    default:
      return state;
  }
};
