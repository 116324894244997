// import  { Web3Storage } from 'web3.storage'

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK'
};


export const isVideoUrl = (url) =>
  [".webm", ".mp4", ".m4v", ".ogg", ".ogv"].some(ext => url?.toLowerCase().endsWith(ext))

export const isAudioUrl = (url) =>
  [".mp3", ".wav", ".oga"].some(ext => url?.toLowerCase().endsWith(ext))

export const isWebGlUrl = (url) =>
  [".gltf", ".glb"].some(ext => url?.toLowerCase().endsWith(ext))

export const isImageUrl = (url) =>
  isAnimatableImage(url) || url.endsWith(".jpg") || url.endsWith(".jpeg")

export const isAnimatableImage = (url) =>
  url.endsWith(".gif") || url.endsWith(".svg") || url.endsWith(".png")

export const mediaTypeFromFile = (file) => {
  return file.type.startsWith("video/")
    ? "video"
    : file.type.startsWith("audio/")
      ? "audio"
      : file.type.startsWith("model/")
        ? "webgl"
        : file.type.startsWith("image/")
          ? "image"
          : mediaTypeFromUrl(file.name)
}

export const mediaTypeFromUrl = (url) => {
  return isVideoUrl(url)
    ? "video"
    : isAudioUrl(url)
      ? "audio"
      : isWebGlUrl(url)
        ? "webgl"
        : isImageUrl(url)
          ? "image"
          : undefined
}

export const getFileSize = (file) => {
  return file ? (file.size / 1024 / 1024).toFixed(2) : undefined // MB
}

export const MaxFileSize = 100 // MB

export const getWeb3StorageLink = (cid, name) => {
  return `https://${cid}.ipfs.dweb.link/${name}`
}


// *****  IPFS config
export const IPFS_CLIENT_URL = "https://ipfs.infura.io:5001/api/v0"

export const IPFS_URL = "https://chaingames.infura-ipfs.io/ipfs"


export const NFT_RARITY = ["COMMON", "UNCOMMON", "RARE", "EPIC", "LEGENDARY"]

export const PRICE_USD = 1000000


export const NFT_NAME_MAX_CHAR = 128
export const NFT_DESC_MAX_CHAR = 2048
export const NFT_QTY_MAX = 7
export const NFT_PROPERTY_MAX_CHAR = 128
export const NFT_PROPERTY_LENGTH = 15
export const DEFAULT_TRANSACTION_APPROVE =  0xfffffffffffffff
const projectId = '2DFNPBGDwGSjnyJGntYyNNiS0SR';   // <---------- your Infura Project ID
const projectSecret = '1146ef1a8d88dea0f79dbde20c76c2aa';  // <---------- your Infura Secret
// (for security concerns, consider saving these values in .env files)
export const auth = 'Basic ' + Buffer.from(projectId + ':' + projectSecret).toString('base64');
export const NEWS_DESC_MAX_CHAR = 512