import Carousel from '../../../components/Carousel/Carousel';

import {
  Card,
  CardMedia,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
  Box,
  Grid,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { SuperCryptoKartLink, ChainOPageLink, ChainBloxLink, ChainbloxGameUrl, ChainoGameURL, WildGameURL, SolitaireGameUrl, DropziGameUrl, GoobersGameUrl } from '../../../config/constants';
import CHAINO_Image from '../../../assets/img/chaino-splash.jpg';
import { autofill } from 'redux-form';
import { getCharByLength } from 'src/utils/helpers';

const items = [
  {
    id: 0,
    Name: 'Dropzi',
    devloper: 'CHAIN GAMES',
    category: 'PUZZLE',
    //description: 'Race against the world in this blockchain-enabled kart racing game.',
    Image: '/static/images/Dropzi.jpg',
    link: DropziGameUrl,
    openInNewTab: true
  },
  {
    id: 1,
    Name: 'ChainBlox',
    devloper: 'CHAIN GAMES',
    category: 'PUZZLE',
    // description: 'A block-based puzzle strategy game that lets you win real crypto! Clear as many blocks as you can!',
    Image: '/static/images/chainblox.jpg',
    link: ChainbloxGameUrl,
    openInNewTab: true
  },
  {
    id: 2,
    Name: 'Chaino',
    devloper: 'CHAIN GAMES',
    category: 'CARD GAME',
    // description: 'Reverse, +2 Draw, Wild, Skip! The popular party game has arrived on the blockchain.',
    Image: '/static/images/chaino.jpg',
    link: ChainoGameURL,
    openInNewTab: true
  },
  {
    id: 3,
    Name: 'Super Crypto Kart',
    devloper: 'CHAIN GAMES',
    category: 'RACING',
    //description: 'Race against the world in this blockchain-enabled kart racing game.',
    Image: '/static/images/supercryptokart.jpg',
    link: SuperCryptoKartLink,
    openInNewTab: true
  },  
  {
    id: 4,
    Name: 'Solitaire Plus',
    devloper: 'CHAIN GAMES',
    category: 'CARD GAME',
    //description: 'The classic card game is here on the blockchain! A game of concentration and skill.',
    Image: '/static/images/solitaire.jpg',
    link: SolitaireGameUrl,
    openInNewTab: true
  },
  {
    id: 5,
    Name: 'Goobers Battle Arena',
    devloper: 'CHAIN GAMES',
    category: 'PARTY ROYALE',
    //description: 'Race against the world in this blockchain-enabled kart racing game.',
    Image: '/static/images/goobers.jpg',
    link: GoobersGameUrl,
    openInNewTab: true
  },
  {
    id: 6,
    Name: '21Wild',
    devloper: 'CHAIN GAMES',
    category: 'CARD GAME',
    // description: '21 Wild is a fast paced combination of Blackjack and Solitaire.',
    Image: '/static/images/21wild.jpg',
    link: WildGameURL,
    openInNewTab: true
  },
  {
    id: '#',
    Name: '',
    devloper: '',
    category: '',
    //description: 'Race against the world in this blockchain-enabled kart racing game.',
    Image: '',
    link: '',
    openInNewTab: false
  },
];

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    borderRadius: 30,
    height: 'auto',
    display:'flex',
    flexWrap: 'wrap',
    // borderRadius: 30,
    // padding: '17px',
    // margin: '12px',
    // backgroundColor: '#0B0B26',
    // boxShadow: "0px 0px 4px 4px rgba(155, 0, 249, 0.3)"

  },
  image: {
    height: 200,
    borderRadius: 30,

  },
  media: {
    height: '188px',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
    position: 'relative',
    transition: '300ms',
    borderRadis: 30,
    cursor: 'pointer',
    '&:hover': {
      filter: 'brightness(115%)'
    }
  },
  mediaCaption: {
    textOverflow: 'ellipsis',
    bottom: 0,
    borderradius: 30,
    paddingTop: '17px',
    paddingLeft: '10px',
    backgroundColor: '#0b0b26',
    color: 'white',
    opacity: 1,
    fontFamily: 'K2D',
    fontSize: 20,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      paddingTop: '5px',
      paddingLeft: '5px',
    },
    width: '100%',
    transition: '300ms',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8
    },
    textTransform:'none'
  },
  banner1: {
    borderRadius: 30,
    padding: '17px',
    margin: '15px',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    fontSize:'22px',
    fontFamily: 'Orbitron',
    textShadow: '0px 5px 10px #9B00F9',
    fontWeight: 600,
    letterSpacing: '0.78px',
    backgroundImage: 'radial-gradient(circle at 0 0,rgba(146,36,250,.65),rgba(255,255,255,0) 32%,rgba(188,227,255,0) 77%,rgba(37,165,254,.59))',
    [theme.breakpoints.down('sm')]: {
      margin: '9px',
      padding:'7px',
      height:'223px'
    },
    backgroundColor: '#0B0B26',
    boxShadow: "0px 0px 4px 4px rgba(155, 0, 249, 0.3)",
    height:'394px',
    '@media(max-width:460px)': {
      fontSize:'13px',
    },
  },
  banner: {
    borderRadius: 30,
    padding: '17px',
    margin: '15px',
    [theme.breakpoints.down('sm')]: {
      margin: '9px',
      padding:'7px',
      height:'223px'
    },
    backgroundColor: '#0B0B26',
    boxShadow: "0px 0px 4px 4px rgba(155, 0, 249, 0.3)",
    height:'394px'
  },
  colortextBox: {
    fontFamily: 'Orbitron',
    textTransform: 'uppercase',
    fontWeight: '700',
    fontSize: '10px',
    color: '#00AEFF',
    [theme.breakpoints.down('sm')]: {
      fontSize: '8px',
    },
  },
  normaltextBox: {
    fontFamily: 'Orbitron',
    textTransform: 'uppercase',
    fontWeight: '800',
    fontSize: '12px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '8px',
    },
  },
  playButton: {
    width: '100%',
    marginBottom: 6,
    marginTop: 20, 
    borderRadius: 30,
    background: '#9B00F9',
    fontWeight: '800',
    fontFamily: 'K2D',
    [theme.breakpoints.down('sm')]: {
      marginTop: 4, 
      marginBottom: 0,
      fontSize:'13px'
    },
  },
  imageHeight:{
    width: '100%', 
    height: '192px', 
    borderRadius: '30px',
    [theme.breakpoints.down('sm')]: {
      height: '107px', 
    },
  },
  descriptionPadding:{
    fontSize: 14, 
    padding: 8,
    [theme.breakpoints.down('sm')]: {
      padding: '3px',
    },
  }
}));

const Banner = () => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const laptopDevice = useMediaQuery('(max-width:1350px) and (min-width:960px)');
  const mediumDevice = useMediaQuery('(max-width:710px)');
  const mobileImageWidth = useMediaQuery('(max-width:380px)');

  return (

    <>
      {/* <Card className={classes.card}> */}
        {items.map((item) => {
          return (
            <Grid item xs={6} md={3} lg={3} sm={6}>
            <Box mt={mobileDevice? '10px':'30px'}>
            {item.id !='#' ?
            <Link href={item.link} key={item.id} style={{textDecoration:'none'}}>
              <Box className={classes.banner} style={{height: mobileImageWidth ? '250px': ''}}>
                <img src={item.Image} alt="Logo" className={classes.imageHeight} />
                <CardMedia
                  className={classes.media}
                  title={item.Name}
                >
                  <Typography className={classes.mediaCaption}>
                    <span style={{ fontFamily: 'Orbitron', fontWeight: '800' }}>{mediumDevice? mobileImageWidth ? getCharByLength(item.Name, 6) : getCharByLength(item.Name, 9) :laptopDevice? getCharByLength(item.Name, 9): getCharByLength(item.Name, 20)}</span><br></br>
                    <p className={classes.descriptionPadding}
                    ></p>
                    <Box display="flex" justifyContent='space-between' style={{flexDirection: mobileImageWidth? 'column': ''}}>
                      <Box display='flex' flexDirection='column' >
                        <Box className={classes.colortextBox}>  
                          CATEGORY:
                        </Box>
                        <Box className={classes.normaltextBox}>
                          {item.category}
                        </Box>
                      </Box>
                      <Box display='flex' flexDirection="column">
                        <Box className={classes.colortextBox}>
                          DEVLOPER:
                        </Box>
                        <Box className={classes.normaltextBox}>
                          {item.devloper}
                        </Box>
                      </Box>
                    </Box>
                    <Button
                      className={classes.playButton}
                      size="medium"
                      aria-controls="menu-buytokens"
                      aria-haspopup="true"
                      onClick={item.Link}
                      borderradius="5"
                      BackgroundColor="#fff"
                      color="info"
                    >
                      {item.id == 5 ? mobileImageWidth ? 'UPCOMING' : 'COMING SOON': 'PLAY NOW'}
                    </Button>
                  </Typography>
                </CardMedia>
              </Box>
            </Link>:
             <Link style={{textDecoration:'none',color:'#9B00F9'}} >
             <Box className={classes.banner1} style={{height: mobileImageWidth ? '250px': ''}}>
                MORE&nbsp;GAMES <br></br>COMING&nbsp;SOON 
              </Box>
              </Link>
            }
            </Box>
            </Grid>
          );
        })}
      {/* </Card> */}
    </>
  );
};

export default Banner;
