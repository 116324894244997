import React, { useContext, useEffect, useState } from 'react';
import { NavLink as RouterLink, useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Notification from './EthAddressNotLinkedNotification';
import {
  Box,
  Button,
  Card,
  CardMedia,
  Container,
  Grid,
  Typography,
  Paper,
  useTheme,
  useMediaQuery,
  MenuItem,
  Menu,
  Hidden,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import GamesCarousel from './Carousel';
import CasualGamesCarousel from './CasualGamesCarousel';
import NftsCarousel from './NftCarousel';
import { Carousel } from 'react-responsive-carousel';
import { AuthContext } from '../../../context/AuthContext';
import { getBalanceFromCS } from '../../../service/centralServerService';
import { getEventsService, getLinkedNetworkService } from '../../../service/battleServerService';
import { getTournamentsService } from '../../../service/tournaments.service';
import * as Sentry from '@sentry/react';
import {
  getTimeFromEpoch,
  getDateFromEpoch,
  getGameFormatFromIndex,
  calculateTotalPrizePool,
  getDuration,
  formatInCHAIN
} from '../../../utils/helpers';
import { MAX_APPROVED_BALANCE } from '../../../config/constants';
import Wizard from '../../initialStepsWizard/index';
import FIFA_Image from '../../../assets/img/fifa21.jpg';
import NBA_Image from '../../../assets/img/nba.jpg';
import COD_Image from '../../../assets/img/cod.jpg';
import chaintokenright from '../../../assets/img/chaintokenright.svg';
import chaintokenrightMobile from '../../../assets/img/chaintokenrightMobile.svg';
import MADDEN_Image from '../../../assets/img/madden.png';
import HALO_Image from '../../../assets/img/halo.jpg';
import battlebg2 from '../../../assets/img/battlebg2.svg';
import battlebgMobile from '../../../assets/img/battlebgMobile.svg';
import gamenftsimg from '../../../assets/img/gamenfts.svg';
import { SupportedGamesWithID } from '../../../config/constants';
import Events from './Events';
import Tournaments from './Tournaments';
import {
  AllSupportedGamesNames
} from '../../../config/constants';
import LeaderBoard from './LeaderBoard';
import CHAINO_Image from '../../../assets/img/chaino-splash.jpg';
import readytobattelimg from '../../../assets/img/readytobattle_.svg';
import WILDGAME_Image from '../../../assets/img/21-wild.png';
import SOLITAIRE_Image from '../../../assets/img/solitaireplus-dash.jpg';
import PUBG_Image from '../../../assets/img/pubg-background-image.jpg';
import ChainBlox_Image from '../../../assets/img/chainblox.jpg';
import { AlignCenter } from 'react-feather';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
const font = "'K2D', sans-serif";
import { ReactComponent as Ellipse13 } from '../../../assets/img/Ellipse13.svg';
import Ellipse2 from '../../../assets/img/battlebg2.svg';
import GroupMobile from '../../../assets/img/GroupMobile.svg';
import {
  getMyInfoService
} from '../../../service/node.service';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 10,
    paddingBottom: 59,
    minHeight: '100%',
    [theme.breakpoints.down('lg')]: {
      paddingTop: 0,
      paddingBottom: 60
    },
    margin: 0
  },
  button: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%'
  },
  cardLeft: {
    width: '100%',
    height: 520,
    borderRadius: '30px',
    color: '#fff',
    margin: '0 15px',
    position: 'relative'
  },
  cardLeftsmall: {
    width: '100%',
    height: 368,
    borderRadius: '30px',
    color: '#fff',
    margin: '0 15px',
    position: 'relative'
  },
  cardRight: {
    width: '100%',
    height: 248,
    color: '#fff',
    borderRadius: '30px',
    margin: '0 15px',
    position: 'relative'
  },
  pricePool: {
    position: 'absolute',
    top: 0,
    right: 0,
    fontFamily: 'K2D',

    paddingRight: '15px',
    paddingBottom: '10px',
    color: 'white',
    fontSize: 60,
    '&:before': {
      position: 'absolute',
      bottom: -2,
      right: 0,
      paddingRight: '18px',
      content: '"PRIZE"',
      fontSize: 20
    }
  },
  pricePoolRight: {
    position: 'absolute',
    top: 0,
    right: 0,

    paddingRight: '15px',
    paddingBottom: '10px',
    color: 'white',
    fontFamily: font,
    fontSize: 40,
    '&:before': {
      position: 'absolute',
      bottom: 2,
      right: 0,
      paddingRight: '18px',
      content: '"PRIZE"',
      fontSize: 16
    }
  },
  media: {
    height: '100%'
  },

  tournamentBox: {
    width: '100%'
  },
  paper: {
    paddingTop: 20,
    paddingRight: 35,
    paddingLeft: 21,
    paddingBottom: 20,
  },
  papersmall: {
    paddingTop: 40,
    paddingRight: 50,
    paddingLeft: 21,
    paddingBottom: 40,
    [theme.breakpoints.down('sm')]: {
      paddingRight: '38px',
      paddingLeft: '10px',
    }

  },
  MediaCaption: {
    position: 'absolute',
    bottom: 0,

    paddingLeft: '15px',
    paddingBottom: '78px',
    paddingTop: '10px',

    backgroundColor: 'black',
    color: 'white',
    opacity: 0.8,

    width: '100%',
    height: '30%'
  },
  MediaCaptionLeft: {
    position: 'absolute',
    bottom: 0,

    paddingLeft: '30px',
    paddingBottom: '10px',
    paddingTop: '36px',

    backgroundColor: 'black',
    color: 'white',
    opacity: 0.8,

    width: '100%',
    height: '20%'
  },
  MediaCaptionLeftMobile: {
    position: 'absolute',
    bottom: 0,

    paddingLeft: '30px',
    paddingBottom: '10px',
    paddingTop: '36px',

    backgroundColor: 'black',
    color: 'white',
    opacity: 0.8,

    width: '100%',
    height: '32%'
  },
  viewButtonRight: {
    position: 'absolute',
    bottom: 10,
    right: 10,
    color: 'white'
  },
  viewButtonLeft: {
    position: 'absolute',
    bottom: 10,
    right: 16
  },
  captionCarousel: {
    position: 'absolute',
    bottom: 10,
    left: 16
  },
  shadowmainBox: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '51px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '40px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around'
    }
  },
  battelBox: {
    display: 'flex',
    marginLeft: '7%',
    alignItems: 'center',
    margintop: '30px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '7%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margintop: '30px'
    },
  },
  mobilebattelBox: {
    marginLeft: '3%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margintop: '30px'
  },

  numBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  featuresBox: {
    display: 'flex',
    paddingTop: 'auto',
    paddingBottom: '22px',
    paddingLeft: '40px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '10px',
      paddingBottom: '10px',
      paddingLeft: '40px',
    },
  },
  mobilefeaturesBox: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '3%',
    margintop: '10px',
    marginBottom: '10px'
  },
  mobilebattelImg: {
    width: '60%',
  },
  leadingHedingimg: {
    width: '70%',
  },
  battelImg: {
    width: '70%',
  },
  blackBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#0B0B26',
    borderRadius: '30px',
    padding: '14px',
    border: '1px solid #9B00F9',
  },
  mobileblackBox: {
    alignItems: 'center',
    backgroundColor: '#0B0B26',
    borderRadius: '25px',
    height: "31px",
    paddingLeft: "5px",
    paddingRight: "5px",
    border: '1px solid #9B00F9'
  },
  nftHeding: {
    height: '87px',
    width: '100%',
    borderTop: '1px solid #9B00F9',
    borderBottom: '1px solid #9B00F9',
    paddingLeft: '3%',
    paddingRight: '3%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  mobilenftHeding: {
    height: '78px',
    width: '100%',
    borderTop: '1px solid #9B00F9',
    borderBottom: '1px solid #9B00F9',
    paddingLeft: '3%',
    paddingRight: '3%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  nftHedingimg: {
    width: '173px',
    height: '43px'
  },
  headingFont:{
    [theme.breakpoints.down('sm')]:{
      fontSize:'18px'
    }
  },
  fontStyle: {
    fontSize: '12px',
    fontWeight: '600',
    [theme.breakpoints.down('sm')]: {
      fontSize: '8px',
      fontWeight: '600'
    },
  },
  fontEvent: {
    fontSize: '16px',
    fontWeight: '600',
    fontFamily: 'K2D',
    textTransform: 'uppercase'
    // [theme.breakpoints.down('xs')]: {
    //   fontSize: '8px',
    //   fontWeight: '600'
    // },
  },
  greenBox: {
    boxShadow: "0px 0px 10px #83FF8F",
    backgroundColor: '#83FF8F',
    borderRadius: '6px',
    width: '17px',
    height: '17px',
  },
  mobilegreenBox: {
    boxShadow: "0px 0px 10px #83FF8F",
    backgroundColor: '#83FF8F',
    borderRadius: '6px',
    width: '11px',
    height: '11px',
  },
  mobiletokennumber: {
    width: "80%"
  },
  tokenright: {
    width: '80%'
  },
  shadowBox: {
    boxShadow: "0px 0px 10px #9B00F9, inset 0px 0px 4px rgba(0, 0, 0, 0.25)",
    backgroundImage: `url(${Ellipse2})`,
    backgroundRepeat: 'no-repeat',
    borderRadius: '30px',
    backgroundColor: "#141342",
    backgroundPosition: "bottom",
    backgroundRepeat: 'repeat-x',
    height: '394px',
    marginLeft: '15px',
    marginRight: '15px',
    marginTop: '30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    [theme.breakpoints.down('md')]: {
      marginBottom: '15px'
    },
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url(${battlebgMobile})`,
      height: '211px',
    },
  },
  navBox: {
    fontFamily: 'Orbitron',
    fontSize: '12px',
    border: '1px solid #9B00F9',
    width: '239px', height: '45px'
    , backgroundColor: 'rgba(63, 61, 122, 0.24)',
    [theme.breakpoints.down('sm')]:{
      fontSize: '12px',
      width: '183px',
      paddingLeft: '8px',
      paddingRight: '8px',
      paddingBottom: '3px',
      paddingTop: '5px',
      minHeight: "35px"
    },
    '@media (max-width: 396px)': {
      width:'130px',
      height:'33px'
   },
  },
 
  shopallFont: {
    width: '250px',
    fontFamily: 'Orbitron',
    fontSize: '12px'
  },
  mobileshopallFont: {
    width: '170px',
    fontFamily: 'Orbitron',
    fontSize: '12px'
  },
  gamesPadding: {
    paddingLeft: '25px',
    paddingRight: '25px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '10px',
      paddingRight: '10px',
    },
  },
  nftPadding: {
    paddingLeft: '25px',
    paddingRight: '25px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
  },
  fontStyleBattle: {
    fontSize: '30px',
    fontWeight: '600',
    textShadow: '0px 5px 10px #9B00F9',
    fontFamily: 'Orbitron',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
      fontWeight: '600',
      alignItems: 'center',
      display: 'flex',
    },
  },
  fontStyleChain: {
    fontSize: '40px',
    fontWeight: '600',
    textShadow: '0px 5px 10px #9B00F9',
    fontFamily: 'Orbitron',
    display: 'flex',
    flexDirection: 'column',
    justifyContent:'center',
    
    [theme.breakpoints.down('sm')]: {
      marginRight:'10em',
      fontSize: '12px',
      fontWeight: '600',
    },
    [theme.breakpoints.up('sm')]: {
      marginRight:'4em',
    },
    [theme.breakpoints.up('md')]: {
      marginRight:'0em',
    },
    [theme.breakpoints.up('lg')]: {
      marginRight:'4.8em',
    },
    [theme.breakpoints.up('xl')]: {
      marginRight:'8em',
    },
  },
  fontStylePaidout: {
    fontSize: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
  },
  buttonBox: {
    backgroundColor: 'black',
    borderBottom: '1px solid #9B00F9',
    height: '63px',
    marginTop: '18px',
    display: 'flex',
    alignItems: 'center',
    padding:'38px',
    [theme.breakpoints.down('sm')]: {
      padding:'20px'
    },
  },
  systemLive: {
    backgroundColor: '#0B0B26',
    borderRadius: '10px',
    paddingLeft: '10px',
    paddingRight: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  battleBox: {
    paddingLeft: '20px',
    paddingRight: '20px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '10px',
      paddingRight: '10px',
    },
  },
  numberBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    // marginLeft: '44px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '20px',
    },
  },
  fontStyletext: {
    textTransform: 'uppercase',
    fontSize: '12px',
    fontWeight: '600'
  }
}));

const WizardEnums = {
  AccountLink: 0,
  Approve: 1,
  CHAINTokens: 2,
  Deposit: 4,
  ConsoleLink: 6
};

const Hero = ({ className, ...rest }) => {
  const classes = useStyles();
  const [events, setEvents] = useState([]);
  const [tournaments, setTournaments] = useState([]);
  const location = useLocation();
  const theme = useTheme();
  const mobileDevice1 = useMediaQuery(theme.breakpoints.down('sm'));
  const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));
  const mediumDevice = useMediaQuery(theme.breakpoints.down('lg'));
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const account = user.user?.session?.ethAddress;
  const [showNotification, setShowNotification] = useState(false);
  const [showWizardModal, setShowWizardModal] = useState(false);
  const [communityEvents, setCommunityEvents] = useState([]);
  const [currentStep, setCurrentStep] = useState(WizardEnums.AccountLink);
  const username = user.user?.session?.username;

  const handleMenuNFT = (event) => {
    setAnchorElNFT(event.currentTarget);
  };
  const [anchorElNFT, setAnchorElNFT] = useState(null);
  const handleCloseNFT = () => {
    setAnchorElNFT(null);
  };
  const openNFT = Boolean(anchorElNFT);
  useEffect(() => {
    if (!user.user.session?.ethAddress) {
      setShowNotification(true);
    } else {
      setShowNotification(false);
    }
  }, [user.user.session]);

  const getInfoFromAPI = async () => {
    if (username) {
      try {
        // const { data } = await getMyInfoService();
        // console.log("🚀 ~ file: index.js:584 ~ getBalanceInfoFromAPI ~ data:", data)
        const balanceInfo = await getBalanceFromCS({});
        const { data } = await getLinkedNetworkService({ username });
        if (balanceInfo.data.success && data.success) {
          const networks = data.linkedNetworks?.length > 0;
          const allowanceFormatInChain = formatInCHAIN(
            balanceInfo.data.data[0].token.allowance
          );
          const networkFormatInChain = formatInCHAIN(
            balanceInfo.data.data[0].token.total
          );
          if (account) {
            if (MAX_APPROVED_BALANCE > allowanceFormatInChain) {
              setCurrentStep(WizardEnums.Approve);
              setShowWizardModal(true);
            } else if (networkFormatInChain < 100) {
              setCurrentStep(WizardEnums.CHAINTokens);
              setShowWizardModal(true);
            } else if (!networks) {
              setCurrentStep(WizardEnums.ConsoleLink);
              setShowWizardModal(true);
            }
          } else {
            setCurrentStep(WizardEnums.AccountLink);
            setShowWizardModal(true);
          }
        }
      } catch (error) {
        console.log(
          '🚀 ~ file: Hero.js ~ line 331 ~ getInfoFromAPI ~ error',
          error
        );
        Sentry.captureException(error, {
          tags: {
            page: location.pathname
          }
        });
      }
    }
  };

  const filterCommunityEvents = () => {
    const allEvents = events.concat(tournaments);
    let eventsList = []
    AllSupportedGamesNames.forEach(gameName => {
      const found = allEvents.find(event => event.game === gameName);
      if (found) {
        eventsList.push(found)
      } else {
        eventsList.push(allEvents[Math.floor(Math.random() * allEvents.length)])
      }
    });
    // get one random event
    eventsList.push(allEvents[Math.floor(Math.random() * allEvents.length)])
    return eventsList.slice(0, 5);
  };

  const getEvents = async () => {
    if (username) {
      try {
        const { data } = await getEventsService({ allGames: true });
        if (data.success === true && data.events?.length > 0) {
          setEvents(
            data.events.map((row) => {
              return {
                ...row,
                date: getDateFromEpoch(row.startTime),
                time: getTimeFromEpoch(row.startTime),
                duration: getDuration(row.startTime, row.endTime),
                gameFormat: getGameFormatFromIndex(row.game, row.gameFormat),
                noOfUsersEnrolled:
                  row.noOfUsersEnrolled > row.maxUsers
                    ? row.maxUsers
                    : row.noOfUsersEnrolled,
                betAmount: row.sponsored
                  ? 'Free'
                  : `$${row.betAmount.toFixed(2)}`,
                prizePool: `$${calculateTotalPrizePool(
                  row.betAmount,
                  row.maxUsers
                )}`,
                isEvent: true
              };
            })
          );
        }
      } catch (error) {
        console.log('🚀 ~ file: Hero.js ~ line 320 ~ getEvents ~ error', error);
        Sentry.captureException(error, {
          tags: {
            page: location.pathname
          }
        });
      }
    }
  };

  const getTournaments = async () => {
    if (username) {
      try {
        const { data } = await getTournamentsService({ allGames: true });
        if (data.success === true && data.events?.length > 0) {
          setTournaments(
            data.events.map((row) => {
              return {
                ...row,
                date: getDateFromEpoch(row.startTime),
                time: getTimeFromEpoch(row.startTime),
                duration: getDuration(row.startTime, row.endTime),
                gameFormat: getGameFormatFromIndex(row.game, row.gameFormat),
                noOfUsersEnrolled:
                  row.noOfUsersEnrolled > row.maxUsers
                    ? row.maxUsers
                    : row.noOfUsersEnrolled,
                betAmount: row.sponsored
                  ? 'Free'
                  : `$${row.betAmount.toFixed(2)}`,
                prizePool: `$${calculateTotalPrizePool(
                  row.betAmount,
                  row.maxUsers
                )}`,
                isEvent: false
              };
            })
          );
        }
      } catch (error) {
        console.log(
          '🚀 ~ file: Hero.js ~ line 357 ~ getTournaments ~ error',
          error
        );
        Sentry.captureException(error, {
          tags: {
            page: location.pathname
          }
        });
      }
    }
  };

  useEffect(() => {
    getEvents();
    getTournaments();
    getInfoFromAPI();
  }, []);

  useEffect(() => {
    if (events.length + tournaments.length > 4) {
      setCommunityEvents(filterCommunityEvents);
    }
  }, [events, tournaments]);

  const getImage = (game) => {
    switch (game) {
      case SupportedGamesWithID[0].name:
        return COD_Image;
      case SupportedGamesWithID[1].name:
        return MADDEN_Image;
      case SupportedGamesWithID[2].name:
        return FIFA_Image;
      case SupportedGamesWithID[3].name:
        return NBA_Image;
      case SupportedGamesWithID[4].name:
        return HALO_Image;
      case SupportedGamesWithID[5].name:
        return PUBG_Image;
      case SupportedGamesWithID[6].name:
        return CHAINO_Image;
      case SupportedGamesWithID[7].name:
        return WILDGAME_Image;
      case SupportedGamesWithID[8].name:
        return SOLITAIRE_Image;
      case SupportedGamesWithID[9].name:
        return ChainBlox_Image;
    }
  };

  return (
    <>
      {user.user.doNotShowWizard !== true && showWizardModal && (
        <Wizard
          showWizardModal={showWizardModal}
          setShowWizardModal={setShowWizardModal}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          WizardEnums={WizardEnums}
        />
      )}
      <div className={clsx(classes.root, className)} {...rest}>
        {!user.user.isLoggedIn &&
          <Hidden only={['lg', 'xl']}>
            <Box className={classes.buttonBox}>
              <Box>
                <Typography className={classes.fontStyletext}>login or register to play</Typography>
              </Box>
              <Box display='flex' ml='12px'>
                <Button
                  variant='contained'
                  style={{ fontSize: '12px' }}
                  onClick={() => {
                    history.push('/login');
                  }}
                  color='primary'>
                  LOGIN
                </Button>
                <Button
                  variant='contained'
                  style={{ fontSize: '12px', marginLeft: '7px' }}
                  onClick={() => {
                    history.push('/register');
                  }}
                  color='secondary'
                >
                  REGISTER
                </Button>
              </Box>
            </Box>
          </Hidden>
        }
        {/* Notification to register Ethereum Address */}
        {(!user.user.isAddressSigned && user.user.isLoggedIn) && showNotification && <Notification />}
        {!mediumDevice && communityEvents.length > 4 && (
          <Box className={classes.paper}>
            <Box className={classes.tournamentBox}>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <Card raised className={classes.cardLeft} spacing={1}>
                    <Typography
                      variant="h6"
                      color="secondary"
                      className={classes.pricePool}
                    >
                      {communityEvents[0].prizePool}
                    </Typography>
                    <CardMedia
                      className={classes.media}
                      image={getImage(communityEvents[0].game)}
                      title={communityEvents[0].game}
                    >
                      <Box className={classes.MediaCaptionLeft}>
                        <Grid container spacing={3}>
                          <Grid item xs={8} >
                            <Box className={classes.captionCarousel}>
                              <Grid item xs={12}>
                                <Typography style={{ fontSize: '20px' }} className={classes.fontEvent}>
                                  {`COMMUNITY ${communityEvents[0].isEvent ? 'EVENT' : 'TOURNAMENT'} `}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography variant="h4" color="textPrimary" className={classes.fontEvent}>
                                  {`${communityEvents[0].date} ${communityEvents[0].time}`}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography variant="h3" color="textPrimary" className={classes.fontEvent}>
                                  {`Entry ${communityEvents[0].betAmount} ${communityEvents[0].gameFormat}`}
                                </Typography>
                              </Grid>
                            </Box>
                          </Grid>
                          <Grid item xs={4}>
                            <Button
                              className={classes.viewButtonLeft}
                              variant="contained"
                              size="large"
                              color="secondary"
                              component={RouterLink}
                              to={`/gameInformationPage/${communityEvents[0].id}`}
                            >
                              {`VIEW ${communityEvents[0].isEvent ? 'EVENT' : 'TOURNAMENT'} `}
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </CardMedia>
                  </Card>
                </Grid>
                {!mediumDevice && (
                  <Grid item container xs={6} spacing={3}>
                    {communityEvents.slice(1, 5).map((tournament, index) => {
                      return (
                        <Grid item xs={6} key={index}>
                          <Card raised className={classes.cardRight}>
                            <Typography
                              variant="h6"
                              color="secondary"
                              className={classes.pricePoolRight}
                              fontFamily={font}
                            >
                              {tournament.prizePool}
                            </Typography>
                            <CardMedia
                              className={classes.media}
                              image={getImage(tournament.game)}
                              title={tournament.game}
                            >
                              <Box className={classes.MediaCaption}>
                                <Grid container spacing={1}>
                                  <Grid item xs={9}>
                                    <Grid item xs={12}>
                                      <Typography
                                        className={classes.fontEvent}
                                      >
                                        {`COMMUNITY ${tournament.isEvent ? 'EVENT' : 'TOURNAMENT'} `}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Typography
                                        variant="body2"
                                        color="textPrimary"
                                        className={classes.fontEvent}
                                      >
                                        {`${tournament.date} ${tournament.time}`}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Typography
                                        variant="body1"
                                        color="textPrimary"
                                        className={classes.fontEvent}
                                      >
                                        {`Entry ${tournament.betAmount} ${tournament.gameFormat}`}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Box>
                              <Button
                                className={classes.viewButtonRight}
                                variant="contained"
                                size="small"
                                color="secondary"
                                component={RouterLink}
                                to={`/gameInformationPage/${tournament.id}`}
                              >
                                VIEW
                              </Button>
                            </CardMedia>
                          </Card>
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
              </Grid>
            </Box>
          </Box>
        )}

        {mediumDevice && (
          <Carousel infiniteLoop showStatus={false}>
            {communityEvents.map((tournament) => {
              return (
                <Box key={tournament.id} className={classes.papersmall}>
                  <Box className={classes.tournamentBox}>
                    <Card
                      raised
                      className={classes.cardLeftsmall}
                      key={tournament.id}
                    >
                      <Typography
                        variant="h6"
                        color="secondary"
                        className={classes.pricePool}
                        fontFamily={font}
                      >
                        {tournament.prizePool}
                      </Typography>
                      <CardMedia
                        className={classes.media}
                        image={getImage(tournament.game)}
                        title={tournament.game}
                      >
                        <Box
                          className={
                            mobileDevice1
                              ? classes.MediaCaptionLeftMobile
                              : classes.MediaCaptionLeft
                          }
                        >
                          <Grid container spacing={3}>
                            <Grid item align="left" xs={12} md={8}>
                              <Box className={classes.captionCarousel}>
                                <Typography style={{ fontSize: mobileDevice1 ? '16px' : '20px' }} className={classes.fontEvent}>
                                  {`COMMUNITY ${tournament.isEvent ? 'EVENT' : 'TOURNAMENT'} `}
                                </Typography>
                                <Typography variant="h4" color="textPrimary" className={classes.fontEvent}>
                                  {`${tournament.date} ${tournament.time}`}
                                </Typography>
                                <Typography variant="h3" color="textPrimary" className={classes.fontEvent}>
                                  {`Entry ${tournament.betAmount} ${tournament.gameFormat}`}
                                </Typography>
                                {mobileDevice1 && (
                                  <Box mt={1}>
                                    <Button
                                      variant="contained"
                                      size="large"
                                      color="secondary"
                                      component={RouterLink}
                                      to={`/gameInformationPage/${tournament.id}`}
                                    >
                                      {`VIEW ${tournament.isEvent ? 'EVENT' : 'TOURNAMENT'} `}
                                    </Button>
                                  </Box>
                                )}
                              </Box>
                            </Grid>
                            {!mobileDevice1 && (
                              <Grid item xs={12} md={4}>
                                <Button
                                  className={classes.viewButtonLeft}
                                  variant="contained"
                                  size="large"
                                  color="secondary"
                                  component={RouterLink}
                                  to={`/gameInformationPage/${tournament.id}`}
                                >
                                  {`VIEW ${tournament.isEvent ? 'EVENT' : 'TOURNAMENT'} `}
                                </Button>
                              </Grid>
                            )}
                          </Grid>
                        </Box>
                      </CardMedia>
                    </Card>
                  </Box>
                </Box>
              );
            })}
          </Carousel>
        )}


        <Grid container className={classes.gamesPadding}>
          <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: events.length + tournaments.length > 4 ? '':mobileDevice? '30px' : '20px' }}>
            {/* <Grid item lg={6} xs={12} sm={12} md={6}>
              <Box
                className={classes.shadowBox}
              >
                <Box style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                  <Typography className={classes.fontStyleBattle}>
                    READY TO BATTLE?
                  </Typography>
                  <Box className={classes.systemLive} >
                    <Typography className={classes.fontStyle}>
                      ALL SYSTEMS LIVE
                    </Typography>
                    <Ellipse13 />
                  </Box>
                </Box>
                <Box className={classes.numberBox}>
                  <Typography className={classes.fontStyleChain}>
                    127,000,000+ <span className={classes.fontStylePaidout}>CHAIN TOKENS PAID OUT</span>
                  </Typography>
                  <img src={mobileDevice ? chaintokenrightMobile: chaintokenright} />
                </Box>
                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                  <Box className={classes.blackBox}>
                    <Typography className={classes.fontStyle}>
                      DECENTRALIZED GAMES OF SKILL
                    </Typography>
                  </Box>
                  <Box className={classes.blackBox}>
                    <Typography className={classes.fontStyle}>
                      WIN REAL CRYPTO
                    </Typography>
                  </Box>
                  <Hidden only={['xs']}>
                    <Box className={classes.blackBox}>
                      <Typography className={classes.fontStyle}>
                        PLAY TO EARN
                      </Typography>
                    </Box>
                  </Hidden>
                </Box>
              </Box>
            </Grid> */}
            <CasualGamesCarousel />

          </div>
        </Grid>

        <Box mt={4} mb={3} className={mobileDevice1 ? classes.mobilenftHeding : classes.nftHeding}>

          <Box Width='45%'>
            <Typography variant='h2' className={classes.headingFont}>
            IN-GAME ITEMS
            </Typography>
          </Box>
          <Box >
            <MenuItem
              className={classes.navBox}
              aria-controls="menu-NFT"
              aria-haspopup="true"
              onClick={handleMenuNFT}
            >
              <Typography className={mobileDevice1 ? classes.mobileshopallFont : classes.shopallFont} >
                SHOP ALL
              </Typography>
              <Typography  >
                <ShoppingCartOutlinedIcon />
              </Typography>
            </MenuItem>
            <Menu
              id="menu-NFT"
              anchorEl={anchorElNFT}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              keepMounted
              open={openNFT}
              onClose={handleCloseNFT}
            >
              <MenuItem
                onClick={handleCloseNFT}
                color="#fff"
                component={RouterLink}
                to="/store"
              >
                Marketplace 
              </MenuItem>
              <MenuItem
                onClick={handleCloseNFT}
                color="#fff"
                component={RouterLink}
                to="/communitynft-store"
              >
                Community NFT Store
              </MenuItem>
              <MenuItem
                onClick={handleCloseNFT}
                color="#fff"
                component={RouterLink}
                to="/myassets"
              >
                My Items
              </MenuItem>
            </Menu>
          </Box>
        </Box>

        <Box className={classes.nftPadding} >

          <NftsCarousel />
        </Box>

        {/* <Events events={events} /> */}
        {/* <Tournaments tournaments={tournaments} /> */}

        <Box mt={4} mb={3} className={mobileDevice1 ? classes.mobilenftHeding : classes.nftHeding}>
            <Typography variant='h2' className={classes.headingFont}>
            Leaderboards
            </Typography>
        </Box>
        <LeaderBoard />
      </div>
    </>
  );
};

Hero.propTypes = {
  className: PropTypes.string
};

export default Hero;
