let networkName = '',
  projectID = process.env.REACT_APP_INFURA_ID,
  chainId = '',
  networkURL = '',
  chainTokenContract = '',
  etherscan = '',
  RootChainManagePredicateProxy = '',
  rootChainProxy = '',
  maticNetwork = '',
  maticVersion = '',
  maticExplorer = '',
  maticExplorerForAddress = '',
  maticRPC = '',
  chainTokenMaticAddress = '',
  cxpTokenMaticAddress = '',
  betManagerContractMaticAddress = '',
  ERC20PredicateProxy = '',
  // nodeApi = '',
  polygonNetworkName = '',
  chainNFTContractAddress = '',
  devices = {
    XBOX_ONE: { id: 2, name: 'XBOX ONE' },
    XBOX_SERIES: { id: 1, name: 'XBOX SERIES X|S' },
    PS4: { id: 3, name: 'PLAYSTATION 4' },
    PS5: { id: 4, name: 'PLAYSTATION 5' }
  },
  basescan = '',
  gameNetworksWithNameAndID = [
    {
      name: 'Xbox Live',
      index: 0,
      devicesSupported: [devices.XBOX_ONE, devices.XBOX_SERIES]
    },
    { name: 'BattleNet', index: 1 },
    {
      name: 'PlayStation (PSN)',
      index: 2,
      devicesSupported: [devices.PS4, devices.PS5]
    },
    { name: 'Activision', index: 3 },
    { name: 'Steam', index: 4 },
  ],
  CasualGames = [
    {
      name: 'Chaino',
      id: 11,
      shortName: 'Chaino',
    },
    {
      name: 'Super Crypto Kart',
      id: 0,
      shortName: 'Super Crypto Kart',
    },
    {
      name: 'Solitaire Plus',
      id: 12,
      shortName: 'Solitaire Plus',
    },
  ],
  captchaSiteKey = '6LdEPt8ZAAAAAJjKZNsmiHFPewM53OowRotwL-HL',
  baseApiURLBS = `http://127.0.0.1:8080/v1/`,
  baseApiURLCS = `http://127.0.0.1:8000/v1/`,
  redirectURL = 'https://battle.testnet.chaingames.io/',
  basePathToImg = 'http://139.59.35.142:8080',
  facebookAppID = '1265368041062827',
  chainoPageLink = 'https://chaino.testnet.chaingames.io/',
  cookieName = 'TCS_ISACTIVE',
  // paypalFeesLink = 'https://www.paypal.com/us/webapps/mpp/merchant-fees#fixed-fees-commercialtrans',
  // paypalClientId = 'AaDiAnrqBpj4lOgqLnNenFKSgNgGs4 KxWP4bRfaDaDNQ71E8DWp6iwBafcP90FXaJWcR8WpaCzPhYSVT',
   maticChainId = 80001,
   portisId = 'c564598b-da1d-470f-81d7-81261b834667',
  transakObject = {
  apiKey: '7a2ff413-802b-45a8-a971-fa0afa80f2ba',  
  environment: 'STAGING',
  redirectURL: '',
  },
  // minimumWithdrawAmount = 1,
  firebaseConfig = {
    apiKey: 'AIzaSyAa7NqUp_vMtl_8MkpDGEwcrxCMAVgrKcE',
    authDomain: 'chat-6c999.firebaseapp.com',
    databaseURL: 'https://chat-6c999-default-rtdb.firebaseio.com/',
    projectId: 'chat-6c999',
    storageBucket: 'chat-6c999.appspot.com',
    messagingSenderId: '74450210365',
    appId: '1:74450210365:web:a1a23a859e12d665f6a454'
  },
  publicVapidKey =
    'BBwedotvAwaP3nu5P4IYoWXs3r_ModlYBdEVZ4CUPoI5jvbW1-5I67N1dPC9PglFxD4JzyTQviwmbvNH9JKJJQU',
  wildGameUrl = '',
  chainoGameUrl = '',
  solitaireGameUrl = '',
  wildGameID = '',
  ChainBloxGameID = '',
  chainbloxGameUrl = '',
  DropziGameID = '',
  dropziGameUrl = '',
  goobersGameUrl = '',
  networkConfig = [],
  networkConfigEthereum = [],
  NetworkChainID = [],
  networkConfigAllChain = [];
const ENV = process.env.REACT_APP_ETH_PROVIDER;
switch (ENV) {
  case 'mainnet':
    networkName = 'mainnet';
    polygonNetworkName = 'mainnet';
    chainId = 1;
    networkURL = `https://mainnet.infura.io/v3/${projectID}`;
    chainTokenContract = '0xC4C2614E694cF534D407Ee49F8E44D125E4681c4';
    etherscan = 'https://etherscan.io/tx/';
    ERC20PredicateProxy = '0x40ec5B33f54e0E8A33A975908C5BA1c14e5BbbDf';
    RootChainManagePredicateProxy =
      '0xA0c68C638235ee32657e8f720a23ceC1bFc77C77';
    chainTokenMaticAddress = '0xd55fCe7CDaB84d84f2EF3F99816D765a2a94a509';
    cxpTokenMaticAddress = '0xDf67c67Dd1D445E6147653656e4c68593E854004';
    betManagerContractMaticAddress =
      '0x16625b9FF0f12952b3a9B048aAba4aC4229B2F9a';
    maticExplorer = 'https://polygonscan.com/tx/';
    maticExplorerForAddress = 'https://polygonscan.com/';
    maticRPC = 'https://polygon-rpc.com/';
    // maticRPC = "https://matic-mainnet.chainstacklabs.com";
    maticVersion = 'v1';
    maticNetwork = 'mainnet';
    rootChainProxy = '0x86E4Dc95c7FBdBf52e33D563BbDB00823894C287';
    chainNFTContractAddress = '0x13BB2910AEfCfa187cd9C7b1d77c387BBa05418d';
    facebookAppID = '6245020478882814';
    redirectURL = 'https://battle.chaingames.io/';
    chainoPageLink = 'https://chaino.chaingames.io/';
    cookieName = 'CS_ISACTIVE';
    baseApiURLBS = `https://api.battle.chaingames.io/v1/`;
    baseApiURLCS = `https://api.wallet.chaingames.io/v1/`;
    publicVapidKey = 'BCVlypU7Q3972qagxdBiO8iF6kZ0A6CldBbKhre1e6wNBJlsTfVk5WU2H6OQMw2nC-xa5IPmoYEDbN4_3cFBpB8';
    captchaSiteKey = '6Lcuge8ZAAAAAOvMI6arX-0GgRPcwsyUBabgK6v8';
    // paypalClientId = '';
    maticChainId = 137;
     portisId = 'c564598b-da1d-470f-81d7-81261b834667';
    transakObject = {
      apiKey: 'c7fa2eb7-09de-419f-a66e-a8716c913a0d',
      environment: 'PRODUCTION',
      redirectURL: '',
    };
    firebaseConfig = {
      databaseURL: 'https://battle-dash-98c0aeurope-west.firebasedatabase.app',
      authDomain: 'battle-dash-98c0a.firebaseapp.com',
      projectId: 'battle-dash-98c0a',
      storageBucket: 'battle-dash-98c0a.appspot.com',
      messagingSenderId: '1050832496508',
      appId: '1:1050832496508:web:fd45eedcbf776d912526f9',
      measurementId: 'G-V3J8BHRTF1'
    };
    wildGameUrl = 'https://21wild.chaingames.io/';
    chainoGameUrl = 'https://chaino.chaingames.io/';
    solitaireGameUrl = 'https://solitaireplus.chaingames.io/'; 
    wildGameID = 13;
    ChainBloxGameID = 14;
    DropziGameID = 15;
    chainbloxGameUrl = 'https://chainblox.chaingames.io/';
    dropziGameUrl = 'https://dropzi.chaingames.io/';
    goobersGameUrl = 'https://chaingames.io/games/goobers-battle-obstacle-arena';
    basescan = 'https://basescan.org/tx/';
    networkConfig = [{
      name: 'Polygon',
      chainId: 137,
      rpcUrls: "https://polygon-rpc.com/",
      nativeCurrency : {
        "name": "MATIC",
        "symbol": "MATIC",
        "decimals": 18
      },
      blockExplorerUrls : [
        "https://polygonscan.com/"
      ],
      iconImage: '/static/images/polygon-matic-logo1.svg'
    },
    {
      name: 'Base',
      chainId: 8453,
      rpcUrls: "https://developer-access-mainnet.base.org",
      nativeCurrency : {
        "name": "Ether (ETH)",
        "symbol": "ETH",
        "decimals": 18
      },
      blockExplorerUrls : [
        "https://basescan.org"
      ],
      iconImage: '/static/images/base.svg'
    }];
    networkConfigEthereum = [{
      name: 'Ethereum',
      iconImage: '/static/images/Ethereum.svg'
    },
    {
      name: 'Polygon',
      iconImage: '/static/images/polygon-matic-logo1.svg'
    }];
    NetworkChainID = [
      {
        name: 'Polygon',
        chainID: 0
      },
      {
        name: 'Supernet Mainnet',
        chainID: 1
      },
     
      // {
      //   name: 'Base',
      //   chainID: 2
      // }
  ];
    networkConfigAllChain = [
      {
        name: 'Supernet Mainnet',
        chainId: 9999,
        rpcUrls: " http://34.100.181.54:10002",
        nativeCurrency : {
          "name": "Chain (Chain)",
          "symbol": "CHAIN",
          "decimals": 18
        },
        blockExplorerUrls : [
          "http://34.100.181.54:4000/"
        ],
        iconImage: '/static/images/supernets-logo.svg',
        depositId: 'Polygon',
        defaultId: 1,
        chainIndex: 2,
      },
      {
        name: 'Polygon',
        chainId: 137,
        rpcUrls: "https://polygon-rpc.com/",
        nativeCurrency : {
          "name": "MATIC",
          "symbol": "MATIC",
          "decimals": 18
        },
        blockExplorerUrls : [
          "https://polygonscan.com/"
        ],
        iconImage: '/static/images/polygon-matic-logo1.svg',
        depositId: 'Ethereum',
        defaultId: 0,
        chainIndex: 0,
      },
    // {
    //   name: 'Base',
    //   chainId: 8453,
    //   rpcUrls: "https://developer-access-mainnet.base.org",
    //   nativeCurrency : {
    //     "name": "Ether (ETH)",
    //     "symbol": "ETH",
    //     "decimals": 18
    //   },
    //   blockExplorerUrls : [
    //     "https://basescan.org"
    //   ],
    //   iconImage: '/static/images/base.svg'
    // },
  ];
    break;

  case 'goerli':
  case 'testnet':
    networkName = 'goerli';
    polygonNetworkName = 'polygon testnet';
    chainId = 5;
    networkURL = `https://goerli.infura.io/v3/${projectID}`;
    chainTokenContract = '0x76EBA9F21d659Fc15D5f33a1a0C7f9088D0FBd28';
    etherscan = 'https://goerli.etherscan.io/tx/';
    ERC20PredicateProxy = '0xdD6596F2029e6233DEFfaCa316e6A95217d4Dc34';
    RootChainManagePredicateProxy =
      '0xBbD7cBFA79faee899Eaf900F13C9065bF03B1A74';
    chainTokenMaticAddress = '0x783288fb03079238dd917794ec16F812eB25B390';
    cxpTokenMaticAddress = '0xE7Db51Cc4cF29BE477FC97eE9931e69c986BEa2C';
    betManagerContractMaticAddress =
      '0x39d3f03Fa9Cdc11DE1fc21b267dBd703b87C596E';
    maticExplorer = 'https://mumbai.polygonscan.com/tx/';
    maticExplorerForAddress = 'https://mumbai.polygonscan.com/';
    maticRPC = 'https://rpc-mumbai.maticvigil.com';
    maticVersion = 'mumbai';
    maticNetwork = 'testnet';
    maticChainId = 80001;
    rootChainProxy = '0x2890bA17EfE978480615e330ecB65333b880928e';
    captchaSiteKey = '6LdEPt8ZAAAAAJjKZNsmiHFPewM53OowRotwL-HL';
    chainNFTContractAddress = "0x4c550c9a577D0D926589aEDff076dfBbd1309A32";
    baseApiURLBS = `https://api.battle.testnet.chaingames.io/v1/`;
    baseApiURLCS = `https://api.wallet.testnet.chaingames.io/v1/`;
    // baseApiURLBS = `http://127.0.0.1:8080/v1/`;
    // baseApiURLCS = `http://127.0.0.1:8000/v1/`;
    // baseApiURLBS = `https://battle.testnet.chaingames.io:8080/v1/`;
    // baseApiURLCS = `https://wallet.testnet.chaingames.io:8000/v1/`;
    //publicVapidKey ='BCU5sE8oaZtY0ybJ5Wmt1eHP6S-4Y_wryla0ykKyjx2FHgNAqG9iOXJVKDnMvCyEb8HNX559K_U34VCmD6uDvMw';
    wildGameUrl = 'https://21wild.testnet.chaingames.io/';
    chainoGameUrl = 'https://chaino.testnet.chaingames.io/';
    solitaireGameUrl = 'https://solitaireplus.testnet.chaingames.io/';
    wildGameID = 18;
    ChainBloxGameID = 20;
    DropziGameID = 24;
    chainbloxGameUrl = 'https://chainblox.testnet.chaingames.io/';
    dropziGameUrl = 'https://dropzi.testnet.chaingames.io/';
    goobersGameUrl = 'https://chaingames.io/games/goobers-battle-obstacle-arena';
    basescan = 'https://goerli.basescan.org/tx/';
    networkConfig = [{
      name: 'Mumbai',
      chainId: 80001,
      rpcUrls: "https://matic-mumbai.chainstacklabs.com",
      nativeCurrency : {
        "name": "MATIC",
        "symbol": "MATIC",
        "decimals": 18
      },
      blockExplorerUrls : [
        "https://mumbai.polygonscan.com"
      ],
      iconImage: '/static/images/polygon-matic-logo1.svg'
    },
    {
      name: 'Base Goerli',
      chainId: 84531,
      rpcUrls: "https://base-goerli.rpc.thirdweb.com",
      nativeCurrency : {
        "name": "Goerli Ether (ETH)",
        "symbol": "ETH",
        "decimals": 18
      },
      blockExplorerUrls : [
        "https://goerli.basescan.org"
      ],
      iconImage: '/static/images/base.svg'
    },
  ];
    networkConfigEthereum = [{
      name: 'Goerli',
      iconImage: '/static/images/Ethereum.svg'
    },
    {
      name: 'Mumbai',
      iconImage: '/static/images/polygon-matic-logo1.svg'
    }];
    NetworkChainID = [
      {
        name: 'Mumbai',
        chainID: 0
      },
      {
        name: 'Supernet Testnet',
        chainID: 1
      },
      // {
      //   name: 'Base Goerli',
      //   chainID: 2
      // }
    ];
    networkConfigAllChain = [
      {
        name: 'Supernet Testnet',
        chainId: 9999,
        rpcUrls: "http://34.100.181.54:10002",
        nativeCurrency : {
          "name": "Chain (Chain)",
          "symbol": "CHAIN",
          "decimals": 18
        },
        blockExplorerUrls : [
          "http://34.100.181.54:4000/"
        ],
        iconImage: '/static/images/supernets-logo.svg',
        depositId: 'Mumbai',
        defaultId: 1,
        chainIndex: 2, 
      },
      {
        name: 'Mumbai',
        chainId: 80001,
        rpcUrls: "https://matic-mumbai.chainstacklabs.com",
        nativeCurrency : {
          "name": "MATIC",
          "symbol": "MATIC",
          "decimals": 18
        },
        blockExplorerUrls : [
          "https://mumbai.polygonscan.com"
        ],
        iconImage: '/static/images/polygon-matic-logo1.svg',
        depositId: 'Goerli',
        defaultId: 0,
        chainIndex: 0,
      },
      // {
      //   name: 'Base Goerli',
      //   chainId: 84531,
      //   rpcUrls: "https://base-goerli.rpc.thirdweb.com",
      //   nativeCurrency : {
      //     "name": "Goerli Ether (ETH)",
      //     "symbol": "ETH",
      //     "decimals": 18
      //   },
      //   blockExplorerUrls : [
      //     "https://goerli.basescan.org"
      //   ],
      //   iconImage: '/static/images/base.svg'
      // },
  ];
    break;
  default:
    console.error('REACT_APP_ETH_PROVIDER env not found')
}
let allSupportedGames = [
  {
    name: 'Call of Duty: Modern Warfare',
    id: 1,
    shortName: 'COD: MW',
    supportedNetworks: [
      gameNetworksWithNameAndID[0],
      gameNetworksWithNameAndID[1],
      gameNetworksWithNameAndID[3],
    ]
  },
  {
    name: 'Madden NFL 21',
    id: 2,
    shortName: 'Madden NFL 21',
    supportedNetworks: [
      gameNetworksWithNameAndID[0],
      gameNetworksWithNameAndID[2]
    ]
  },
  {
    name: 'Fifa',
    id: 3,
    shortName: 'Fifa',
    supportedNetworks: [
      gameNetworksWithNameAndID[0],
      gameNetworksWithNameAndID[2]
    ]
  },
  {
    name: 'NBA 2K21',
    id: 4,
    shortName: 'NBA',
    supportedNetworks: [
      gameNetworksWithNameAndID[0],
      gameNetworksWithNameAndID[2]
    ]
  },
  {
    name: 'Halo: Infinite',
    id: 5,
    shortName: 'Halo: Infinite',
    supportedNetworks: [
      gameNetworksWithNameAndID[0],
    ]
  },
  {
    name: 'PUBG',
    id: 6,
    shortName: 'PUBG',
    supportedNetworks: [
      gameNetworksWithNameAndID[0],
      gameNetworksWithNameAndID[4]
    ]
  },
  {
    name: 'Chaino',
    id: 11,
    shortName: 'Chaino',
    supportedNetworks: [
    ]
  },
  {
    name: '21Wild',
    id: wildGameID,
    shortName: '21Wild',
    supportedNetworks: [
    ]
  },
  {
    name: 'Solitaire',
    id: 12,
    shortName: 'Solitaire',
    supportedNetworks: [
    ]
  },
  {
    name: 'Chainblox',
    id: ChainBloxGameID,
    shortName: 'ChainBlox',
    supportedNetworks: [
    ]
  },
];
export const newGames = [
  {
    name: 'Dropzi',
    id: DropziGameID,
    shortName: 'Dropzi',
    supportedNetworks: [
    ]
  },
];

export const network = {
  name: networkName,
  chainID: chainId,
  url: networkURL,
  maticChainId,
  maticRPC,
  polygonNetworkName
};

export const chainTokenContractAddress = chainTokenContract;
export const EtherScanLink = etherscan;
// export const nodeApiURL = nodeApi;
export const ERC20PredicateProxyAddress = ERC20PredicateProxy;
export const RootChainManageProxyAddress = RootChainManagePredicateProxy;
export const RootChainProxyAddress = rootChainProxy;
export const ChainTokenMaticAddress = chainTokenMaticAddress;
export const CXPTokenMaticAddress = cxpTokenMaticAddress;
export const BetManagerMaticAddress = betManagerContractMaticAddress;
export const ChainNFTContractAddress = chainNFTContractAddress;
export const MaticRpcURL = maticRPC;
export const MaticExplorerLink = maticExplorer;
export const MaticExplorerLinkForAddress = maticExplorerForAddress;
export const MaticNetwork = {
  name: maticNetwork,
  version: maticVersion
};
export const SignUpRedirectURL = redirectURL + 'register';
export const BaseApiURLCS = baseApiURLCS;
export const BaseApiURLBS = baseApiURLBS;
export const BasePathToImg = basePathToImg;
export const RedirectURL = redirectURL;
export const RegisterEthAddressRedirectURL = redirectURL;
export const ForgotPasswordRedirectURL = redirectURL + 'resetPassword';
export const DepositRedirectLink = redirectURL + 'deposit';
export const ApproveRedirectLink = redirectURL + 'approve';
export const WithdrawInitiateRedirectLink = redirectURL + 'withdraw-initiate';
export const CODStatsRedirectLink =
  'https://cod.tracker.gg/modern-warfare/profile/xbl/riftdawg/mp';
export const RecaptchaSiteKey = captchaSiteKey;
// export const CaptchaSiteKey = captchaSiteKey;
export const AllSupportedGamesNames = allSupportedGames.map((row) => row.name);
export const AllGames = [...allSupportedGames, ...CasualGames];
export const AllGameNames = AllGames.map((row) => row.name);
export const AllSupportedGamesWithOtherAttributes = allSupportedGames;
export const PollingDelayInMinutes = 5;
export const ChaingamesFeesCutPercentage = 5;
export const SupportedGameNetworks = gameNetworksWithNameAndID;
export const SupportedGamesWithID = allSupportedGames;
export const MaticChainId = maticChainId
export const CoinGeckoAPIURL =
  'https://api.coingecko.com/api/v3/simple/price?ids=chain-games&vs_currencies=usd';
export const Chainvalue=
  'https://api.coingecko.com/api/v3/simple/price?ids=chain-games&vs_currencies=usd&include_24hr_change=true&precision=4';
export const Styles = [
  { name: '1v1', index: 0 },
  { name: 'Bracket', index: 1 },
  { name: 'Single Game', index: 2 },
  { name: 'Solos', index: 3 },
  { name: 'Duos', index: 4 },
  { name: 'Trios', index: 5 },
  { name: 'Quads', index: 6 },
  { name: "Plunder", index: 7 },
  { name: "Resurgence", index: 8 },
];

export const vgStyles =  [
  { name: 'Single Game', index: 0 },
  { name: '1v1', index: 1 },
  { name: 'Vanguard Royal Solos', index: 2 },
  { name: 'Vanguard Royal Duos', index: 3 },
  { name: 'Vanguard Royal Trios', index: 4 },
  { name: 'Battle Royal Quads', index: 5 },
  { name: 'Bracket', index: 6 },
  { name: 'Public 1v1', index: 7 },
  { name: "Plunder Trios", index: 8 },
  { name: "Vanguard Resurgence Quads", index: 9 },
];

export const gameID = {
  "COD_GAME_ID" : 0,
  "NFL_GAME_ID" : 1,
  "FIFA_GAME_ID" : 2,
  "NBA_GAME_ID" : 3,
  "HALO_GAME_ID" : 4,
  "PUBG_GAME_ID" : 5,
  "CHAINO_GAME_ID": 6,
  "WILD_GAME_ID" : 7,
  "SOLITAIRE_GAME_ID" : 8,
  "CHAINBLOX_GAME_ID" : 9,
}

export const GameFormat = {
  [allSupportedGames[gameID.COD_GAME_ID].name]: [
    { name: 'Warzone - Max Kills', index: 0 },
    { name: 'Warzone - Max Score', index: 1 },
    { name: 'Warzone - Most Headshots', index: 2 }
    // { name: "Warzone - Max K/D", index: 3 },
  ],
  [allSupportedGames[gameID.NFL_GAME_ID].name]: [{ name: 'Max Score', index: 0 }],
  [allSupportedGames[gameID.FIFA_GAME_ID].name]: [{ name: 'Max Score', index: 0 }],
  [allSupportedGames[gameID.NBA_GAME_ID].name]: [{ name: 'Max Score', index: 0 }],
  [allSupportedGames[gameID.HALO_GAME_ID].name]: [
    { name: 'Halo - Max Kills', index: 0 },
    { name: 'Halo - Max Kill to Death Ratio', index: 3 },
    { name: 'Halo - Max Score', index: 1 }
  ],
  [allSupportedGames[gameID.CHAINO_GAME_ID].name]: [{ name: 'Max Score', index: 0 }],
  [allSupportedGames[gameID.WILD_GAME_ID].name]: [{ name: 'Max Score', index: 0 }],
  [allSupportedGames[gameID.SOLITAIRE_GAME_ID].name]: [{ name: 'Max Score', index: 0 }],
  [allSupportedGames[gameID.PUBG_GAME_ID].name]: [
    { name: 'PUBG - Max Kills', index: 0 },
    { name: 'PUBG - Max Score', index: 1 },
    { name: 'PUBG - Max Headshots', index: 2 },
  ],
  [allSupportedGames[gameID.CHAINBLOX_GAME_ID].name]: [{ name: 'Max Score', index: 0 }],
  [newGames[0].name]: [{ name: 'Max Score', index: 0 }]
};
export const passwordRegex =
  /^(?=.*\d)(?=.*[!@#$%^&*()_+=,.\\\/;':"-])(?=.*[a-z])(?=.*[A-Z]).{6,30}$/;
export const activisionRegex = /^(?=.*#\d+)/; 
export const BetAmount = [1, 2.5, 5, 10, 15, 20, 25];
export const MaxWinners = [1, 2, 3, 5, 10];
export const MAX_APPROVED_BALANCE = 1.157920892373162e59;
export const SuperCryptoKartLink = 'https://chaingames.io/games/super-crypto-kart';
export const FacebookAppID = facebookAppID;
export const ChainGamesFBID = '106807154470210';
export const ChainOPageLink = chainoPageLink;
export const CookieName = cookieName;
// export const MinimumWithdrawAmount = minimumWithdrawAmount;
// export const PaypalFeesLink = paypalFeesLink;
export const paypalWithdrawStatus =  [
  {
    id: 0,
    shortMessage: 'Withdraw Initiated',
    longMessage: 'Your request to withdraw has been initiated, please check your registered email address to confirm withdraw. Once it is confirmed you would receive money back in 3 business days'
  },
  {
    id: 1,
    shortMessage: 'Withdraw Confirmation Email Sent',
    longMessage: 'Please check your registered email address, you would have received an email to confirm withdrawal. Once it is confirmed you would receive money back in 3 business days'
  },
  {
    id: 2,
    shortMessage: 'Withdraw email verified',
    longMessage: 'You have verified your withdrawal request. You would receive money back on your paypal email address in 3 business days.'
  },
  {
    id: 3,
    shortMessage: 'Withdraw Completed',
    longMessage: 'Congratulations!! You would have received money in your paypal wallet.'
  },
  {
    id: 4,
    shortMessage: 'Withdraw Cancelled',
    longMessage: 'Your request was cancelled for some reason. Your USD Balance would be updated in 3 business days'
  },
  {
    id: 5,
    shortMessage: 'Withdraw errored',
    longMessage: 'Your request was errored for some reason.'
  }
];
// export const PaypalClientID = paypalClientId;
 export const CovalentApiKey = 'ckey_c41ed062b1994121bcd5af08d06';
 export const PortisId = portisId;
 export const TransakObject = transakObject;

export const NotificationsTypes = [
  { typeID: 1, notificationName: 'Event Starting' },
  { typeID: 2, notificationName: 'Event Bets Placed' },
  { typeID: 3, notificationName: 'Event Ended' },
  { typeID: 4, notificationName: 'Event Result Declared' },
  { typeID: 5, notificationName: 'Challenge Invite Received' },
  { typeID: 6, notificationName: 'Challenge Invite Accepted' }
];
export const SupportedCurrency = [
  {
    currency: 'CHAIN',
    id: 0
  },
  {
    currency: 'USD',
    id: 1
  }
];
export const StatusReceivedFromAPI = {
  RESULT_NOT_SUBMITTED: 1,
  RESULT_SUBMITTED: 2,
  DISPUTE_OCCURED: 3,
  PROOF_SUBMITTED: 4
};
export const Devices = devices;
export const HoursAfterWhichCanSubmitEvidence = 12;
export const MinutesAfterWhichCanSubmitResult = 15;
export const FirebaseConfig = firebaseConfig;
export const PublicVapidKey = publicVapidKey;
export const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'application/pdf',
  'application/zip'
];
export const CODBracketStyles = [
  { name: 'Solos', index: 3 },
  { name: 'Duos', index: 4 },
  { name: 'Trios', index: 5 },
  { name: 'Quads', index: 6 },
  { name: "Plunder", index: 7 },
  { name: "Resurgence", index: 8 },
]
export const AdminRoleID = 128;
export const InfluencerRoleID = 127;

export const haloStyles = [
  { name: '1v1', index: 0 },
  { name: 'Bracket', index: 1 },
  { name: 'Multiplayer', index: 2 }
];
export const haloBracketStyles = [haloStyles.find(({ name })=> name === 'Multiplayer')];

export const casualGameStyles = [
  { name: '1v1', index: 0 },
  { name: 'Bracket', index: 1 },
];
export const RarityCategories = [
  {
    category: 'COMMON',
    id: 0,
    // color: '#EEEEEE',
    color: '#B8B8B8',
  },
  {
    category: 'UNCOMMON',
    id: 1,
    color: "#43A047"
  },
  {
    category: 'RARE',
    id: 2,
    color: "#03A9F4"
  },
  {
    category: 'EPIC',
    id: 3,
    color: "#E11584"
  },
  {
    category: 'LEGENDARY',
    id: 4,
    color: "#FFEB3B"
  }
];

export const SortingOptions = [
  {
    category: 'NEW',
    id: 0,
    categoryName: 'Newest'
  },
  {
    category: 'LOW_TO_HIGH',
    id: 1,
    categoryName: 'Price: Low to High'
  },
  {
    category: 'HIGH_TO_LOW',
    id: 2,
    categoryName: 'Price: High to Low'
  },
  {
    category: 'TOP_SELLERS',
    id: 3,
    categoryName: 'Top Sellers'
  },
];
export const SupportedCurrencyForNFT = [
  {
    currency: 'CHAIN',
    id: 1
  },
  {
    currency: 'CXP',
    id: 2
  }
];
export const timerangeOptions = [
  {
    name: 'Monthly',
    id: 1
  },
  {
    name: 'Weekly',
    id: 2
  },
  {
    name: 'Daily',
    id: 3
  },
]
export const AllSupportedCurrencyForNFT = SupportedCurrencyForNFT.map((row) => row.currency);
export const AllSortingOptions = SortingOptions.map((row) => row.categoryName);
export const AllRarityCategories = RarityCategories.map((row)=>row.category);
export const SocialMedia = [
  {
    type: 'twitter',
    name: 'twitter'
  },
  {
    type: 'fb',
    name: 'facebook'
  },
  {
    type: 'twitch',
    name: 'twitch'  
  },
  {
    type: 'yt',
    name: 'youtube'
  },
];
export const SocialMediaEnums = {
  twitter: 0,
  facebook: 1,
  twitch: 2,
  youtube: 3,
};
export const pubgStyles = [
  { name: '1v1', index: 0 },
  { name: 'Bracket', index: 1 },
  { name: 'Solo', index: 2 },
  { name: 'Duo', index: 3 },
  { name: 'Squad', index: 4 }
];
export const pubgBracketStyles = pubgStyles.slice(2,5).map((row) => row);
export const sportsGameStyles = [
  { name: '1v1', index: 0 },
  { name: 'Bracket', index: 1 },
  { name: 'Public 1v1', index: 2 },
];
export const sportsGameBracketStyles = [sportsGameStyles.find(({ name })=> name === 'Public 1v1')];
export const eligibleReferral = 10;
export const bonusCurrencyId = 2;
export const QuickswapLink = "https://quickswap.exchange/#/swap?outputCurrency=0xd55fCe7CDaB84d84f2EF3F99816D765a2a94a509";
export const BuyTransakRedirectLink = redirectURL + 'buy-chain-transak';
export const UserAccountSettings = redirectURL + 'userAccountSetting';
export const ChainBloxLink = 'https://chainblox.chaingames.io/';
export const WildGameURL = wildGameUrl;
export const ChainoGameURL = chainoGameUrl;
export const SolitaireGameUrl= solitaireGameUrl;
export const ChainbloxGameUrl = chainbloxGameUrl;
export const SuperCryptoCart = {
  name: 'Super Crypto Kart',
  id: 0,
  shortName: 'Super Crypto Kart'
};
export const AllSupportedGamesIncludeSCK = [...allSupportedGames, SuperCryptoCart];
export const MinSubsequntReqMinutes = 0;
export const DefaultAmount = 1000;
export const AddressLength = 56;
export const stakingURL = 'https://staking.chaingames.io/app/staking-stats';
export const DropziGameUrl = dropziGameUrl;
export const GoobersGameUrl = goobersGameUrl;
export const AssetFilters = [
  {name: 'all nfts', shortName: 'All NFTs'},
  {name: 'asset', shortName: 'Asset'},
  {name: 'communityAsset', shortName: 'Community Asset'},
  {name: 'playToEarn', shortName: 'PlayToEarn'}
];
export const TransferNFTQuantity = 1
export const NewGameNames = newGames.map((row) => row.name);
export const networkChainId = NetworkChainID;
export const BaseScanLink = basescan;
export const Network = networkConfig;
export const NetworkEthereum = networkConfigEthereum;
export const NetworkConfigAllChain = networkConfigAllChain