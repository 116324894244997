import React, { useState, useEffect, useContext } from 'react';
import Page from 'src/components/Page';
import { loginService } from '../../service/centralServerService';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import useQueryString from './useQueryString';
import { LOGIN_REQUEST } from '../../actions/actions';
import makeStyles from '@mui/styles/makeStyles';
import LoadingScreen from 'src/components/LoadingScreen';
import { useMediaQuery, useTheme } from '@mui/material';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box
} from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 0,
    paddingTop: 0,
    marginBottom: '800px'
  }
}));

const Redirection = ({ successPath }) => {
  const classes = useStyles();
  const [showProgressPopup, setshowProgressPopup] = useState(false);
  const history = useHistory();
  const { dispatch } = useContext(AuthContext);
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));
  const search = useQueryString();
  const token = search.get('token');

  useEffect(() => {
    async function login() {
      try {
        setshowProgressPopup(true);
        
        let clientMetadata = { IPv4: '' };
        try {
          const publicIp = require('public-ip');
          clientMetadata['IPv4'] = await publicIp.v4();
        } catch (e) {
          console.log('unable to fetch public ip', e);
        }

        const { data } = await loginService({
          otat: token,
          clientMetadata: JSON.stringify(clientMetadata)
        });

        if (data && data.success) {
          dispatch({
            type: LOGIN_REQUEST,
            payload: { ...data }
          });
          setshowProgressPopup(false);
          history.push(successPath);
        } else {
          setshowProgressPopup(false);
          throw new Error('Failure');
        }
      } catch (error) {
        setshowProgressPopup(false);
        console.log(error);
        history.push('/login');
      }
    }
    login();
  }, []);
  return (
    <Page className={classes.root} title="Battle | Chain Games">
      <Container maxWidth="lg">
        <Dialog
          open={showProgressPopup}
          onClose={(e) =>
            e.keyCode != 27
              ? setshowProgressPopup(false)
              : setshowProgressPopup(true)
          }
          maxWidth="sm"
          fullWidth
        >
          <DialogContent>
            <Container maxWidth="lg">
              <Typography
                align="center"
                variant={mobileDevice ? 'h4' : 'h1'}
                color="textPrimary"
              >
                Automatically logging you into Chain Games...
              </Typography>
              <Box mt={4} display="flex" justifyContent="center">
                <LoadingScreen
                  width={mobileDevice ? 200 : 400}
                  darkMode={true}
                />
              </Box>
            </Container>
          </DialogContent>
        </Dialog>
      </Container>
    </Page>
  );
};

export default Redirection;
