import React, { useRef, useState,useContext,useEffect } from 'react';
import { Link as RouterLink, useLocation, useHistory } from 'react-router-dom';
import {
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  Popover,
  Tooltip,
  Typography,
  ListItemAvatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  RemoveFromCart,
  getCartItems,
  UpdateintoCart,
  getCartStatus
} from '../../service/centralServerService';
import { AuthContext } from '../../context/AuthContext';
import { MyCartcontext } from '../../contexts/cartContext';
import { CART_DISABLE } from '../../actions/actions';
import ShoppingCartSharpIcon from '@mui/icons-material/ShoppingCartSharp';
import PerfectScrollbar from 'react-perfect-scrollbar';
import * as Sentry from '@sentry/react';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useChainNFTContract } from 'src/context/Contract';
import { PRICE_USD } from 'src/constants';
const useStyles = makeStyles((theme) => ({
  popover: {
    width: 620,
    marginLeft: '17px',
    marginTop: '15px',
    [theme.breakpoints.down('md')]: {
      marginTop: '23px',
    }
  },
  notificationicon: {
    '&.MuiSvgIcon-root': {
      fontSize: '35px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '26px',
      }
    }
  },
  iconButton: {
    '&.MuiIconButton-root': {
      [theme.breakpoints.down('md')]: {
        padding: '0px',
      }
    },
  },
  title: {
    fontSize: 20
  },
  fontStyle: {
    fontWeight: '600',
    textTransform: 'uppercase',
    textAlign:'center',
    fontSize: '16px',
    paddingTop:'8px'

  },
  cartItem: {
    marginBottom: theme.spacing(2),
  },
  paymentmainBox: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  paymentBox: {
    height: 66,
    backgroundColor: 'rgba(63, 61, 122, 0.5)',
    width: '40%',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #9b00f9',
    fontWeight: '600'

  },
  buttonApprove: {
    marginLeft: '20px',
    marginRight: '20px',
    fontSize: '20px',
    color: '#FFFFFF',
    width: '100%',
    borderRadius: '10px',
    alignItems: 'center',
    letterSpacing: '.5px',
    height: '66px',
    marginTop: '10px',
    marginBottom: '15px',
    '&:hover': {
      cursor: 'pointer',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      height: '45px',
      marginTop: '13px'
    },
  },
  inputoutline: {
    paddingLeft: '0px',
    paddingRight: '0px',
    borderRadius: '0px',
    height: '38px',
    fontSize: '16px',
    fontFamily: 'auto',
    width: '90px',
    backgroundColor: 'rgba(63, 61, 122, 0.5)',
  },
  iconBox: {
    backgroundColor: '#0B0B26',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor:'pointer',
    height: '38px',
    width: '20px',
    border: '1px solid #9B00F9',
  },
  fabarrow: {
    fontSize: '15px',
    //backgroundColor: '#8C9FAC',
    height: '20px',
    width: '20px',
    borderRadius: '4px',

  },
}));



const Notifications = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { setMyVariable,disable} = useContext(MyCartcontext);
  const { user, dispatch } = useContext(AuthContext);
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [product, setProduct] = useState([]);
  const { chainNFTContract } = useChainNFTContract();
  const handleOpen = () => {
    setOpen(true);
    getCartDetail();
  };
  const handleClose = () => {
    setOpen(false);
  };
  const getname = (name) => {
    if(name.length>6){
      return name.substring(0,6)+"...";
    }else{
      return name;
    } 
 };
  const getCartDetail = async () => {
    try {
      const { data } = await getCartItems();
      if (data) {
        setProduct(data.assetDetails);
        getSumvalue(data.assetDetails)
        setMyVariable(pre => !pre)
      }
    }
    catch (error) {
      console.log("🚀 ~ file: index.js:730 ~ getCartDetail ~ error", error)
      Sentry.captureException(error, {
        tags: {
          page: location.pathname
        }
      });
    }

  };

  const handleRemoveCartItem = async (address, id1) => {
    try {
      const { data } = await RemoveFromCart({
        tokenID: id1,
        contractAddress: address
      });
    }
    catch (error) {
      console.log("🚀 ~ file: index.js:730 ~ getCartDetail ~ error", error)
      Sentry.captureException(error, {
        tags: {
          page: location.pathname
        }
      });
    }
    getCartDetail()
  };

  const handleUpdatetocart = async (updatedvalue) => {
    try {
      const data = await UpdateintoCart(updatedvalue);
    }
    catch (error) {
      console.log("🚀 ~ file: index.js:730 ~  UpdateintoCart ~ error", error)
      Sentry.captureException(error, {
        tags: {
          page: location.pathname
        }
      });
    }
    getCartDetail()
  }

  const getSumvalue = async (product) => {
    var cartquantity = 0;
    for (var i = 0; i < product.length; i++) {
      cartquantity += product[i].quantity;
    }

    dispatch({
      type: CART_DISABLE,
      payload: {
        cartdisable: user.user.cartdisable,
        totalQuantity: cartquantity,
        cartstatusdata:user.user.cartstatusdata,
        ProgressBarStart:user.user.ProgressBarStart
      }
    })
  }
  useEffect(()=>{
    getcartstatus();
  },[])
  const getcartstatus = async () => {
    try {
         const { data } = await getCartStatus();
        // if (data) {
        //   setCartstatusdata(data.result);
          
        // }
        if(data.cartStatus =='SUCCESS' || data.cartStatus =='PENDING' ){
          dispatch({
            type: CART_DISABLE,
            payload: {
              cartdisable: false,
              totalQuantity:user.user.totalQuantity,
              cartstatusdata:user.user.cartstatusdata,
              ProgressBarStart:user.user.ProgressBarStart
            }
          })
        }
    }
    catch (error) {
        console.log("🚀 ~ file: index.js:730 ~ getCartstatus ~ error", error)
        Sentry.captureException(error, {
            tags: {
                page: location.pathname
            }
        });
    }
 };

  return <>
    <Tooltip title="Notifications">
      <IconButton
        color="inherit"
        ref={ref}
        onClick={handleOpen}
        className={classes.iconButton}
        size="large">
        <Badge badgeContent={user.user.totalQuantity} color="secondary">
          <ShoppingCartSharpIcon className={classes.notificationicon} />
        </Badge>
      </IconButton>
    </Tooltip>
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      classes={{ paper: classes.popover }}
      anchorEl={ref.current}
      onClose={handleClose}
      open={isOpen}
    >
      <Box p={2}>
        <Typography
          variant="h2"
          color="textPrimary"
          className={classes.title}
        >
          Your cart items
        </Typography>
      </Box>
      {product.length != undefined && (
        <>
          <PerfectScrollbar options={{ suppressScrollX: true }}>

            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>name</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>chainusd</TableCell>
                  <TableCell>cxpusd</TableCell>
                  <TableCell>action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {product.map((item, index) => {
                  return (
                    <TableRow key={index} >
                      <TableCell>
                      {item.isCommunityAsset == 1 ? <>
                        <RouterLink to={`/communityAssets/${item.game_id}/${item.token_id}`}>
                          <ListItemAvatar>
                            <Avatar alt={item.name} src={item.imageURL} />
                          </ListItemAvatar>
                        </RouterLink>
                      </>:<>
                      <RouterLink to={`/assets/${item.game_id}/${item.token_id}`}>
                          <ListItemAvatar>
                            <Avatar alt={item.name} src={item.imageURL} />
                          </ListItemAvatar>
                        </RouterLink>
                      </>}
                      </TableCell>
                      <TableCell>
                      <Tooltip  title={item?.name } >
                      <Typography> {getname(item?.name)}</Typography>
                       </Tooltip>
                       </TableCell>
                      <TableCell>
                        <Box className={classes.textField} > 
                              <FormControl
                                variant="outlined"
                              >
                                <OutlinedInput
                                  className={classes.inputoutline}
                                  type="number"
                                  disabled={user.user.cartdisable || disable}
                                  inputProps={{
                                    max: item.avilable_quantity,
                                    min: 1,
                                    style: { textAlign: 'center', }
                                  }}
                                  value={item.quantity}
                                  onChange={(ev) => {
                                    if (ev.target.value <= item.avilable_quantity) {
                                      handleUpdatetocart({
                                        tokenID: Number(item.token_id),
                                        quantity: Number(ev.target.value),
                                        gameID: Number(item.game_id),
                                        isCommunityAsset: item.isCommunityAsset,
                                        contractAddress: item.contractAddress,
                                        chainInUsd: item.chain_in_usd,
                                        cxpInUsd: item.cxp_in_usd
                                      })
                                    }
                                  }}
                                  id="outlined-adornment-weight"
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <Box className={classes.iconBox} >
                                        <RemoveIcon
                                          className={classes.fabarrow}
                                          onClick={() => {
                                            if (item.quantity >= 2 && !user.user.cartdisable && !disable) {
                                              handleUpdatetocart({
                                                tokenID: Number(item.token_id),
                                                quantity: Number(item.quantity - 1),
                                                gameID: Number(item.game_id),
                                                isCommunityAsset: item.isCommunityAsset,
                                                contractAddress: item.contractAddress,
                                                chainInUsd: item.chain_in_usd,
                                                cxpInUsd: item.cxp_in_usd
                                              })
                                            }
                                          }
                                          }
                                        />
                                      </Box>
                                    </InputAdornment>
                                  }
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <Box className={classes.iconBox} >
                                        <AddIcon
                                          className={classes.fabarrow}
                                          onClick={() => {
                                            if (item.quantity <= item.avilable_quantity - 1 && !user.user.cartdisable && !disable) {
                                              handleUpdatetocart({
                                                tokenID: Number(item.token_id),
                                                quantity: Number(item.quantity + 1),
                                                gameID: Number(item.game_id),
                                                isCommunityAsset: item.isCommunityAsset,
                                                contractAddress: item.contractAddress,
                                                chainInUsd: item.chain_in_usd,
                                                cxpInUsd: item.cxp_in_usd
                                              })
                                            }
                                          }
                                          }
                                        />
                                      </Box>
                                    </InputAdornment>
                                  }
                                  aria-describedby="outlined-weight-helper-text"
                                />
                              </FormControl>
                        </Box>
                      </TableCell>
                      <TableCell>{item.chain_in_usd * item.quantity / PRICE_USD} </TableCell>
                      <TableCell>{item.cxp_in_usd * item.quantity / PRICE_USD} </TableCell>
                      <TableCell> <IconButton
                        edge="end"
                        aria-label="delete"
                        disabled={user.user.cartdisable || disable}
                        onClick={() => handleRemoveCartItem(item.contractAddress, item.token_id)}
                        size="large">
                        <DeleteIcon />
                      </IconButton></TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {product.length == 0 && (
                  <Box p={2} >
                    <Typography variant="body2" color="textPrimary" className={classes.fontStyle}>
                      no items in cart
                    </Typography>
                  </Box>
                )}
          </PerfectScrollbar>
          <Box pr={5} mt={2}>
            <Button
              className={classes.buttonApprove}
              onClick={() => { history.push('/cart'), setOpen(false) }}
              variant="contained"
              //disabled={product.length <= 0}
              color='secondary'
            >
             { user.user.cartdisable || product.length <= 0 ?<>go to cart</>:<>Complete purchase</>} 
            </Button>
          </Box>
        </>
      )}
    </Popover>
  </>;
};

export default Notifications;
