import React, { useEffect, useState } from 'react'
import { useReducer } from 'reinspect';
import ChainNFTContract from "./ChainNFTContract";
import CommunityNFTContract from './CommunityNFTContract';
import { useWeb3React } from '@web3-react/core';
import {
    getAllGames,
  } from 'src/service/centralServerService';


const ChainNFTContractContext = React.createContext();
const ChainNFTContractDispatch = React.createContext();


function ChainNFTContractProvider({ children }) {
     let chainNFTContractArray12=[];
     let chainNFTContractArray13=[];
     const [chainNFTContract, setchainNFTContract] = useState([]);
     const [communityNFTContract, setCommunityNFTContract] = useState([]);

    useEffect(() => {
        const getGames = async () => {
            const games = await getAllGames();
            if (games?.data?.games.length > 0) {
                games?.data?.games.map((value, index) => {
                    let a = new ChainNFTContract(value.contractAddress);
                    chainNFTContractArray12.push(a);
                    if (value.community_nft_contract_address != null) {
                        let b = new CommunityNFTContract(value.community_nft_contract_address);
                        chainNFTContractArray13.push(b);
                    }
                });
            }
        };
        getGames();
        setchainNFTContract(chainNFTContractArray12);
        setCommunityNFTContract(chainNFTContractArray13);
    }, []);
      
    return (
        <ChainNFTContractContext.Provider value={{ chainNFTContract, communityNFTContract }}>
            <ChainNFTContractDispatch.Provider >{children}</ChainNFTContractDispatch.Provider>
        </ChainNFTContractContext.Provider>
    )

}

function useChainNFTContract() {
    const context = React.useContext(ChainNFTContractContext);
    if (context === undefined) {
        throw new Error('useChainNFTContract must be used within a ChainNFTContractProvider')
    }
    return context  
}

// function useChainNFTDispatch() {
//     const context = React.useContext(ChainNFTContractDispatch);
//     if (context === undefined) {
//         throw new Error('useChainNFTDispatch must be used within a ChainNFTContractProvider');
//     }
//     return context;
// }


export { ChainNFTContractProvider, useChainNFTContract }
// useChainNFTDispatch