import React, { useContext, useEffect, useState } from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardMedia,
  Container,
  Divider,
  Grid,
  Typography,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  SvgIcon,
  Tooltip,
  useTheme,
  useMediaQuery,
  FormControl,
  Select,
  MenuItem,
  Avatar,
  Hidden,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FIFA_Image from '../../../assets/img/fifa21.jpg';
import NBA_Image from '../../../assets/img/nba.jpg';
import COD_Image from '../../../assets/img/cod.jpg';
import MADDEN_Image from '../../../assets/img/madden.png';
import eyes from '../../../assets/img/eyes.svg';
import {
  SupportedGamesWithID,
  AllSupportedGamesNames,
  AllSupportedGamesWithOtherAttributes,
  timerangeOptions,
  SuperCryptoCart,
  AllSupportedGamesIncludeSCK
} from '../../../config/constants';
import { Star as StarIcon } from 'react-feather';
import Halo_Image from '../../../assets/img/halo.jpg';
import { getLeaderBoardDetails } from 'src/service/centralServerService';
import gold from '../../../assets/img/trophy_gold.png';
import silver from '../../../assets/img/trophy_silver.png';
import bronze from '../../../assets/img/trophy_bronze.png';
import * as Sentry from '@sentry/react';

const font = "'K2D', sans-serif";

const useStyles = makeStyles((theme) => ({
  gamesTitle: {
    fontFamily: font,
    fontSize: 40
  },
  title: {
    position: 'relative',
    '&:before': {
      position: 'absolute',
      bottom: -2,
      left: -16,
      content: '" "',
      height: 62,
      width: 6,
      backgroundColor: theme.palette.secondary.main,
      marginRight: '20px'
    }
  },
  '@keyframes fadeIn': {
    '0%': {
      color: theme.palette.secondary.main
    },
    '100%': {
      color: '#30913C'
    }
  },
  icon: {
    marginRight: theme.spacing(1),
    animation: '$fadeIn 1s alternate infinite'
  },
  imageCell: {
    height: '60px',
    width: '60px',
    padding: 0
  },
  rowImage: {
    height: '60px',
    width: '60px',
    margin: 0,
    padding: 0,
    verticalAlign: 'top',
    objectFit: 'cover'
  },
  entry: {
    fontFamily: font,
    fontSize: 16
  },
  entryFree: {
    fontFamily: font,
    fontSize: 16,
    color: theme.palette.secondary.main
  },
  priceCell: {
    color: theme.palette.success.main,
    fontFamily: font,
    fontSize: 16
  },
  scrollBox: {
    overflow: 'auto',
    paddingLeft: '3%',
    paddingRight: '3%',
  },
  formControl: {
    minWidth: 171,
    padding: 0,
    marginRight: '20px',
    backgroundColor: ' #3F3D7A3D',
    '& .MuiOutlinedInput-root':{
      backgroundColor:'rgba(63, 61, 122, 0.55)',
       border:0,
       borderRadius:'0px'
    },
    '&.MuiOutlinedInput-notchedOutline .MuiSelect-outlined':{
      border: 'none !important',
    }
  },
  formControlRank: {
    minWidth: 171,
    padding: 0,
    marginRight: '20px',
    textColor: 'white',
    fontWeight: '800',
    backgroundColor: ' #3F3D7A3D',
    '& .MuiOutlinedInput-root':{
      backgroundColor:'rgba(63, 61, 122, 0.55)',
       border:0,
       borderRadius:'0px'
    },
    '&.MuiOutlinedInput-notchedOutline .MuiSelect-outlined':{
      border: 'none !important',
    }
  },
  // avatarCell: {
  //   width: '20px'
  // },
  avatarGold: {
    // height: '100px',
    // width: '100px'

    height: theme.spacing(5),
    width: theme.spacing(5)

    // marginTop: theme.spacing(2),

    // marginBottom: theme.spacing(1),
    // border: '3px solid',
    // borderColor: '#F1B640',
    //   boxShadow:
    //     '0px 3px 1px -2px #F1B640,0px 2px 2px 0px #F1B640,0px 1px 5px 0px #F1B640'
  },
  noLeaderboard: {
    minHeight: '20px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  noLeaderboardText: {
    textTransform: 'none'
  },
  tabButton: {
    backgroundColor: '#9B00F9',
    border: '1px solid #9B00F9',
    marginRight: '20px',
    textColor: 'white',
    fontWeight: '800',
    height: '45px'

  },
  tabButton2: {
    border: '1px solid #9B00F9',
    marginRight: '20px',
    textColor: 'white',
    fontWeight: '800',
    height: '45px'
  },

}));

const applyPagination = (list, page, limit) => {
  return list.slice(page * limit, page * limit + limit);
};

const LeaderBoard = () => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState('wins');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [leaderBoardData, setLeaderBoardData] = useState([]);
  const [selectedAmount, setSelectedAmount] = useState(false);
  const [games, setGames] = useState(['All Games', SuperCryptoCart.name, ...AllSupportedGamesNames.slice(6, 10)]);
  const [selectedGame, setSelectedGame] = useState('All Games');
  const allGames = ['All Games', SuperCryptoCart.name, ...AllSupportedGamesNames.slice(6, 10)];
  const [gameId, setGameId] = useState('');
  const [timeOptions, setTimeOptions] = useState('Monthly');
  const [showModal, setShowModal] = useState(true);
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));
  const tabIndicatorStyle = {
    display: "none",
  };
  const tabs = [
    { value: 'wins', label: 'MOST WINS' },
    { value: 'amount', label: 'MOST WAGERS' }
  ];

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value));
  };

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
    setSelectedAmount(!selectedAmount);
    setTimeOptions('Monthly');
    setSelectedGame('All Games');
    setGameId('');
    setPage(0);
  };

  const onDropdownClick = (event) => {
    setSelectedGame(event.target.value);
    setPage(0);
    if (event.target.value === 'All Games') {
      setGames([...allGames]);
    } else {
      setGames([event.target.value]);
    }
  };

  const onClickTimeOptions = (event) => {
    setTimeOptions(event.target.value);
    setPage(0);
  };

  const getGameId = () => {
    if (selectedGame != 'All Games') {
      const gameidFilter = AllSupportedGamesIncludeSCK.filter(
        (row) => row.name == selectedGame
      );
      setGameId(gameidFilter[0].id);
    } else {
      setGameId('');
    }
  };

  const getLeaderBoardData = async () => {
    let leaderboardFilter = {
      getAmount: selectedAmount,
      [`is${timeOptions}`]: true,
    };
    if (gameId || (selectedGame != 'All Games')) {
      leaderboardFilter.gameID = gameId;
    }
    try {
      const { data } = await getLeaderBoardDetails(leaderboardFilter);
      if (data.success) {
        setLeaderBoardData(data.LeaderboardDetails);
      }
    } catch (error) {
      console.log(
        '🚀 ~ file: LeaderBoard.js ~ line 221 ~ getLeaderBoardData ~ error',
        error
      );
      Sentry.captureException(error, {
        tags: {
          page: location.pathname
        }
      });
    }
  };
  const paginatedLeaderBoard = applyPagination(leaderBoardData, page, limit);

  useEffect(() => {
    if (showModal) getLeaderBoardData();
  }, [selectedAmount, gameId, timeOptions, showModal]);

  useEffect(() => {
    getGameId();
  }, [selectedGame]);

  const handleClick = () => {
    setShowModal(true);
  }

  return <>
    <Box>
      {/* <Box ml={2} mt={10} mb={3}>
        <Typography
          display="inline"
          variant="h2"
          color="textPrimary"
          className={classes.gamesTitle}
        >
          Leaderboard
        </Typography>
        <Typography
          className={classes.title}
          variant="h4"
          color="textPrimary"
        >
          Chain Games Leaders
        </Typography>
      </Box> */}
      {/* {!showModal && (
        <Box className={classes.noLeaderboard}>
          <Button
            color="secondary"
            variant="outlined"
            onClick={handleClick}
            className={classes.noLeaderboardText}
          >
            {' '}
            Click Here To View Leaderboard Details
          </Button>
        </Box>
      )} */}
      {showModal && (
        <>
          <Box mt={1} mb={3} pl='3%' pr='3%' display='flex' flexDirection='row' alignItems='center'>
            <Box>
              <Tabs
                TabIndicatorProps={{ style: tabIndicatorStyle }}
                onChange={handleTabsChange}
                scrollButtons="auto"
                textColor='white'
                value={currentTab}
                variant="scrollable"
              >
                {tabs.map((tab) => (
                  <Tab key={tab.value} label={tab.label} value={tab.value} className={currentTab == tab.value ? classes.tabButton : classes.tabButton2} />
                ))}
              </Tabs>
              {/* <Divider /> */}
            </Box>
            <Box display='flex'>
            <Hidden only='xs'>

            <Hidden only='sm'>
             
              <Box >
                <FormControl
                  className={classes.formControl}
                >
                      <Select
                        id="select-game"
                        value={selectedGame}
                        onChange={onDropdownClick}
                        style={{
                          height: '45px', fontWeight: '800', fontFamily: 'Orbitron', backgroundColor: 'rgba(63, 61, 122, 0.24)',
                        }}>
                        {allGames.map((row, index) => (
                          <MenuItem key={index} value={row}>
                            {row}
                          </MenuItem>
                        ))}
                      </Select>
                </FormControl>
              </Box>
              </Hidden>
              <Box>
                <FormControl
                  variant="outlined"
                  className={classes.formControlRank}
                >
                  <Select
                    sx={{
                      boxShadow: "none",
                      // ".MuiOutlinedInput-notchedOutline": { border: 0 },
                      // "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      // {
                      //   border: 0,
                      // },
                      // "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      // {
                      //   border: 0,
                      // },
                    }}
                    id="select-rankoptions"
                    value={timeOptions}
                    style={{ height: '45px', fontWeight: '800', fontFamily: 'Orbitron', backgroundColor: 'rgba(63, 61, 122, 0.24)' }}
                    onChange={onClickTimeOptions}>
                    {timerangeOptions.map((row, index) => (
                      <MenuItem key={index} value={row.name}>
                        {row.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
          
              </Hidden>
            </Box>
            
          </Box>
          <Hidden only={['md','lg','xl']}>
          <Box  mt={1} mb={3} pl='3%' pr='3%' >
              
               
                <FormControl
                  variant="outlined"
                  style={{ backgroundColor: ' #3F3D7A3D',width:"100%"}}
                >
                  <Select
                    id="select-game"
                    value={selectedGame}
                    onChange={onDropdownClick}
                    style={{ height: '45px', fontWeight: '800', fontFamily: 'Orbitron', backgroundColor: 'rgba(63, 61, 122, 0.24)' }}>
                    {allGames.map((row, index) => (
                      <MenuItem key={index} value={row}>
                        {row}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                   <Hidden only='sm'>
                 <FormControl
                  variant="outlined"
                  style={{ backgroundColor: ' #3F3D7A3D',width:"100%",marginTop:'12px'}}
                >
                  <Select
                    sx={{
                      boxShadow: "none",
                      ".MuiOutlinedInput-notchedOutline": { border: 0 },
                      "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        border: 0,
                      },
                      "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        border: 0,
                      },
                    }}
                    id="select-rankoptions"
                    value={timeOptions}
                    style={{ height: '45px', fontWeight: '800', fontFamily: 'Orbitron', backgroundColor: 'rgba(63, 61, 122, 0.24)' }}
                    onChange={onClickTimeOptions}>
                    {timerangeOptions.map((row, index) => (
                      <MenuItem key={index} value={row.name}>
                        {row.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                </Hidden>  
              </Box>
          </Hidden>  

          <Box className={classes.scrollBox} >
            <Box minWidth={300}>
              <Table>
                <TableHead>
                  <TableRow>
                  {!mobileDevice && <TableCell className={classes.avatarCell}></TableCell>}
                    <TableCell className={classes.avatarCell}>RANK</TableCell>
                    {!mobileDevice && <TableCell className={classes.avatarCell}>
                      AVATAR
                    </TableCell>
                    }
                    <TableCell>USERNAME</TableCell>
                    {currentTab == 'wins' && (
                      <>
                        <TableCell>{mobileDevice ? 'WINS' : 'TOTAL WINS'}</TableCell>
                        <TableCell>{mobileDevice ? 'GAMES' : 'TOTAL GAMES'}</TableCell>
                      </>
                    )}
                    {currentTab == 'amount' && (
                      <>
                        <TableCell>{mobileDevice ? 'BET AMOUNT(USD)' : 'TOTAL BET AMOUNT(USD)'}
                        </TableCell>
                        <TableCell>{mobileDevice ? 'AMOUNT OF WINS(USD)' : 'TOTAL AMOUNT OF WINS(USD)'}</TableCell>
                      </>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedLeaderBoard.map((entry) => {
                    return (
                      <TableRow spacing={0}>
                        {!mobileDevice && <>{entry.rank == 1 && (
                          <TableCell>
                            {' '}
                            <Avatar
                              className={classes.avatarGold}
                              src={gold}
                            />
                          </TableCell>
                        )}
                        {entry.rank == 2 && (
                          <TableCell>
                            {' '}
                            <Avatar
                              className={classes.avatarGold}
                              src={silver}
                            />
                          </TableCell>
                        )}
                        {entry.rank == 3 && (
                          <TableCell>
                            {' '}
                            <Avatar
                              className={classes.avatarGold}
                              src={bronze}
                            />
                          </TableCell>
                        )}
                        {entry.rank >= 4 && <TableCell></TableCell>}</>}
                        <TableCell>{entry.rank}</TableCell>
                        {!mobileDevice && <TableCell
                          component={RouterLink}
                          to={`/profile/${entry.username}`}
                          style={{ textDecoration: 'none' }}
                        >
                          <Avatar
                            src={
                              entry.user_dp ? entry.user_dp : defaultAvatar
                            }
                          />
                        </TableCell>
                        }
                        <TableCell
                          component={RouterLink}
                          to={`/profile/${entry.username}`}
                          style={{ textDecoration: 'none',color:'#00AEFF' }}
                        >
                          {entry.username.toUpperCase()}
                          &nbsp; &nbsp;
                          <img src={eyes}></img>
                        </TableCell>

                        {currentTab == 'wins' && (
                          <>
                            <TableCell>{entry.total_wins}</TableCell>
                            <TableCell>{entry.total_matches}</TableCell>
                          </>
                        )}

                        {currentTab == 'amount' && (
                          <>
                            <TableCell>
                              {entry.total_bet_amount?.toFixed(2)}
                            </TableCell>
                            <TableCell>
                              {entry.total_amount_wins?.toFixed(2)}
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                count={leaderBoardData.length}
                labelRowsPerPage={'Rows per page'}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 25]}
              />
            </Box>
          </Box>
         
        </>
      )}
    </Box>
  </>;
};

export default LeaderBoard;
