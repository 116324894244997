import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'prismjs/prism';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-jsx';
import 'nprogress/nprogress.css';
import 'react-toastify/dist/ReactToastify.css';
import 'src/assets/css/prism.css';
import 'src/assets/css/font.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { enableES5 } from 'immer';
import * as serviceWorker from 'src/serviceWorker.js';
import store from 'src/store/index.js';
import { SettingsProvider } from 'src/contexts/SettingsContext.js';
import App from 'src/App.js';

enableES5();

ReactDOM.render(
  <Provider store={store}>
    <SettingsProvider>
      <App />
    </SettingsProvider>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.register();
