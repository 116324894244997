import { network, CovalentApiKey, } from '../config/constants';
import axios from 'axios';
const { nodeAxios, formDataAxiosService } = require("../config/axiosForWallet");
const axiosConfig = {
  withCredentials: true,
}

const increasedTimeoutConfig = {
  timeout: 30000,
}

export const registerUser = async (body) => {
  return await nodeAxios.post(`/public/register`, body)
}

export const loginService = async (body) => {
  return await nodeAxios.post(`/public/login`, body, axiosConfig);
};

export const verifyEmailService = async (body) => {
  return await nodeAxios.post(`/public/verify-email-token`, body, axiosConfig);
};

export const verifyForgotPasswordTokenService = async (body) => {
  return await nodeAxios.post(`/public/verify-forgot-password-token`, body, axiosConfig);
};

export const resetForgottenPasswordService = async (body) => {
  return await nodeAxios.post(`/public/reset-forgotten-password`, body, axiosConfig);
};

export const logoutService = async () => {
  return await nodeAxios.post(`/authenticated/logout`, {}, axiosConfig);
};

export const postReportIssue = async (body) => {
  return await formDataAxiosService.post(`/authenticated/report-issue`, body);
};

export const recoverPassword = async (body) => {
  return await nodeAxios.post(`/public/send-reset-link`, body)
}

export const changePassword = async (body) => {
  return await nodeAxios.post(`/authenticated/change-password`, body, axiosConfig)
}

export const burnTxHash = async (body) => {
  return await nodeAxios.post(`/authenticated/burn-tx-withdrawn`, body, axiosConfig);
}

export const getChainAssetApproveTx = async (body) => {
  return await nodeAxios.post(`/public/get-chain-asset-approve-tx`, body, axiosConfig)
}

export const sendChainAssetApproveTx = async (body) => {
  return await nodeAxios.post(`/public/send-chain-asset-approve-tx`, body, {...axiosConfig, ...increasedTimeoutConfig})
}

export const getMintableToken = async(body) =>{
  return await nodeAxios.post('/authenticated/get-mintable-token',body,axiosConfig)
}
export const getCXPAssetApproveTx = async (body) => {
  return await nodeAxios.post(`/public/get-cxp-asset-approve-tx`, body, axiosConfig)
}

export const sendCXPAssetApproveTx = async (body) => {
  return await nodeAxios.post(`/public/send-cxp-asset-approve-tx`, body, {...axiosConfig, ...increasedTimeoutConfig})
}

export const getChainAssetPurchaseUsingChainTx = async (body) => {
  return await nodeAxios.post(`/public/get-chain-asset-purchase-using-chain`, body, axiosConfig) 
}

export const sendChainAssetPurchaseUsingChainTx = async (body) => {
  return await nodeAxios.post(`/public/send-chain-asset-purchase-using-chain`, body, {...axiosConfig, ...increasedTimeoutConfig})
}

export const getChainAssetPurchaseUsingCXPTx = async (body) => {
  return await nodeAxios.post(`/public/get-chain-asset-purchase-using-cxp`, body, axiosConfig) 
}

export const sendChainAssetPurchaseUsingCXPTx = async (body) => {
  return await nodeAxios.post(`/public/send-chain-asset-purchase-using-cxp`, body, {...axiosConfig, ...increasedTimeoutConfig})
}

export const getChainAssetPurchase = async (body) => {
  return await nodeAxios.post(`/public/get-chain-asset-purchase`, body, axiosConfig) 
}

export const sendChainAssetPurchase = async (body) => {
  return await nodeAxios.post(`/public/send-chain-asset-purchase`, body, {...axiosConfig, ...increasedTimeoutConfig})
}

export const getFullApproveTx = async (body) => {
  return await nodeAxios.post(`/authenticated/get-full-approval-tx`, body, axiosConfig)
}

export const sendFullApproveTx = async (body) => {
  return await nodeAxios.post(`/authenticated/send-full-approval-tx`, body, {...axiosConfig, ...increasedTimeoutConfig})
}
export const getCxpMintTx = async (body) => {
  return await nodeAxios.post(`/authenticated/get-cxp-mint-tx`, body, axiosConfig)
}

export const sendCxpMintTx = async (body) => {
  return await nodeAxios.post(`/authenticated/send-cxp-mint-tx`, body, {...axiosConfig, ...increasedTimeoutConfig})
}
export const getBurnTx = async (body) => {
  return await nodeAxios.post(`/authenticated/get-burn-tx`, body, axiosConfig)
}

export const sendBurnTx = async (body) => {
  return await nodeAxios.post(`/authenticated/send-burn-tx`, body, {...axiosConfig, ...increasedTimeoutConfig})
}

export const getTransferTx = async (body) => {
  return await nodeAxios.post(`/authenticated/get-transfer-tx`, body, axiosConfig)
}

export const sendTransferTx = async (body) => {
  return await nodeAxios.post(`/authenticated/send-transfer-tx`, body, {...axiosConfig, ...increasedTimeoutConfig})
}

export const getAllBurntTxn = async () => {
  return await nodeAxios.post(`/authenticated/get-all-burn-txs`, {}, axiosConfig)
}

export const getNonceForSign = async () => {
  return await nodeAxios.post(`/authenticated/get-nonce`, {}, axiosConfig)
}

export const verifyNonce = async (body) => {
  return await nodeAxios.post(`/authenticated/verify-nonce`, body, axiosConfig)
}

export const getMyInfoService = async (body) => {
  return await nodeAxios.post(`/authenticated/get-my-info`, body, axiosConfig)
}

export const userInfoCSService = async (body) => {
  return await nodeAxios.post(`/authenticated/get-user-info`, body, axiosConfig)
};

//Paypal Services
export const sendOrderIDService = async (body) => {
  return await nodeAxios.post(`/authenticated/paypal-orderID`, body, axiosConfig)
}

export const getPaypalEmailsService = async (body) => {
  return await nodeAxios.post(`/authenticated/get-paypal-emails`, body, axiosConfig)
}

export const initiateWithdrawService = async (body) => {
  return await nodeAxios.post(`/authenticated/paypal-withdraw-initiate`, body, axiosConfig)
}

export const getBalanceService = async (body) => {
  return await nodeAxios.post(`/authenticated/get-balance`, body, axiosConfig)
}

export const verifyWithdrawService = async (body) => {
  return await nodeAxios.post(`/public/verify-withdraw-token`, body, axiosConfig);
};

export const getTransactionHistoryService = async (body) => {
  return await nodeAxios.post(`/authenticated/get-transaction-history`, body, axiosConfig);
};

export const resendWithdrawEmailService = async (body) => {
  return await nodeAxios.post(`/authenticated/resend-withdraw-email`, body, axiosConfig);
};

export const changeEmailService = async (body) => {
  return await nodeAxios.post(`/authenticated/change-email`, body, axiosConfig);
};

export const verifyNewEmailService = async (body) => {
  return await nodeAxios.post(`/authenticated/check-new-email`, body, axiosConfig);
};

export const getTokenBurnAmountFromCovalentService = async (txnHash) => {
  return await axios.get(`https://api.covalenthq.com/v1/${network.maticChainId}/transaction_v2/${txnHash}/?key=${CovalentApiKey}`);
};

export const checkCanTransferChainService = async (body) => {
  return await nodeAxios.post(`/authenticated/validate-transfer`, body, axiosConfig);
};
//Transak Service
export const sendTransakObjectService = async (body) => {
  return await nodeAxios.post(`/authenticated/send-transak-object`, body, axiosConfig);
};

export const getBonusHistoryService = async (body) => {
  return await nodeAxios.post(`/authenticated/get-bonus-history`, body, axiosConfig);
};

export const addBaseBurnTx = async (body) => {
  return await nodeAxios.post(`/authenticated/add-base-burn-tx`, body, axiosConfig);
};