import React, {
  useEffect,
  useState,
  useRef
} from 'react';
import { nodeAxiosWithCredentials } from '../../../config/axios.js';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import {
  Box,
  IconButton,
  Popover,
  TextField,
  Typography,
  MenuItem,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Autocomplete from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme) => ({
  popover: {
    width: 320,
    padding: 0
  },
  navBox: {
    fontFamily: 'Orbitron',
    backgroundColor: 'rgba(63, 61, 122, 0.24)', 
    width:'239px',
    '@media(max-width:1755px)': {
      width: '346px'
   },
    '&.MuiAutocomplete-clearIndicator':{
      padding:'3px'
    }
  },
  navTextBox: {
    '&.MuiOutlinedInput-root': {
      backgroundColor: 'rgba(63, 61, 122, 0.24)',  
      height:'45px !important',
      borderRadius:'0px',
  },
}
}));

const SearchBox = ({}) => {
  const classes = useStyles();
  const [cancelToken, setCancelToken] = useState('');
  const ref = useRef(null);
  const [isOpenPopover, setIsOpenPopover] = useState(true);
  const history = useHistory();
  const [openSearchBox, setOpenSearchBox] = useState(false);
  const [options, setOptions] = useState([]);
  const [input, setInput] = useState('');
  const loading = openSearchBox && options.length === 0 && !input;
  const theme = useTheme();
  const mobileDevice = useMediaQuery('(max-width:1755px)');


  useEffect(() => {
    if (!openSearchBox) {
      setOptions([]);
    }
  }, [openSearchBox]);

  const handleOpen = () => {
    setIsOpenPopover(true);
  };

  const handleClose = () => {
    setIsOpenPopover(false);
  };

  const promiseOptions = async (inputValue) => {
    try {
      if (cancelToken) {
        // Cancel the previous request before making a new request
        cancelToken.cancel();
      }

      setCancelToken(axios.CancelToken.source());
      const data = await nodeAxiosWithCredentials.post(
        `authenticated/get-usernames`,
        {
          identifier: inputValue
        },
        {
          cancelToken: cancelToken.token
        }
      );
      setCancelToken('');
      let result = data.data.usernames || [];
      result = result.map((row) => {
        return { label: row, value: row };
      });
      setOptions(result);
      setInput(inputValue);
    } catch (error) {
      console.log(
        '🚀 ~ file: ChallengeModal.jsx ~ line 126 ~ //newPromise ~ error',
        error
      );

      if (axios.isCancel(error)) {
        // Handle if request was cancelled
        console.log('Request canceled', error.message);
      } else {
        // Handle usual errors
        console.log('Something went wrong: ', error.message);
      }
    }
  };

  return <>
        <Autocomplete
          id="asynchronous-user-search"
          freeSolo
          className={classes.navBox}
          open={openSearchBox}
          onOpen={() => {
            setOpenSearchBox(true);
          }}
          onClose={() => {
            setOpenSearchBox(false);
          }}
          options={options}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          getOptionLabel={(option) => option.label}
          loading={loading}
          onChange={(event, value) => {
            if (value != null) {
              setOpenSearchBox(false);
              handleClose();
              history.push(`/profile/${value.value? value.value : value}`);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="SEARCH USERS"
              variant="outlined"
              onChange={(ev) => {
                if (ev.target.value !== '' || ev.target.value !== null) {
                  promiseOptions(ev.target.value);
                }
              }}
              InputLabelProps={{style: {fontSize: 12, paddingTop: '5px',fontWeight:400, paddingLeft: mobileDevice? '14px': ''}}} 
              InputProps={{
                ...params.InputProps,
                className: classes.navTextBox,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : <SearchIcon style={{marginRight:'5px'}} />}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                )
              }}
              size="small"
            />
      
          )}
        />
  </>;
};


export default SearchBox;
