import React, { Suspense, Fragment, lazy, useState } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/home/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import { SessionID } from './reducer/reducer';
import { checkIsPrivatePath, checkIsPublicPath } from './utils/helpers';
import RedirectPage from './views/redirection';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <>
                      {route.path == '/approve-via-game' && (
                        <RedirectPage successPath="/approve" />
                      )}
                      {route.path == '/login-via-game' && (
                        <RedirectPage successPath="/dashboard" />
                      )}
                      {route.path == '/report-issue' && (
                        <RedirectPage successPath="/reportIssue" />
                      )}
                      {route.path == '/link-metamask' && (
                        <RedirectPage successPath="/dashboard" />
                      )}
                      {route.path == '/change-password' && (
                        <RedirectPage successPath="/userAccountSetting" />
                      )}
                      {(checkIsPublicPath(route.path) &&
                        SessionID !== 'true') ||
                      (checkIsPrivatePath(route.path) &&
                        SessionID === 'true') ? (
                        <>
                          {route.path !== '/approve-via-game' &&
                            route.path !== '/login-via-game' &&
                            route.path !== '/report-issue' &&
                            route.path !== '/link-metamask' &&
                            route.path !== '/change-password' && (
                              <Component {...props} />
                            )}
                        </>
                      ) : SessionID === 'true' ? (
                        route.path !== '/approve-via-game' &&
                        route.path !== '/login-via-game' &&
                        route.path !== '/report-issue' &&
                        route.path !== '/link-metamask' &&
                        route.path !== '/change-password' && (
                          <Redirect to="/dashboard" />
                        )
                      ) : (
                        route.path !== '/approve-via-game' &&
                        route.path !== '/login-via-game' &&
                        route.path !== '/report-issue' &&
                        route.path !== '/link-metamask' &&
                        route.path !== '/change-password' && (
                          <Redirect to="/login" />
                        )
                      )}
                    </>
                  )}
                </Layout>
              </>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
 
 
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/404',
        component: lazy(() => import('src/views/errors/NotFoundView'))
      },
      {
        exact: true,
        path: '/500',
        component: lazy(() => import('src/views/errors/InternalServerErrorView'))
      },
      {
        exact: true,
        path: '/VerifyEmail',
        component: lazy(() => import('src/views/auth/VerifyEmailView'))
      },
      {
        exact: true,
        path: '/verifyForgotPassword',
        component: lazy(() => import('src/views/auth/VerifyForgotPasswordView'))
      },
      {
        exact: true,
        path: '/ForgotPassword',
        component: lazy(() => import('src/views/auth/ForgotPasswordView'))
      },
      {
        exact: true,
        path: '/ChangeUsername',
        component: lazy(() => import('src/views/auth/ChangeUsernameView'))
      },
      {
        exact: true,
        path: '/verifyChangeUsername',
        component: lazy(() => import('src/views/auth/VerifyChangeUsernameView'))
      },
      {
        exact: true,
        path: '/register',
        component: lazy(() => import('src/views/auth/RegisterView'))
      },
      {
        exact: true,
        path: '/login',
        component: lazy(() => import('src/views/auth/LoginView'))
      },
      {
        exact: true,
        path: '/404',
        component: lazy(() => import('src/views/errors/NotFoundView'))
      },
      {
        exact: true,
        path: '/actionGamePage/cod',
        component: lazy(() => import('src/views/actionGameView'))
      },
      {
        exact: true,
        path: '/actionGamePage/halo',
        component: lazy(() => import('src/views/actionGameView'))
      },
      {
        exact: true,
        path: '/actionGamePage/pubg',
        component: lazy(() => import('src/views/actionGameView'))
      },
      {
        exact: true,
        path: '/actionGamePage/fifa',
        component: lazy(() => import('src/views/actionGameView'))
      },
      {
        exact: true,
        path: '/actionGamePage/madden2021',
        component: lazy(() => import('src/views/actionGameView'))
      },
      {
        exact: true,
        path: '/actionGamePage/nba',
        component: lazy(() => import('src/views/actionGameView'))
      },
      {
        exact: true,
        path: '/gameInformationPage/:id?',
        component: lazy(() => import('src/views/gameInfo'))
      },
      {
        exact: true,
        path:'/mint-cxp-token',
        component:lazy(()=>import('src/views/cxpMintToken'))
      },
      // {
      //   exact: true,
      //   path: '/myChat',
      //   component: lazy(() => import('src/views/chat'))
      // },
      {
        exact: true,
        path: '/myEvents',
        component: lazy(() => import('src/views/upcomingEvents'))
      },
      {
        exact: true,
        path: '/myChallenges',
        component: lazy(() => import('src/views/challenges'))
      },
      {
        exact: true,
        path: '/profile/:username?',
        component: lazy(() => import('src/views/profile'))
      },
      {
        exact: true,
        path: '/myWallet',
        component: lazy(() => import('src/views/myWallet'))
      },
      {
        exact: true,
        path: '/approve',
        component: lazy(() => import('src/views/approve'))
      },
      {
        exact: true,
        path: '/approve-via-game',
        component: lazy(() => import('src/views/redirection'))
      },
      {
        exact: true,
        path: '/login-via-game',
        component: lazy(() => import('src/views/redirection'))
      },
      {
        exact: true,
        path: '/report-issue',
        component: lazy(() => import('src/views/redirection'))
      },
      {
        exact: true,
        path: '/link-metamask',
        component: lazy(() => import('src/views/redirection'))
      },
      {
        exact: true,
        path: '/change-password',
        component: lazy(() => import('src/views/redirection'))
      },
      {
        exact: true,
        path: '/transactionHistory',
        component: lazy(() => import('src/views/transactionHistory'))
      },
      {
        exact: true,
        path: '/withdraw',
        component: lazy(() => import('src/views/withdraw'))
      },
      {
        exact: true,
        path: '/deposit',
        component: lazy(() => import('src/views/deposit'))
      },
      {
        exact: true,
        path: '/tokenTransfer',
        component: lazy(() => import('src/views/tokenTransfer'))
      },
      {
        exact: true,
        path: '/userAccountSetting',
        component: lazy(() => import('src/views/userAccountSetting'))
      },
      {
        exact: true,
        path: '/consoleLink/:values?',
        component: lazy(() => import('src/views/consoleLink'))
      },
      {
        exact: true,
        path: '/reportIssue',
        component: lazy(() => import('src/views/reportIssue'))
      },
      {
        exact: true,
        path: '/admin',
        component: lazy(() => import('src/views/admin'))
      },
      {
        exact: true,
        path: '/manageevents',
        component: lazy(() => import('src/views/manageevents'))
      },
      {
        exact: true,
        path: '/liveStats/:id?',
        component: lazy(() => import('src/views/liveStats'))
      },
      {
        exact: true,
        path: '/dispute/:id?',
        component: lazy(() => import('src/views/dispute'))
      },
      {
        exact: true,
        path: '/claim-network/:tag/:network',
        component: lazy(() => import('src/views/claimNetwork'))
      },
      {
        exact: true,
        path: '/store',
        component: lazy(() => import('src/views/store'))
      },
      {
        exact: true,
        path: '/cart',
        component: lazy(() => import('src/views/cart/Cartitem'))
      },
      {
        exact: true,
        path: '/assets/:gameID/:id?',
        component: lazy(() => import('src/views/itemInfo'))
      },
      {
        exact: true,
        path: '/myassets',
        component: lazy(() => import('src/views/myAssets'))
      },
      {
        exact: true,
        path: ['/', '/dashboard'],
        component: HomeView
      },
      {
        exact: true,
        path: '/buy-chain-transak',
        component: lazy(() => import('src/views/transak'))
      },
      {
        exact: true,
        path: '/community-nfts',
        component: lazy(() => import('src/views/communityNfts'))
      },
      {
        exact: true,
        path: '/communityAssets/:id?',
        component: lazy(() => import('src/views/communityNftInfo'))
      },
      {
        exact: true,
        path: '/communitynft-store',
        component: lazy(() => import('src/views/communitynftStore'))
      },
      {
        exact: true,
        path: '/communityAssets/:gameID/:id?',
        component: lazy(() => import('src/views/communityNftInfoUsingID'))
      },
      {
        exact: true,
        path: '/chaingamesFaucet',
        component: lazy(() => import('src/views/chaingamesFaucet'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
